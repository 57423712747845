import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { MonitoringFormData } from "../../../../store/monitoringType";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { ReactNode } from "react";
import { styled } from "styled-components";
import { KeyboardArrowDown } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  Table,
  TableRow,
  TableCell,
  Box,
  IconButton,
  Card,
  CardContent,
  CardHeader,
  TextField,
  MenuItem,
  Select,
  Grid
} from "@mui/material";
import { Divider } from "antd";
import DoNotDisturbOnIcon from "@mui/icons-material/DoNotDisturbOn";
import TextEditor from "../../../../component/TextEditor";
import MonitoringReductionTable from "../../section/MonitoringReductionTable";
import { ParamType } from "../../../../store/paramType";
import { ValueListItem } from "../../paramData/ParamDetailsPage";
interface ArgType {
  name: string;
  id: string;
  unit: string;
  definition: string;
  standard: string; // "FIXED" or "CHANGE"
  valueList: { id: string; item: string; value: number }[];
}
interface CustomTypographyProps {
  children: ReactNode;
  sx?: object; // Optional prop to accept the sx prop
}
interface DataArgumentValue {
  id: number;
  name: string;
  standard: ParamType;
  valueList: ValueListItem[];
  unit: string;
  definition: string;
  methodologyId: number;
  selectedValue?: { id: string; item: string; value: number };
}
interface DataArgumentValueList {
  id: number;
  // name: string;
  standard: string; // Adjust type as necessary
  valueList: ValueListItem[];
  unit: string;
  definition: string;
  methodologyId: number;
  selectedValue?: { id: string; item: string; value: number };
  name: NameType;
  selectedArg?: {
    valueList: Array<{ id: string; item: string; value: number }>;
  };
}
interface NameType {
  unit: string;
  definition: string;
  // other properties as needed
}
interface ApiResponseItem {
  id: number;
  name: string;
  standard: ParamType;
  valueList: ValueListItem[];
  unit: string;
  definition: string;
  methodologyId: number;
  selectedValue?: { id: string; item: string; value: number };
}

const MonitoringCalcPage: React.FC<any> = ({
  id,
  formData,
  setFormData,
  handleTextChange,
  isNew = false,
  error: propsError
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined>(propsError);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [dataArgumentValueList, setDataArgumentValueList] = useState<
    DataArgumentValue[]
  >([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(API_URL);
        const argumentData = response.data.content; // Adjust as necessary based on your API response structure

        // Map the data to the desired structure
        const formattedData: DataArgumentValue[] = argumentData.map(
          (item: ApiResponseItem) => ({
            id: item.id || 0,
            name: item.name || "",
            standard: (item.standard as ParamType) || "defaultStandard",
            valueList: item.valueList || [],
            unit: item.unit || "",
            definition: item.definition || "",
            methodologyId: item.methodologyId || 0,
            selectedValue: item.selectedValue || undefined
          })
        );

        setDataArgumentValueList(formattedData); // Set the formatted data to state
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const [baselineArgs, setBaselineArgs] = useState({
    fixed: [] as DataArgumentValueList[],
    change: [] as DataArgumentValueList[]
  });
  const [projectArgs, setProjectArgs] = useState({
    fixed: [] as DataArgumentValueList[],
    change: [] as DataArgumentValueList[]
  });

  const dataArgumentInit = {
    id: "",
    name: "",
    standard: "",
    valueList: [],
    selectedValue: null,
    unit: "",
    definition: ""
  };

  const API_URL = "https://api-kisa.greenery.im/monitoring";
  const API_URL_ARGS = "https://api-kisa.greenery.im/data-argument/monitoring";
  const API_URL_ARG = "https://api-kisa.greenery.im/data-argument";

  const fetchMonitoringData = useCallback(
    async (purpose: ParamType) => {
      setLoading(true);
      const methodologyId = formData.methodologyId;

      if (!methodologyId) {
        console.error("Methodology ID is undefined.");
        setLoading(false);
        return;
      }

      console.log("Fetching data with Methodology ID:", methodologyId);

      try {
        const fixed_res = await axios.get(
          `${API_URL_ARGS}?standard=${ParamType.FIXED}&purpose=${purpose}&methodologyId=${methodologyId}`
        );
        console.log("Fixed Response:", fixed_res.data); // Log response data

        const change_res = await axios.get(
          `${API_URL_ARGS}?standard=${ParamType.CHANGE}&purpose=${purpose}&methodologyId=${methodologyId}`
        );

        if (purpose === ParamType.BASELINE) {
          setBaselineArgs({
            fixed: fixed_res.data,
            change: change_res.data
          });
        } else {
          setProjectArgs({
            fixed: fixed_res.data,
            change: change_res.data
          });
        }

        console.log("Change Response:", change_res.data); // Log response data
      } catch (error) {
        console.error("Error fetching monitoring data:", error);
      } finally {
        setLoading(false);
      }
    },
    [formData] // Ensure formData is included in the dependencies
  );
  const [baselineArgsList, setBaselineArgsList] = useState<Array<any>>([
    dataArgumentInit
  ]);
  const [projectArgsList, setProjectArgsList] = useState<Array<any>>([
    dataArgumentInit
  ]);
  const fetchArgumentData = async (id: string | number) => {
    try {
      const res = await axios.get(`${API_URL_ARG}/${id}`);
      return res.data;
    } catch (error) {
      console.log("error --------->", error);
    }
  };

  console.log("projectArgument List: ", projectArgsList);
  useEffect(() => {
    console.log("Baseline Args List Updated:", baselineArgsList);
  }, [baselineArgsList]);

  useEffect(() => {
    console.log("Project Args List Updated:", projectArgsList);
  }, [projectArgsList]);

  // const changeFormArrayValue = (
  //   listName: string,
  //   index: number,
  //   fieldName: string,
  //   value: any
  // ) => {
  //   const logic = (prev: DataArgumentValueList[]) => {
  //     const newList = prev.map((el, idx) => {
  //       return index === idx
  //         ? {
  //             ...el,
  //             [fieldName]: value
  //           }
  //         : el;
  //     });
  //     return newList;
  //   };
  //   if (listName === "baseline") {
  //     setBaselineArgsList(logic);
  //   } else if (listName === "project") {
  //     setProjectArgsList(logic);
  //   }
  // };
  const changeFormArrayValue = (
    listName: string,
    index: number,
    fieldName: string,
    value: any
  ) => {
    console.log(
      `Updating ${listName} at index ${index}: ${fieldName} = ${value}`
    );
    const logic = (prev: DataArgumentValueList[]) => {
      const newList = prev.map((el, idx) => {
        if (index === idx) {
          return { ...el, [fieldName]: value };
        }
        return el;
      });
      console.log("New List after update:", newList);
      return newList;
    };

    if (listName === "baseline") {
      setBaselineArgsList(logic);
    } else if (listName === "project") {
      setProjectArgsList(logic);
    }
  };

  useEffect(() => {
    fetchMonitoringData(ParamType.BASELINE);
    fetchMonitoringData(ParamType.PROJECT);
  }, []);

  useEffect(() => {
    setFormData((prev: any) => ({
      ...prev,
      dataArgumentValueIdList: [...baselineArgsList, ...projectArgsList]
    }));
  }, [baselineArgsList, projectArgsList]);

  useEffect(() => {
    async function processArgData() {
      if (
        !formData.dataArgumentValueList ||
        formData.dataArgumentValueList.length === 0
      ) {
        return;
      }

      try {
        const argDataList = await Promise.all(
          formData.dataArgumentValueList.map(async (el: any) => {
            const { valueList } = await fetchArgumentData(el.dataArgumentId);
            return {
              id: el.dataArgumentId,
              selectedValue: {
                id: el.id,
                item: el.item,
                value: el.value
              },
              standard: el.standard,
              name: el.name,
              unit: el.unit,
              definition: el.definition,
              purpose: el.purpose,
              valueList: valueList
            };
          })
        );

        const baseline = argDataList.filter((el: any) =>
          el?.purpose?.includes("BASELINE")
        );
        const project = argDataList.filter((el: any) =>
          el?.purpose?.includes("PROJECT")
        );

        setBaselineArgsList(
          baseline.length > 0 ? baseline : [dataArgumentInit]
        );
        setProjectArgsList(project.length > 0 ? project : [dataArgumentInit]);
      } catch (error) {
        console.error("Error processing argument data:", error);
      }
    }

    processArgData();
  }, [formData.dataArgumentValueList]);

  useEffect(() => {
    console.log("Project Args List Updated:", projectArgsList);
  }, [projectArgsList]);

  useEffect(() => {
    console.log("Baseline Args List Updated:", baselineArgsList);
  }, [baselineArgsList]);

  const CustomTextField = styled(TextField)(({ theme }) => ({
    width: "100%",
    minHeight: "48px",
    padding: "12px 16px",
    gap: "2px",
    borderRadius: "10px 0px 0px 0px",
    border: "1px solid transparent",
    opacity: 1,
    marginLeft: "0",
    "& .MuiInputBase-root": {
      height: "48px" // Ensures input area matches the outer height
    }
  }));
  const generateArgsFormArray = (
    list: DataArgumentValueList[],
    listType: "baseline" | "project",
    changeFormArrayValue: (
      listType: string,
      index: number,
      field: string,
      value: any
    ) => void
  ) => {
    return (
      <Table>
        <TableRow>
          <TableCell>구분</TableCell>
          <TableCell>데이터/인자</TableCell>

          {listType === "baseline" || listType === "project" ? (
            <TableCell>Value</TableCell>
          ) : null}
          {listType === "baseline" || listType === "project" ? (
            <TableCell>항목</TableCell>
          ) : null}
          <TableCell>단위</TableCell>
          <TableCell>정의</TableCell>
          <TableCell></TableCell>
        </TableRow>
        {list.map((arg, idx) => (
          <TableRow key={idx} sx={{ border: 0 }}>
            <TableCell padding="none" sx={{ width: "15%", border: 0 }}>
              <Select
                value={arg.standard}
                onChange={(e) =>
                  changeFormArrayValue(
                    listType,
                    idx,
                    "standard",
                    e.target.value
                  )
                }
                name="standard"
                size="small"
                IconComponent={(props) => (
                  <KeyboardArrowDown {...props} sx={{ fontSize: "16px" }} />
                )}
                sx={{
                  width: "100%",

                  opacity: 1,
                  borderRadius: "10px",

                  gap: "2px",
                  backgroundColor: "#ffff",
                  "&:hover": {
                    borderColor: "#F9F6FD"
                  }
                }}
                style={{ borderRadius: "7px" }}
              >
                <MenuItem value={"FIXED"}>고정</MenuItem>
                <MenuItem value={"CHANGE"}>변동</MenuItem>
              </Select>
            </TableCell>
            <TableCell
              padding="none"
              sx={{ width: "15%", padding: "4px", border: 0 }}
            >
              <Select
                value={arg?.name}
                name="name"
                onChange={(e) => {
                  changeFormArrayValue(listType, idx, "name", e.target.value);
                  changeFormArrayValue(
                    listType,
                    idx,
                    "selectedArg",
                    e.target.value
                  );
                }}
                size="small"
                IconComponent={(props) => (
                  <KeyboardArrowDown {...props} sx={{ fontSize: "16px" }} />
                )}
                sx={{
                  width: "100%",
                  opacity: 1,
                  borderRadius: "10px",
                  gap: "2px",
                  backgroundColor: "#ffff",
                  "&:hover": {
                    borderColor: "#F9F6FD"
                  }
                }}
                style={{ borderRadius: "7px" }}
                renderValue={(val: any) => {
                  return val.name || val;
                }}
              >
                {baselineArgs[
                  arg.standard === ParamType.FIXED ? "fixed" : "change"
                ].flatMap((menuItem: any) => (
                  <MenuItem key={menuItem.id} value={menuItem}>
                    {menuItem.name}
                  </MenuItem>
                ))}
              </Select>
            </TableCell>

            <>
              <TableCell
                padding="none"
                sx={{ width: "15%", padding: "4px", border: 0 }}
              >
                <Select
                  value={arg.selectedValue}
                  name="selectedValue"
                  onChange={(e) =>
                    changeFormArrayValue(
                      listType,
                      idx,
                      "selectedValue",
                      e.target.value
                    )
                  }
                  size="small"
                  disabled={arg.standard === "CHANGE"}
                  IconComponent={(props) => (
                    <KeyboardArrowDown {...props} sx={{ fontSize: "16px" }} />
                  )}
                  sx={{
                    width: "100%",
                    opacity: 1,
                    borderRadius: "10px",
                    gap: "2px",
                    backgroundColor:
                      arg.standard === "CHANGE" ? "#E9E9EA" : "#ffff",
                    "&:hover": {
                      borderColor:
                        arg.standard === "CHANGE" ? "transparent" : "#F9F6FD"
                    }
                  }}
                  style={{ borderRadius: "7px" }}
                  renderValue={(val) => {
                    const selected = arg?.selectedArg?.valueList.find(
                      (item: any) => item.id === val.id
                    );
                    return selected
                      ? selected.item
                      : arg.selectedValue
                      ? arg.selectedValue.item
                      : "";
                  }}
                >
                  {arg?.selectedArg?.valueList?.map(
                    (item: any, idx: number) => (
                      <MenuItem key={item.id} value={item}>
                        {item.item}
                      </MenuItem>
                    )
                  )}
                </Select>
              </TableCell>
              <TableCell
                padding="none"
                sx={{ width: "10%", padding: "4px", border: 0 }}
              >
                <TextField
                  sx={{
                    width: "100%",
                    backgroundColor:
                      arg.standard === "CHANGE" ? "#E9E9EA" : "#E9E9EA", // Change color if inactive
                    opacity: arg.standard === "CHANGE" ? 0.5 : 1 // Change opacity if inactive
                  }}
                  disabled={true}
                  size="small"
                  value={arg?.selectedValue?.value}
                  name="value"
                  InputProps={{ style: { borderRadius: "7px" } }}
                />
              </TableCell>
              <TableCell
                padding="none"
                sx={{ width: "15%", padding: "4px", border: 0 }}
              >
                <TextField
                  sx={{
                    width: "100%",
                    backgroundColor: "#E9E9EA",
                    opacity: arg.standard === "CHANGE" ? 0.5 : 1
                  }}
                  size="small"
                  disabled={true}
                  value={arg.name.unit || "j"}
                  name="unit"
                  InputProps={{ style: { borderRadius: "7px" } }}
                />
              </TableCell>
              <TableCell
                padding="none"
                sx={{ width: "25%", padding: "4px", border: 0 }}
              >
                <TextField
                  sx={{
                    width: "100%",
                    backgroundColor: "#E9E9EA",
                    opacity: arg.standard === "CHANGE" ? 0.5 : 1
                  }}
                  size="small"
                  disabled={true}
                  value={arg.name.definition}
                  name="definition"
                  InputProps={{ style: { borderRadius: "7px" } }}
                />
              </TableCell>
            </>

            <TableCell
              padding="none"
              sx={{ width: "5%", padding: "4px", border: 0 }}
            >
              <IconButton
                onClick={() => {
                  if (listType === "baseline") {
                    setBaselineArgsList((prev) => {
                      const _newList = [...prev];
                      _newList.splice(idx, 1);
                      return _newList;
                    });
                  } else if (listType === "project") {
                    setProjectArgsList((prev) => {
                      const _newList = [...prev];
                      _newList.splice(idx, 1);
                      return _newList;
                    });
                  }
                }}
              >
                <DoNotDisturbOnIcon sx={{ fontSize: 20 }} />
              </IconButton>
            </TableCell>
          </TableRow>
        ))}
      </Table>
    );
  };
  const CustomTypography: React.FC<CustomTypographyProps> = ({
    children,
    ...props
  }) => {
    return (
      <Typography
        {...props}
        sx={{
          color: "#99989D",
          ...props.sx,

          lineHeight: "18px",
          fontWeight: 700
        }}
      >
        {children}
      </Typography>
    );
  };
  return (
    <>
      <Card
        sx={{
          marginTop: "20px",
          borderRadius: "20px"
        }}
      >
        <CardContent>
          <Box
            sx={{
              m: 1,
              display: "flex",
              flexDirection: "column"
            }}
          >
            <CustomTypography sx={{ fontWeight: "bold" }}>
              베이스라인 데이터/인자
            </CustomTypography>
          </Box>
          <Box
            sx={{
              m: 2,
              display: "flex",
              flexDirection: "column"
            }}
          >
            {generateArgsFormArray(
              baselineArgsList,
              "baseline",
              changeFormArrayValue
            )}
            <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
              <IconButton
                onClick={() =>
                  setBaselineArgsList((prev) => [
                    ...prev,
                    {
                      ...dataArgumentInit
                    }
                  ])
                }
              >
                <AddCircleIcon sx={{ fontSize: 40 }} />
              </IconButton>
            </Box>
            <Box sx={{ width: "100%", mt: 5 }}>
              <CustomTypography sx={{ fontWeight: "bold" }}>
                베이스라인 배출량(흡수량) 산정 수식 설명
              </CustomTypography>
              <Box sx={{ mt: 2 }}>
                <TextEditor
                  setData={(val: string) =>
                    handleTextChange("formulaDescriptionBaseline", val)
                  }
                  minHeight={120}
                  data={formData.formulaDescriptionBaseline}
                />
              </Box>
            </Box>
          </Box>
        </CardContent>
      </Card>
      <Card
        sx={{
          marginTop: "20px",
          borderRadius: "20px"
        }}
      >
        <CardContent>
          <Box
            sx={{
              m: 1,
              display: "flex",
              flexDirection: "column"
            }}
          >
            <CustomTypography sx={{ fontWeight: "bold" }}>
              프로젝트 데이터/인자 정보
            </CustomTypography>
          </Box>
          <Box
            sx={{
              m: 2,
              display: "flex",
              flexDirection: "column"
            }}
          >
            {generateArgsFormArray(
              projectArgsList,
              "project",
              changeFormArrayValue
            )}
            <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
              <IconButton
                onClick={() =>
                  setProjectArgsList((prev) => [
                    ...prev,
                    {
                      ...dataArgumentInit
                    }
                  ])
                }
              >
                <AddCircleIcon sx={{ fontSize: 40 }} />
              </IconButton>
            </Box>
            <Box sx={{ width: "100%", mt: 5 }}>
              <CustomTypography sx={{ fontWeight: "bold" }}>
                프로젝트 배출량(흡수량) 산정 수식 설명
              </CustomTypography>
              <Box sx={{ mt: 2 }}>
                <TextEditor
                  setData={(val: string) =>
                    handleTextChange("formulaDescriptionProject", val)
                  }
                  minHeight={120}
                  data={formData.formulaDescriptionProject}
                />
              </Box>
            </Box>
          </Box>
        </CardContent>
      </Card>
      <Grid item lg={12}>
        <Card
          sx={{
            borderRadius: 3,
            minHeight: 240,
            width: "100%",
            display: "flex",
            flexDirection: "column",
            mt: 2
          }}
        >
          <CardContent sx={{ width: "100%", height: "100%" }}>
            <CardHeader
              title={"모니터링 데이터 조회"}
              titleTypographyProps={{ fontWeight: "bold", fontSize: 19 }}
            />
            <Box
              sx={{
                m: 2,
                display: "flex",
                flexDirection: "column"
              }}
            >
              {/* 첫번째 줄 */}
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Box sx={{ width: "40%" }}>
                  <CustomTypography sx={{ fontWeight: "bold" }}>
                    기간 조회
                  </CustomTypography>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      mt: 1
                    }}
                  >
                    <CustomTextField
                      type="date"
                      name="startDate"
                      value={startDate}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        setStartDate(event.target.value)
                      }
                      sx={{ width: "47%" }}
                      size="small"
                      InputProps={{
                        style: {
                          borderRadius: "7px"
                        }
                      }}
                    />
                    <Typography sx={{ alignSelf: "center" }}> ~ </Typography>
                    <CustomTextField
                      type="date"
                      value={endDate}
                      name="endDate"
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        setEndDate(event.target.value)
                      }
                      sx={{ width: "47%" }}
                      size="small"
                      InputProps={{
                        style: {
                          borderRadius: "7px"
                        }
                      }}
                    />
                  </Box>
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  mt: 2
                }}
              >
                <MonitoringReductionTable rows={[]} />
              </Box>

              <Divider />

              {/* 세번째 줄 */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  mt: 2
                }}
              >
                <Typography sx={{ fontWeight: "bold", fontSize: 25 }}>
                  온실가스 감축량(순흡수량) 합계
                </Typography>
                <Typography sx={{ fontWeight: "bold", fontSize: 25 }}>
                  16.5 tCO2-eq
                </Typography>
              </Box>
              <Divider />
            </Box>
          </CardContent>
        </Card>
      </Grid>
    </>
  );
};

export default MonitoringCalcPage;
