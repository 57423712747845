import { useState } from "react";
import CommonList from "../../component/CommonList";
import { Button, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import CommonSearch from "../../component/CommonSearch";
import AdminTable from "./section/AdminTable";
import AddIcon from "@mui/icons-material/Add";
import { Navigate, useNavigate } from "react-router-dom";
const AdminMngListPage = () => {
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [usrType, setUsrType] = useState("10");
  const [usrStatus, setUsrStatus] = useState("10");
  const navigate = useNavigate();
  const handleUsrTypeChange = (event: SelectChangeEvent) => {
    setUsrType(event.target.value as string);
  };
  const handleUsrStatus = (event: SelectChangeEvent) => {
    setUsrStatus(event.target.value as string);
  };
  const headerButton = () => {
    return (
      <Button
        sx={{
          height: 40,
          backgroundColor: "#5D38E5",
          color: "white",
          borderRadius: 5,
          width: 85,
          ":hover": {
            backgroundColor: "#A38BFC"
          }
        }}
        onClick={() => {
          navigate("/adminMng/createAdmin");
        }}
      >
        <AddIcon fontSize="small" />
        등록
      </Button>
    );
  };
  return (
    <CommonList
      title="관리자 관리"
      setModal={null}
      headerButton={headerButton()}
    >
      <CommonSearch setRowsPerPage={setRowsPerPage}>
        <Select
          id="paging"
          value={usrType}
          onChange={handleUsrTypeChange}
          size="small"
          sx={{ width: "15%" }}
          style={{ borderRadius: "10px" }}
        >
          <MenuItem value={10}>:: 선택 ::</MenuItem>
          <MenuItem value={20}>일반 사용자</MenuItem>
          <MenuItem value={30}>투어 사용자</MenuItem>
          <MenuItem value={40}>관리자</MenuItem>
        </Select>
        <Select
          id="paging"
          value={usrStatus}
          onChange={handleUsrStatus}
          size="small"
          sx={{ width: "15%" }}
          style={{ borderRadius: "10px" }}
        >
          <MenuItem value={10}>:: 선택 ::</MenuItem>
          <MenuItem value={20}>활동</MenuItem>
          <MenuItem value={30}>승인대기</MenuItem>
          <MenuItem value={40}>탈퇴</MenuItem>
          <MenuItem value={50}>사용중지</MenuItem>
        </Select>
      </CommonSearch>
      <AdminTable rowsPerPage={rowsPerPage} />
    </CommonList>
  );
};

export default AdminMngListPage;
