import {
  Button,
  MenuItem,
  Select,
  SelectChangeEvent,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import CommonList from "../../component/CommonList";
import CommonSearch from "../../component/CommonSearch";
import UserAddModal from "./section/UserAddModal";
import GrpMngListGrid from "./section/GrpMngListGrid";
import { GridRowsProp } from "@mui/x-data-grid";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import GrpHisGrid from "./section/GrpHisGrid";
const GrpMngListPage = () => {
  const navigate = useNavigate();
  const [tabVelue, setTabVelue] = useState("mng");
  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabVelue(newValue);
  };

  const [modal, setModal] = useState(false);
  const [mngRows, setMngRows] = useState<GridRowsProp>([
    {
      id: "aaaa",
      no: "1",
      groupName: "Korea Seoul Admin",
      description: "Korea Seoul Admin",
      permission: "Admin",
      writer: "Minji Lee",
      lastUpdateDate: "2022/12/26 23:00",
      registrationDate: "2022/12/26 23:00",
    },
  ]);
  const [hisRows, setHisRows] = useState<GridRowsProp>([
    {
      id: "aaaa",
      no: "1",
      groupName: "Korea Seoul Admin",
      affiliation: "/korea/admin",
      permission: "Admin",
      writer: "Minji Lee",
      registrationDate: "2022/12/26 23:00",
    },
  ]);
  const [usrType, setUsrType] = useState("10");
  const [usrStatus, setUsrStatus] = useState("10");
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleUsrTypeChange = (event: SelectChangeEvent) => {
    setUsrType(event.target.value as string);
  };

  const handleUsrStatus = (event: SelectChangeEvent) => {
    setUsrStatus(event.target.value as string);
  };

  useEffect(() => {
    console.log(modal);
  }, [modal]);

  const HeaderButton = () => {
    return (
      <Button
        sx={{
          height: 40,
          backgroundColor: "#5D38E5",
          color: "white",
          borderRadius: 5,
          width: 85,
          ":hover": {
            backgroundColor: "#A38BFC",
          },
        }}
        onClick={() => {
          navigate("/grpMng/grpCreate");
        }}
      >
        <AddIcon fontSize="small" />
        등록
      </Button>
    );
  };
  return (
    <CommonList
      title="그룹 관리"
      headerButton={tabVelue == "mng" && HeaderButton()}
    >
      <Tabs
        value={tabVelue}
        onChange={handleTabChange}
        textColor="inherit"
        TabIndicatorProps={{
          style: {
            backgroundColor: "#5D38E5",
          },
        }}
        sx={{ mt: -3, mb: 2 }}
      >
        <Tab
          value="mng"
          label={
            <Typography
              sx={{
                fontSize: "0.9rem", // 폰트 크기 조정
                fontWeight: "bold",
              }}
            >
              관리
            </Typography>
          }
          sx={{
            minWidth: 90,
            minHeight: 0,
            height: "50px",

            mt: 2.7,
          }} // 최소 너비 설정
        />
        <Tab
          value="history"
          label={
            <Typography
              sx={{
                fontSize: "0.9rem", // 폰트 크기 조정
                fontWeight: "bold",
              }}
            >
              이력
            </Typography>
          }
          sx={{
            minWidth: 90,
            minHeight: 0,
            height: "50px",

            mt: 2.7,
          }} // 최소 너비 설정
        />
      </Tabs>
      <CommonSearch setRowsPerPage={setRowsPerPage}>
        <Select
          id="paging"
          value={usrType}
          onChange={handleUsrTypeChange}
          size="small"
          sx={{ width: "15%" }}
          style={{ borderRadius: "10px" }}
        >
          <MenuItem value={10}>All</MenuItem>
          <MenuItem value={20}>User</MenuItem>
          <MenuItem value={30}>Admin</MenuItem>
        </Select>
      </CommonSearch>
      {tabVelue == "mng" && (
        <GrpMngListGrid
          rows={mngRows}
          setRowsPerPage={setRowsPerPage}
          rowsPerPage={rowsPerPage}
        />
      )}
      {tabVelue == "history" && (
        <GrpHisGrid
          hisRows={hisRows}
          setRowsPerPage={setRowsPerPage}
          rowsPerPage={rowsPerPage}
        />
      )}

      <UserAddModal modal={modal} setModal={setModal} />
    </CommonList>
  );
};

export default GrpMngListPage;
