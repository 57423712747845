import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
  Divider,
  Radio,
  useTheme,
  Theme,
  Button,
} from "@mui/material";
import TextEditor from "../../../component/TextEditor";
import { useEffect, useState } from "react";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const AnnounceCreateGrid = () => {
  const [data, setData] = useState(`
    내용을 입력해 주세요.
  `);
  useEffect(() => {
    console.log("data is : ", data);
  }, [data]);

  const [selectedValue, setSelectedValue] = useState("a");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(event.target.value);
  };
  return (
    <Grid container rowSpacing={4.5} columnSpacing={2.75} sx={{ mt: -5 }}>
      {/* 첫번째 카드 */}
      <Grid item lg={8}>
        <Card
          sx={{
            borderRadius: 3,

            width: "100%",
            display: "flex",
          }}
        >
          <CardContent sx={{ width: "100%" }}>
            <Box
              sx={{
                m: 2,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box sx={{ mt: 0 }}>
                <Typography fontWeight={"bold"}>Title</Typography>
                <TextField
                  sx={{ width: "100%", mt: 1 }}
                  placeholder="제목 입력"
                  InputProps={{
                    style: {
                      borderRadius: "12px",
                    },
                  }}
                />
              </Box>
              <Divider sx={{ mt: 3 }} />
              <Box sx={{ mt: 3 }}>
                <TextEditor setData={setData} minHeight={450} data={data} />
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      {/* 두번째 카드 */}
      <Grid item lg={4}>
        <Card
          sx={{
            borderRadius: 3,

            width: "100%",
            display: "flex",
          }}
        >
          <CardContent sx={{ width: "100%" }}>
            <CardHeader
              title="Information"
              titleTypographyProps={{ fontWeight: "bold", fontSize: 19 }}
            />
            <Box
              sx={{
                m: 2,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box sx={{}}>
                <Typography fontWeight={"bold"} color={"#99989D"}>
                  Show/Hide
                </Typography>
                <Box sx={{ display: "flex" }}>
                  <Box>
                    <Radio
                      checked={selectedValue === "a"}
                      onChange={handleChange}
                      value="a"
                      name="radio-buttons"
                      inputProps={{ "aria-label": "A" }}
                    />
                    Show
                  </Box>
                  <Box sx={{ ml: 5 }}>
                    <Radio
                      checked={selectedValue === "b"}
                      onChange={handleChange}
                      value="b"
                      name="radio-buttons"
                      inputProps={{ "aria-label": "B" }}
                    />
                    Hide
                  </Box>
                </Box>
              </Box>
              <Box sx={{ mt: 3 }}>
                <Typography fontWeight={"bold"} color={"#99989D"}>
                  Open Date
                </Typography>
                <TextField
                  sx={{ width: "100%", mt: 1 }}
                  placeholder="제목 입력"
                  InputProps={{
                    style: {
                      borderRadius: "12px",
                    },
                  }}
                />
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Select
                    value={"none"}
                    sx={{
                      width: "48.5%",
                      mt: 1,
                      borderRadius: "12px",
                    }}
                    MenuProps={MenuProps}
                  >
                    {Array.from({ length: 24 }, (_, i) => (
                      <MenuItem key={i + 1} value={i + 1}>
                        {i + 1}
                      </MenuItem>
                    ))}
                  </Select>
                  <Select
                    value={"none"}
                    sx={{
                      width: "48.5%",
                      mt: 1,
                      borderRadius: "12px",
                    }}
                    MenuProps={MenuProps}
                  >
                    {Array.from({ length: 60 }, (_, i) => (
                      <MenuItem key={i + 1} value={i + 1}>
                        {i + 1}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
              </Box>
              <Box sx={{ mt: 3 }}>
                <Typography fontWeight={"bold"} color={"#99989D"}>
                  Writer
                </Typography>
                <TextField
                  name="description"
                  sx={{ width: "100%", mt: 1 }}
                  value={"Very Kim"}
                  // onChange={handleChange}
                  InputProps={{
                    style: {
                      borderRadius: "12px",
                      backgroundColor: "#E9E9EA",
                    },
                  }}
                  disabled
                />
              </Box>
              <Box sx={{ mt: 3, display: "flex", alignItems: "center" }}>
                <Typography fontWeight={"bold"} color={"#99989D"}>
                  File
                </Typography>
                <Button
                  sx={{
                    textTransform: "none",
                    height: 25,
                    //   backgroundColor: "#5D38E5",
                    color: "#5D38E5",
                    borderRadius: 5,
                    border: 1,
                    ml: 2,
                    borderColor: "#5D38E5",
                    width: 95,
                    ":hover": {
                      backgroundColor: "#E9E9E9",
                    },
                  }}
                >
                  Add File
                </Button>
              </Box>
              <Typography sx={{ fontSize: 12, color: "#99989D", mt: 2 }}>
                15MB이하의 파일(jpg,png,mp4) 3개까지 첨부 가능
              </Typography>
            </Box>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default AnnounceCreateGrid;
