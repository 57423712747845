import { getCLS, getFID, getFCP, getLCP, getTTFB, Metric } from 'web-vitals';

type ReportWebVitalsCallback = (metric: Metric) => void;

const reportWebVitals = (onPerfEntry?: ReportWebVitalsCallback) => {
  if (onPerfEntry && typeof onPerfEntry === 'function') {
    getCLS(onPerfEntry);
    getFID(onPerfEntry);
    getFCP(onPerfEntry);
    getLCP(onPerfEntry);
    getTTFB(onPerfEntry);
  }
};
export default reportWebVitals;