import { useLocation, useNavigate } from "react-router-dom";
import CommonList from "../../component/CommonList";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import UserDetailGrid from "./section/UserDetailGrid";
import UserScooterInfoGrid from "./section/UserScooterInfoGrid";
import UserMembershipGrid from "./section/UserMembershipGrid";
import UserCarbonReductionGrid from "./section/UserCarbonReductionGrid";
import UserWalletGrid from "./section/UserWalletGrid";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

const UserDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname;
  const userId = pathname.split("/").pop(); // URL에서 마지막 부분을 추출

  const [value, setValue] = useState("one");

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const HeaderButton = () => {
    return (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Button
          sx={{
            height: 40,
            backgroundColor: "#5D38E5",
            color: "white",
            borderRadius: 5,
            width: 110,
            ":hover": {
              backgroundColor: "#A38BFC",
            },
          }}
          onClick={() => {}}
        >
          업데이트
        </Button>
        &nbsp; &nbsp;
        <Typography fontSize={30} sx={{ mb: 0.5, color: "gray" }}>
          |
        </Typography>
        <IconButton sx={{ color: "red" }}>
          <DeleteOutlineOutlinedIcon fontSize="medium" />
        </IconButton>
      </Box>
    );
  };
  const handleNavigate = (route: string) => {
    navigate(route);
    // 페이지를 새로고침
    window.location.reload();
  };
  const HeaderBackButton = () => {
    return (
      <IconButton
        sx={{
          ":hover": { backgroundColor: "#F2F2F2" },
          mb: 0.5,
          mr: 1,
        }}
        onClick={() => {
          handleNavigate("/usrMng");
        }}
      >
        <ArrowBackIosNewIcon fontSize="medium" />
      </IconButton>
    );
  };
  return (
    <CommonList
      title="회원 정보 상세"
      setModal={null}
      headerButton={HeaderButton()}
      headerBackButton={HeaderBackButton()}
    >
      <Box>
        <Tabs
          value={value}
          onChange={handleChange}
          textColor="inherit"
          TabIndicatorProps={{
            style: {
              backgroundColor: "#000000",
            },
          }}
          sx={{ mt: 1 }}
        >
          <Tab
            value="one"
            label={
              <Typography
                sx={{
                  fontSize: "1rem", // 폰트 크기 조정
                  fontWeight: "bold",
                }}
              >
                User
              </Typography>
            }
            sx={{
              minWidth: 0,
              minHeight: 0,
              height: "35px",
              pl: 0.7,
              pr: 0.7,
              mt: 2.7,
            }} // 최소 너비 설정
          />
          <Tab
            value="two"
            label={
              <Typography
                sx={{
                  fontSize: "1rem", // 폰트 크기 조정
                  fontWeight: "bold",
                }}
              >
                Scooter
              </Typography>
            }
            sx={{
              minWidth: 0,
              minHeight: 0,
              height: "35px",
              pl: 1.5,
              pr: 1.5,
              mt: 2.7,
              ml: 2.5,
            }} // 최소 너비 설정
          />
          <Tab
            value="three"
            label={
              <Typography
                sx={{
                  fontSize: "1rem", // 폰트 크기 조정
                  fontWeight: "bold",
                }}
              >
                Membership
              </Typography>
            }
            sx={{
              minWidth: 0,
              minHeight: 0,
              height: "35px",
              pl: 1.5,
              pr: 1.5,
              mt: 2.7,
              ml: 2.5,
            }} // 최소 너비 설정
          />
          <Tab
            value="four"
            label={
              <Typography
                sx={{
                  fontSize: "1rem", // 폰트 크기 조정
                  fontWeight: "bold",
                }}
              >
                Carbon Reduction
              </Typography>
            }
            sx={{
              minWidth: 0,
              minHeight: 0,
              height: "35px",
              pl: 1.5,
              pr: 1.5,
              mt: 2.7,
              ml: 2.5,
            }} // 최소 너비 설정
          />
          <Tab
            value="five"
            label={
              <Typography
                sx={{
                  fontSize: "1rem", // 폰트 크기 조정
                  fontWeight: "bold",
                }}
              >
                Wallet
              </Typography>
            }
            sx={{
              minWidth: 0,
              minHeight: 0,
              height: "35px",
              pl: 1.5,
              pr: 1.5,
              mt: 2.7,
              ml: 2.5,
            }} // 최소 너비 설정
          />
        </Tabs>
        {value == "one" && <UserDetailGrid />}
        {value == "two" && <UserScooterInfoGrid />}
        {value == "three" && <UserMembershipGrid />}
        {value == "four" && <UserCarbonReductionGrid />}
        {value == "five" && <UserWalletGrid />}
      </Box>
    </CommonList>
  );
};

export default UserDetails;
