import { Button, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { useEffect, useState } from "react";
import CommonList from "../../component/CommonList";
import CommonSearch from "../../component/CommonSearch";
import AddIcon from "@mui/icons-material/Add";
import FAQMngGrid from "./section/FAQMngGrid";
import { GridRowsProp } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";

const FAQMngListPage = () => {
  const navigate = useNavigate();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [faqRows, setFaqRows] = useState<GridRowsProp>([
    {
      id: "test",
      no: "1",
      title: "팝플월드에서는 무엇을 할 수 있나요?",
      registrationDate: "2022/12/26 23:00",
      reply: {
        content:
          "팝플월드에서는 전기 스쿠터의 배터리 교환 서비스를 이용하실 수 있습니다. 배터리 교환 서비스를 이용하기 위해서 팝플월드 앱이 설치 되어 있으야 하며 회원 가입이 필요합니다.",
        registrationDate: "2022/12/28 23:00",
      },
    },
    {
      id: "test2",
      no: "2",
      title: "팝플월드에서는 무엇을 할 수 있나요?",
      registrationDate: "2022/12/26 23:00",
      reply: {
        content:
          "팝플월드에서는 전기 스쿠터의 배터리 교환 서비스를 이용하실 수 있습니다. 배터리 교환 서비스를 이용하기 위해서 팝플월드 앱이 설치 되어 있으야 하며 회원 가입이 필요합니다.",
        registrationDate: "2022/12/28 23:00",
      },
    },
  ]);
  const HeaderButton = () => {
    return (
      <Button
        sx={{
          height: 40,
          backgroundColor: "#5D38E5",
          color: "white",
          borderRadius: 5,
          width: 110,
          ":hover": {
            backgroundColor: "#A38BFC",
          },
        }}
        onClick={() => {
          navigate("/FAQMng/FAQCreate");
        }}
      >
        <AddIcon fontSize="small" />
        FAQ 등록
      </Button>
    );
  };

  return (
    <CommonList title="FAQ 관리" headerButton={HeaderButton()}>
      <CommonSearch setRowsPerPage={setRowsPerPage}>{null}</CommonSearch>
      <FAQMngGrid faqRows={faqRows} rowsPerPage={rowsPerPage} />
    </CommonList>
  );
};

export default FAQMngListPage;
