import { Route, Routes, useNavigate } from "react-router-dom";
import LoginPage from "../pages/auth/LoginPage";
import { useEffect } from "react";

const LoginRoutes = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate("/login");
  }, [navigate]);
  return (
    <Routes>
      <Route path="/login" element={<LoginPage />} />
    </Routes>
  );
};

export default LoginRoutes;
