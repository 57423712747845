import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
} from "@mui/material";
import CommonList from "../../component/CommonList";

import ChargingStationOutlinedIcon from "@mui/icons-material/ChargingStationOutlined";
import EvStationOutlinedIcon from "@mui/icons-material/EvStationOutlined";
import ElectricMopedOutlinedIcon from "@mui/icons-material/ElectricMopedOutlined";

import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import {
  BarChart,
  LineChart,
  BarChartProps,
  AxisConfig,
  axisClasses,
  chartsGridClasses,
  ChartsXAxisProps,
} from "@mui/x-charts";

const getIconByType = (type: string) => {
  switch (type) {
    case "Station":
      return <EvStationOutlinedIcon fontSize="large" sx={{ color: "gray" }} />;
    case "Battery":
      return (
        <ChargingStationOutlinedIcon fontSize="large" sx={{ color: "gray" }} />
      );
    case "Vehicle":
      return (
        <ElectricMopedOutlinedIcon fontSize="large" sx={{ color: "gray" }} />
      );
    case "Obstacle":
      return (
        <ErrorOutlineOutlinedIcon fontSize="large" sx={{ color: "gray" }} />
      );
    default:
      return null;
  }
};

const AnalysisCard = ({
  title,
  count,
  icon,
  usedPower,
}: {
  title: string;
  count: number;
  icon: string;
  usedPower: number;
}) => {
  return (
    <Card
      sx={{ borderRadius: 3, minHeight: 240, width: "100%", display: "flex" }}
    >
      <CardContent sx={{ width: "100%" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            width: "100%",
            height: "100%",
            p: 1,
            mt: 1,
          }}
        >
          <Box sx={{ width: "100%" }}>
            {getIconByType(icon)}
            <Typography
              sx={{ fontSize: 15, fontWeight: "bold", color: "gray" }}
            >
              {title == "Station" && <>운영중 {title}</>}
              {title == "Battety" && <>운영중 {title}</>}
              {title == "Vechicle" && <>운영중 {title}</>}
              {title == "Obstacle" && <>장애 발생건</>}
            </Typography>
          </Box>
          <Box sx={{ width: "100%" }}>
            <Typography sx={{ fontSize: 30, fontWeight: "bold" }}>
              {title == "Station" && <>{count} 개</>}
              {title == "Battety" && <>{count} 개</>}
              {title == "Vechicle" && <>{count} 대</>}
              {title == "Obstacle" && <>{count} 회</>}
            </Typography>
            <Typography sx={{ fontSize: 13, color: "gray" }}>
              {title == "Station" && (
                <>총 사용전력: {usedPower.toLocaleString()} Kwh</>
              )}
              {title == "Battety" && (
                <>총 사용전력: {usedPower.toLocaleString()} Kwh</>
              )}
              {title == "Vechicle" && (
                <>총 사용전력: {usedPower.toLocaleString()} Kwh</>
              )}
              {title == "Obstacle" && <></>}
            </Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

interface CustomBandAxisConfig
  extends Omit<AxisConfig<"band", any, ChartsXAxisProps>, "id"> {
  position: "top" | "bottom" | undefined;
}

const xAxisConfig: CustomBandAxisConfig = {
  categoryGapRatio: 0.8,
  position: "bottom", // Adjust position as per your requirement
  data: ["bar A", "bar B", "bar C"],
  scaleType: "band",
};

const BarChartCard = () => {
  return (
    <Card
      sx={{
        borderRadius: 3,
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <BarChart
        grid={{ horizontal: true }}
        sx={(theme) => ({
          [`.${axisClasses.root}`]: {
            [`.${axisClasses.tick}, .${axisClasses.line}`]: {
              strokeWidth: 0, // y축 눈금과 선 숨기기
            },
          },
          [`& .${chartsGridClasses.line}`]: {
            strokeDasharray: "5 5",
            strokeWidth: 1,
          },
        })}
        xAxis={[xAxisConfig]}
        series={[
          {
            data: [550, 110, 300],
          },
        ]}
        slotProps={{
          bar: {
            clipPath: `inset(0px round 15px 15px 0px 0px)`,
            style: {
              // width: 20,
              // transform: "translateX(18px)", // 막대를 가운데 정렬
            },
            strokeWidth: 10,
          },
        }}
        // width={500}
        height={400}
      />
    </Card>
  );
};

const LineChartCard = () => {
  const chartsParams = {
    margin: { bottom: 40, left: 40, right: 40 },
    height: 300,
  };
  return (
    <Card sx={{ borderRadius: 3, width: "100%", display: "flex" }}>
      <LineChart
        {...chartsParams}
        series={[
          {
            data: [15, 23, 18, 19, 13],

            color: "#4e79a7",
          },
          {
            data: [17, 22, 13, 25, 19],

            color: "red",
          },
        ]}
        height={400}
      />
    </Card>
  );
};

const OperateDashBoardPage = () => {
  return (
    <CommonList title="Analysis Overview" setModal={null}>
      <Grid container rowSpacing={4.5} columnSpacing={2.75}>
        <Grid item lg={3}>
          <AnalysisCard
            title="Station"
            count={100}
            icon="Station"
            usedPower={556542}
          />
        </Grid>
        <Grid item lg={3}>
          <AnalysisCard
            title="Battety"
            count={40}
            icon="Battery"
            usedPower={156070}
          />
        </Grid>
        <Grid item lg={3}>
          <AnalysisCard
            title="Vechicle"
            count={60}
            icon="Vehicle"
            usedPower={247561}
          />
        </Grid>
        <Grid item lg={3}>
          <AnalysisCard
            title="Obstacle"
            count={3}
            icon="Obstacle"
            usedPower={121212}
          />
        </Grid>
        <Grid item lg={7}>
          <LineChartCard />
        </Grid>
        <Grid item lg={5}>
          <BarChartCard />
        </Grid>
      </Grid>
    </CommonList>
  );
};

export default OperateDashBoardPage;
