import {
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@mui/material";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import { Project, getTypeString } from "../../../store/projectType";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import { useEffect, useState } from "react";
import { SxProps } from "@mui/material";

interface ProjectTableProps {
  data: Project[];
  rowsPerPage: number;
  page: number;
  onChangePage: (event: React.ChangeEvent<unknown>, newPage: number) => void;
  totalCount: number;
}

const CustomCell = ({
  title,
  align,
  onSort
}: {
  title: string;
  align: "left" | "center" | "right";
  onSort?: () => void;
}) => (
  <TableCell
    sx={{
      backgroundColor: "white",
      color: "#99989D",
      fontWeight: 600,
      fontFamily: "var(--bs-font-sans-serif)",
      overflow: "hidden",
      whiteSpace: "nowrap",
      position: "relative"
    }}
    align={align}
  >
    {title}

    {onSort && (
      <div
        onClick={onSort}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          position: "absolute",
          right: -20,
          top: 10
        }}
      >
        <UnfoldMoreIcon
          style={{
            marginTop: "10px",
            marginRight: "18px",
            fontSize: "medium",
            color: "#1D1B26"
          }}
        />
      </div>
    )}
  </TableCell>
);

const ProjectTable: React.FC<ProjectTableProps> = ({
  data,
  rowsPerPage,
  page,
  onChangePage,
  totalCount
}) => {
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc");
  const [sortedData, setSortedData] = useState<Project[]>(data);
  const navigate = useNavigate();

  useEffect(() => {
    setSortedData(data);
  }, [data]);

  const handleSort = (column: keyof Project) => {
    const newOrder = sortOrder === "asc" ? "desc" : "asc";
    const sorted = [...sortedData].sort((a, b) => {
      const valueA = a[column];
      const valueB = b[column];

      if (valueA < valueB) return newOrder === "asc" ? -1 : 1;
      if (valueA > valueB) return newOrder === "asc" ? 1 : -1;
      return 0;
    });

    setSortedData(sorted);
    setSortOrder(newOrder);
  };
  const formatDate = (date: string | number | Date): string => {
    const parsedDate = new Date(date);
    return isNaN(parsedDate.getTime())
      ? "Invalid date"
      : format(parsedDate, "yyyy/MM/dd");
  };

  const totalPages = Math.ceil(totalCount / rowsPerPage);

  const handleRowClick = (id: number) => {
    navigate(`/projectMng/projectDetails/${id}`);
  };

  return (
    <Paper
      sx={{
        width: "100%",
        border: 1,
        borderColor: "#F3F3F4",
        borderRadius: 3,
        mt: 3
      }}
      elevation={0}
    >
      <TableContainer sx={{ borderRadius: "15px" }}>
        <Table
          sx={{
            width: "100%",
            border: "1px solid #E9E9EA",
            "&th": {
              border: "1px solid #E9E9EA"
            }
          }}
          stickyHeader
          aria-label="sticky table"
        >
          <TableHead>
            <TableRow>
              <CustomCell align="center" title="No" />
              <CustomCell align="center" title="프로젝트 ID" />
              <CustomCell align="center" title="신청 유형" />
              <CustomCell align="center" title="프로젝트명" />
              <CustomCell align="center" title="적용 방법론" />
              <CustomCell align="center" title="기업명" />
              <CustomCell align="center" title="사업 검증 보고서" />
              <CustomCell
                align="center"
                title="유효기간 시작일"
                onSort={() => handleSort("startDate")}
              />
              <CustomCell
                align="center"
                title="유효기간 종료일"
                onSort={() => handleSort("endDate")}
              />
              <CustomCell
                align="center"
                title="생성일"
                onSort={() => handleSort("createdAt")}
              />
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedData.map((row, index) => (
              <TableRow
                key={row.id}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  ":hover": {
                    backgroundColor: "rgba(0, 0, 0, 0.04)", // Change to a light color for row hover
                    cursor: "pointer"
                  },
                  "& td": {
                    color: "#53515A",
                    "& .MuiSvgIcon-root": {
                      color: "black"
                    }
                  }
                }}
                onClick={() => handleRowClick(row.id)}
              >
                <TableCell
                  component="th"
                  scope="row"
                  align="center"
                  sx={{ color: "#53515A", fontWeight: 400 }}
                >
                  {page * rowsPerPage + index + 1}
                </TableCell>
                <TableCell align="center">{row.projectIdName}</TableCell>
                <TableCell align="center">{getTypeString(row.type)}</TableCell>
                <TableCell align="center">{row.title}</TableCell>
                <TableCell align="center">
                  {row.methodology.methodologyIdName}
                </TableCell>
                <TableCell align="center">{row.companyName}</TableCell>
                <TableCell align="center">
                  <a
                    href={row.businessValidationReportFile}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <InsertLinkIcon
                      style={{ cursor: "pointer", marginRight: 8 }}
                    />
                    다운로드
                  </a>
                  {row.businessValidationReportFile
                    ? row.businessValidationReportFile.split("/").pop()
                    : ""}
                </TableCell>
                <TableCell align="center">
                  {formatDate(row.startDate)}
                </TableCell>
                <TableCell align="center">{formatDate(row.endDate)}</TableCell>
                <TableCell align="center">
                  {row.createdAt
                    ? formatDate(Number(row.createdAt) * 1000)
                    : "Invalid date"}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Pagination
        sx={{
          mt: 2,
          ml: 1,
          mb: 2,
          display: "flex",
          justifyContent: "flex-start",
          size: "medium",
          gap: "10px",
          opacity: 1,
          "& .MuiPaginationItem-root": {
            color: "blue",
            backgroundColor: "white",
            borderRadius: "50%",
            minWidth: "28px",
            height: "28px",
            padding: "4px",
            "&:hover": {
              backgroundColor: "#E0E0E0" // Hover background color
            },
            "&.Mui-selected": {
              backgroundColor: "#EFECFD", // Set background color for selected item
              color: "#5D38E5",
              shape: "circular"
            }
          }
        }}
        count={totalPages}
        shape="rounded"
        page={page + 1}
        color="primary"
        onChange={onChangePage}
      />
    </Paper>
  );
};

export default ProjectTable;
