import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";

const UserScooterInfoGrid = () => {
  return (
    <Grid container rowSpacing={4.5} columnSpacing={2.75} sx={{ mt: 0 }}>
      <Grid item lg={12}>
        {/* 첫번째 카드 */}
        <Card
          sx={{
            borderRadius: 3,

            width: "100%",
            display: "flex",
          }}
        >
          <CardContent sx={{ width: "100%" }}>
            <CardHeader
              title="User"
              titleTypographyProps={{ fontWeight: "bold", fontSize: 19 }}
              // sx={{ ml: -2, mb: 0 }}
            />
            <Box
              sx={{
                m: 2,
                display: "flex",
                flexDirection: "column",
              }}
            >
              {/* 첫번째 줄 */}
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Box sx={{ width: "48.5%" }}>
                  <Typography>Owner</Typography>
                  <TextField
                    sx={{ width: "100%", mt: 1 }}
                    size="small"
                    InputProps={{
                      style: {
                        borderRadius: "7px",
                      },
                    }}
                  />
                </Box>
                <Box sx={{ width: "48.5%" }}>
                  <Typography>Renter</Typography>
                  <TextField
                    sx={{ width: "100%", mt: 1 }}
                    size="small"
                    InputProps={{
                      style: {
                        borderRadius: "7px",
                      },
                    }}
                  />
                </Box>
              </Box>
              {/* 두번째 줄 */}
              <Box
                sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}
              >
                <Box sx={{ width: "48.5%" }}>
                  <Typography>registrant</Typography>
                  <TextField
                    sx={{ width: "100%", mt: 1 }}
                    size="small"
                    InputProps={{
                      style: {
                        borderRadius: "7px",
                      },
                    }}
                  />
                </Box>
                <Box sx={{ width: "48.5%" }}>
                  <Typography>Regist Date</Typography>
                  <TextField
                    sx={{ width: "100%", mt: 1 }}
                    size="small"
                    InputProps={{
                      style: {
                        borderRadius: "7px",
                      },
                    }}
                  />
                </Box>
              </Box>

              {/* 세번째 줄 */}
              <Box
                sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}
              >
                <Box sx={{ width: "48.5%" }}>
                  <Typography>Last Updater</Typography>
                  <TextField
                    sx={{ width: "100%", mt: 1 }}
                    size="small"
                    InputProps={{
                      style: {
                        borderRadius: "7px",
                      },
                    }}
                  />
                </Box>
                <Box sx={{ width: "48.5%" }}>
                  <Typography>Last Update Date</Typography>
                  <TextField
                    sx={{ width: "100%", mt: 1 }}
                    size="small"
                    InputProps={{
                      style: {
                        borderRadius: "7px",
                      },
                    }}
                  />
                </Box>
              </Box>
            </Box>
          </CardContent>
        </Card>

        {/* 두번째 카드 */}
        <Card
          sx={{
            borderRadius: 3,
            mt: 4,
            width: "100%",
            display: "flex",
          }}
        >
          <CardContent sx={{ width: "100%" }}>
            <CardHeader
              title="Scooter"
              titleTypographyProps={{ fontWeight: "bold", fontSize: 19 }}
              // sx={{ ml: -2, mb: 0 }}
            />
            <Box
              sx={{
                m: 2,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box
                sx={{ display: "flex", justifyContent: "space-between", mt: 0 }}
              >
                <Box sx={{ width: "48.5%" }}>
                  <Box sx={{ width: "100%" }}>
                    <Typography>Plate Number</Typography>
                    <TextField
                      sx={{ width: "100%", mt: 1 }}
                      size="small"
                      InputProps={{
                        style: {
                          borderRadius: "7px",
                        },
                      }}
                    />
                    <Typography sx={{ width: "100%", mt: 3 }}>
                      Scooter S/ N
                    </Typography>
                    <TextField
                      sx={{ width: "100%", mt: 2 }}
                      size="small"
                      InputProps={{
                        style: {
                          borderRadius: "7px",
                        },
                      }}
                    />
                    <Typography sx={{ width: "100%", mt: 3 }}>
                      Frame S/ N
                    </Typography>
                    <TextField
                      sx={{ width: "100%", mt: 2 }}
                      size="small"
                      InputProps={{
                        style: {
                          borderRadius: "7px",
                        },
                      }}
                    />
                    <Typography sx={{ width: "100%", mt: 3 }}>IMEI</Typography>
                    <TextField
                      sx={{ width: "100%", mt: 2 }}
                      size="small"
                      InputProps={{
                        style: {
                          borderRadius: "7px",
                        },
                      }}
                    />
                    <Typography sx={{ width: "100%", mt: 3 }}>
                      Date of manufacture
                    </Typography>
                    <TextField
                      sx={{ width: "100%", mt: 2 }}
                      size="small"
                      InputProps={{
                        style: {
                          borderRadius: "7px",
                        },
                      }}
                    />
                  </Box>
                </Box>
                <Box sx={{ width: "48.5%" }}>
                  <Typography>Product Name</Typography>
                  <Select
                    value={1}
                    size="small"
                    sx={{ width: "100%", mt: 1 }}
                    style={{ borderRadius: "7px" }}
                  >
                    <MenuItem value={1}>ST20220720003</MenuItem>
                    <MenuItem value={2}>ST20220720004</MenuItem>
                    <MenuItem value={3}>ST20220720005</MenuItem>
                  </Select>
                  <Box
                    sx={{
                      width: "100%",
                      height: 385,
                      mt: 4,
                      border: 1,
                      borderRadius: 2,
                      borderColor: "#C4C4C4",
                      alignItems: "center",
                      justifyContent: "center",
                      display: "flex",
                    }}
                  >
                    사진
                  </Box>
                </Box>
              </Box>
            </Box>
          </CardContent>
        </Card>

        {/* 세번째 카드 */}
        <Card
          sx={{
            borderRadius: 3,
            mt: 4,
            width: "100%",
            display: "flex",
          }}
        >
          <CardContent sx={{ width: "100%" }}>
            <Box
              sx={{
                m: 2,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box
                sx={{ display: "flex", justifyContent: "space-between", mt: 0 }}
              >
                <Box sx={{ width: "48.5%" }}>
                  <Box sx={{ width: "100%" }}>
                    <Box
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <Box sx={{ width: "48.5%" }}>
                        <Typography>Latitude</Typography>
                        <TextField
                          sx={{ width: "100%", mt: 1 }}
                          size="small"
                          InputProps={{
                            style: {
                              borderRadius: "7px",
                            },
                          }}
                        />
                      </Box>
                      <Box sx={{ width: "48.5%" }}>
                        <Typography>Longitude</Typography>
                        <TextField
                          sx={{ width: "100%", mt: 1 }}
                          size="small"
                          InputProps={{
                            style: {
                              borderRadius: "7px",
                            },
                          }}
                        />
                      </Box>
                    </Box>
                    <Typography sx={{ width: "100%", mt: 3 }}>
                      Location Update Date
                    </Typography>
                    <TextField
                      sx={{ width: "100%", mt: 2 }}
                      size="small"
                      InputProps={{
                        style: {
                          borderRadius: "7px",
                        },
                      }}
                    />
                  </Box>
                </Box>
                <Box sx={{ width: "48.5%" }}>
                  <Box
                    sx={{
                      width: "100%",
                      height: 330,
                      // mt: 4,
                      border: 1,
                      borderRadius: 3,
                      borderColor: "#C4C4C4",
                      alignItems: "center",
                      justifyContent: "center",
                      display: "flex",
                    }}
                  >
                    지도
                  </Box>
                </Box>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default UserScooterInfoGrid;
