import AddIcon from "@mui/icons-material/Add";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import TrashIcon from "../assets/icon/TrashIcon.svg";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Radio,
  RadioGroup,
  FormControlLabel,
  TextField,
  Select,
  MenuItem,
  Typography,
  Box,
  Grid,
  Checkbox,
  IconButton,
  Tab,
  Tabs
} from "@mui/material";
import { SelectChangeEvent } from "@mui/material";
import axios from "axios";
import React, { ChangeEvent, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ParamChange from "./components/ParamChange";
import ParamFixed from "./components/ParamFixed";
import ParamResult from "./components/ParamResult";
import { ParamType } from "../../../store/paramType";
import { useMethodologies } from "../../../context/MethodologyContext";
import { PurposeEnum } from "../../../store/paramType";

interface ParamDetailsPageProps {
  open: boolean;
  id: number;
  onClose: () => void;
  onConfirm: (id: number) => void;
  onDelete?: () => void;
  title: string;
  content: string;
  isCreating?: boolean;
}

export interface ValueListItem {
  id: number;
  item: string;
  value: number;
}

export interface ArgumentDetailFormData {
  id?: number;
  standard: ParamType;
  name: string;
  unit: ParamType;
  definition: string;
  valueList?: ValueListItem[];
  methodologyIdName: string;
  methodologyId: number;
  methodology?: any;
  version: string;
  purpose?: PurposeEnum[] | null;
  output: string;
  measurementProcedure?: string;
  region?: string;
  qaqcProcedure?: string;
  monitoringWeeks: string;
  otherComments?: string;
  relatedDocumentFile?: any;
  createdAt?: string;
  updatedAt?: string;
  fomula?: string;
}

const ParamDetailsPage: React.FC<ParamDetailsPageProps> = ({
  id,
  open,
  onClose,
  onConfirm,
  onDelete,
  title,
  content,
  isCreating
}) => {
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [isDetailLoaded, setIsDetailLoaded] = useState<boolean>(false);
  const [isSaved, setIsSaved] = useState<boolean>(!!id);
  const [methodologyId, setMethodologyId] = useState<string | number>("");

  const [formData, setFormData] = useState<ArgumentDetailFormData>({
    name: "",
    unit: ParamType.MJ_I,
    definition: "",
    version: "",
    region: "",
    methodologyIdName: "",
    methodologyId: 0,
    output: "",
    standard: ParamType.FIXED,
    otherComments: "",
    relatedDocumentFile: "",
    valueList: [],
    measurementProcedure: "",
    qaqcProcedure: "",
    monitoringWeeks: "",
    fomula: ""
  });
  const API_URL = "https://api-kisa.greenery.im/data-argument";
  const methodologies = useMethodologies();
  const [selectedTab, setSelectedTab] = useState<string>(ParamType.FIXED);
  const [sizeValue, setSizeValue] = useState(0);
  const dialogSize = sizeValue === 0 ? "xs" : "sm";
  const [activeTab, setActiveTab] = useState(0);
  const [isUpdating, setIsUpdating] = useState(false);
  const [showTabs, setShowTabs] = useState(false);
  const handleTabChange = (event: React.SyntheticEvent<{}>, newValue: any) => {
    if (!isUpdating) {
      setActiveTab(newValue);
      console.log("newValue, ", newValue);
      setSelectedTab(newValue);
    }
  };

  useEffect(() => {
    setIsUpdating(id ? true : false);
  }, [id]);

  useEffect(() => {
    setFormData((prev) => ({ ...prev, standard: selectedTab as ParamType }));
  }, [selectedTab]);

  const handleMethodologyChange = (event: SelectChangeEvent<number>) => {
    const selectedId = Number(event.target.value);
    const selectedMethodology = methodologies.find(
      (methodology) => methodology.id === selectedId
    ) || { id: 0, methodologyIdName: "", title: "" };

    setFormData((prevFormData) => ({
      ...prevFormData,
      methodology: {
        id: selectedMethodology.id,
        title: selectedMethodology.title,
        methodologyIdName: selectedMethodology.methodologyIdName
      },
      methodologyId: selectedMethodology.id
    }));
  };

  useEffect(() => {
    if (formData.id !== undefined) {
      setIsSaved(true);
    }
  }, [formData.id]);

  useEffect(() => {
    if (!open) {
      setIsDetailLoaded(false);
      setFormData({
        name: "",
        unit: ParamType.MJ_I,
        definition: "",
        version: "",
        region: "",
        methodologyIdName: "",
        methodologyId: 0,
        output: "",
        standard: ParamType.FIXED,
        purpose: null,
        otherComments: "",
        relatedDocumentFile: "",
        valueList: [],
        measurementProcedure: "",
        qaqcProcedure: "",
        monitoringWeeks: "",
        fomula: ""
      });
      setSelectedTab(ParamType.FIXED);
      setShowTabs(true);
    }
  }, [open]);

  const handleSave = async () => {
    console.log("Save function called");
    const {
      id,
      standard,
      name,
      unit,
      version,
      region,
      output,
      monitoringWeeks,
      otherComments,
      relatedDocumentFile,
      valueList,
      measurementProcedure,
      qaqcProcedure,
      fomula
    } = formData;
    // Validation: ensure necessary fields are provided

    if (
      !standard ||
      !name ||
      !unit ||
      !version ||
      // !region ||
      // !output ||
      (standard === ParamType.CHANGE && !monitoringWeeks) ||
      (standard === ParamType.RESULT && !fomula)
    ) {
      setError("모든 항목을 입력해 주세요.");
      return;
    }

    setLoading(true);
    setError("");
    const payload: any = {
      standard,
      name,
      unit,
      definition: formData.definition,
      purpose: formData.purpose,
      methodologyId: formData.methodologyId,
      version
    };
    if (standard === ParamType.FIXED) {
      payload.region = region;
      payload.output = output;
      payload.otherComments = otherComments;
      payload.qaqcProcedure = qaqcProcedure;
      payload.relatedDocumentFile = relatedDocumentFile;
      payload.valueList = valueList;
    }
    // Conditionally include measurementProcedure and qaqcProcedure for CHANGE type
    if (standard === ParamType.CHANGE) {
      payload.qaqcProcedure = qaqcProcedure;
      payload.measurementProcedure = measurementProcedure;
      payload.qaqcProcedure = qaqcProcedure;
      payload.monitoringWeeks = monitoringWeeks;

      payload.relatedDocumentFile = relatedDocumentFile;
    }

    if (standard === ParamType.RESULT) {
      payload.fomula = fomula;
    }
    console.log("Payload before API call:", ParamType.RESULT);
    console.log("Payload before API call:", payload);

    try {
      let response;
      if (formData.id) {
        response = await axios.put(`${API_URL}/${formData.id}`, payload, {
          headers: { "Content-Type": "application/json" }
        });
      } else {
        response = await axios.post(API_URL, payload, {
          headers: { "Content-Type": "application/json" }
        });
      }
      onClose();
    } catch (error) {
      setError("An error occurred while saving the form data.");
      console.error("Save error:", error);

      alert("Error occurred during save");
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (formData.id) {
      try {
        await axios.delete(`${API_URL}/${formData.id}`);
        onClose();
        onDelete && onDelete();
      } catch (error) {
        setError("An error occurred while deleting the item.");
        console.error("Delete error", error);
      }
    } else {
      alert("No item to delete or not in a saved state.");
    }
  };
  if (selectedTab === ParamType.RESULT) {
    console.log("Form Data for RESULT tab:", formData);
  }

  useEffect(() => {
    const getParamDetail = async () => {
      try {
        const res = await axios.get(`${API_URL}/${id}`);
        const { data } = res;
        setFormData(data);
        setSelectedTab(res.data.standard);
        setIsDetailLoaded(true);

        setShowTabs(true);
      } catch (error) {
        alert("Load Failed");
      }
    };
    if (id) {
      getParamDetail();
    }
  }, [id]);

  return (
    <Dialog
      open={id ? open && isDetailLoaded : open}
      onClose={onClose}
      maxWidth={dialogSize}
      PaperProps={{
        sx: {
          minWidth: "1100px",
          maxWidth: selectedTab === ParamType.RESULT ? "400px" : "auto",
          borderRadius: "15px",
          backgroundColor: "#ffffff",
          height: "auto",
          minHeight: "400px"
        }
      }}
    >
      <DialogTitle sx={{ fontSize: "1.25rem", fontWeight: "bold" }}>
        {title}
      </DialogTitle>
      <DialogContent>
        <Box sx={{ fontWeight: "bold", fontSize: 16, height: "36px" }}>
          {showTabs && (
            <Tabs
              value={selectedTab}
              onChange={handleTabChange}
              // sx={{
              //   ".MuiTab-root": {
              //     color: "black",
              //     fontWeight: "bold",
              //     fontSize: "15px"
              //   },
              //   ".MuiTab-root.Mui-selected": {
              //     color: "black"
              //   },
              //   ".MuiTabs-indicator": { backgroundColor: " #5D38E5" }
              // }}
              sx={{
                ".MuiTab-root": {
                  color: "grey",
                  fontWeight: "bold",
                  fontSize: "15px"
                },
                ".MuiTab-root.Mui-selected": {
                  color: "black"
                },
                ".MuiTabs-indicator": { backgroundColor: " #5D38E5" }
              }}
            >
              <Tab
                value={ParamType.FIXED}
                label="고정 데이터/인자"
                disabled={isUpdating}
              />
              <Tab
                value={ParamType.CHANGE}
                label="변동 데이터/인자"
                disabled={isUpdating}
              />
              <Tab
                value={ParamType.RESULT}
                label="결과"
                disabled={isUpdating}
              />
            </Tabs>
          )}
        </Box>
        {selectedTab === ParamType.FIXED ? (
          <ParamFixed
            formData={formData}
            setFormData={setFormData}
            onHandleMethodologyChange={handleMethodologyChange}
            methodologies={methodologies}
          />
        ) : selectedTab === ParamType.CHANGE ? (
          <ParamChange
            formData={formData}
            setFormData={setFormData}
            onHandleMethodologyChange={handleMethodologyChange}
            methodologies={methodologies}
          />
        ) : selectedTab === ParamType.RESULT ? (
          <ParamResult
            formData={formData}
            setFormData={setFormData}
            onHandleMethodologyChange={handleMethodologyChange}
            methodologies={methodologies}
          />
        ) : null}
      </DialogContent>
      <DialogActions
        sx={{
          display: "flex",
          justifyContent: "space-between",
          padding: "16px"
        }}
      >
        <Button
          onClick={onClose}
          variant="text"
          color="inherit"
          sx={{ textTransform: "none" }}
        >
          Cancel
        </Button>
        <div>
          <Button
            onClick={handleSave}
            variant="contained"
            color="primary"
            disableElevation
            sx={{
              backgroundColor: "#5D38E5",
              minHeight: "40px",
              minWidth: "95px",
              borderRadius: "25px",
              color: "white",
              textTransform: "none",
              fontWeight: "bold",
              marginRight: "10px"
            }}
          >
            {formData.id ? "Update" : "Post"}
          </Button>
          {formData.id && (
            <Button
              onClick={handleDelete}
              color="secondary"
              disableElevation
              sx={{
                marginLeft: "10px",
                color: "red",
                textTransform: "none",
                fontWeight: "normal",
                backgroundColor: "transparent",
                minWidth: "auto",
                padding: "6px"
              }}
            >
              <DeleteOutlineIcon fontSize="medium" sx={{ color: "red" }} />
            </Button>
          )}
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default ParamDetailsPage;
