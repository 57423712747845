import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Snackbar,
  SnackbarOrigin,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import CloseIcon from "@mui/icons-material/Close";
import CommonList from "../../component/CommonList";
import { CheckBox } from "@mui/icons-material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { useNavigate } from "react-router-dom";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

interface State extends SnackbarOrigin {
  open: boolean;
}

const AdminDetailPage = () => {
  const [snackState, setSnackState] = useState<State>({
    open: false,
    vertical: "top",
    horizontal: "center",
  });
  const [usrType, setUsrType] = useState("1");
  const [statusState, setStatusState] = useState(false);
  const [formData, setFormData] = useState({
    usrType: "User",
    group: "korea/seoul/gangnam",
    status: "PendingApproval",
    name: "",
    phoneCountryCode: "1",
    CountryCode: "+82",
    phoneNumber: "",
    role: "1",
    office: "1",
    email: "qkrwodbs43@gmail.com",
    password: "",
    birthYear: "1",
    birthMonth: "1",
    birthDay: "1",
    gender: "1",
    registrationDate: "",
    lastModifiedDate: "",
  });

  const navigate = useNavigate();

  useEffect(() => {
    if (formData.status == "PendingApproval") {
      setStatusState(false);
    } else if (formData.status != "PendingApproval") {
      setStatusState(true);
    }
  }, [formData]);

  const { vertical, horizontal, open } = snackState;

  const handleClick = (newState: SnackbarOrigin) => () => {
    setSnackState({ ...newState, open: true });
  };

  const handleClose = () => {
    setSnackState({ ...snackState, open: false });
  };

  const handleSendEmail = (email: string) => {
    handleClick({ vertical: "bottom", horizontal: "right" })();
    console.log(email);
  };

  const handleNavigate = (route: string) => {
    navigate(route);
    // 페이지를 새로고침
    window.location.reload();
  };

  const HeaderButton = () => {
    return (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Button
          sx={{
            height: 40,
            backgroundColor: "#5D38E5",
            color: "white",
            borderRadius: 5,
            width: 110,
            ":hover": {
              backgroundColor: "#A38BFC",
            },
          }}
          onClick={() => {}}
        >
          업데이트
        </Button>
        &nbsp; &nbsp;
        <Typography fontSize={30} sx={{ mb: 0.5, color: "gray" }}>
          |
        </Typography>
        <IconButton sx={{ color: "red" }}>
          <DeleteOutlineOutlinedIcon fontSize="medium" />
        </IconButton>
      </Box>
    );
  };

  const HeaderBackButton = () => {
    return (
      <IconButton
        sx={{
          ":hover": { backgroundColor: "#F2F2F2" },
          mb: 0.5,
          mr: 1,
        }}
        onClick={() => {
          handleNavigate("/adminMng");
        }}
      >
        <ArrowBackIosNewIcon fontSize="medium" />
      </IconButton>
    );
  };

  return (
    <CommonList
      title="관리자 정보 상세"
      setModal={null}
      headerButton={HeaderButton()}
      headerBackButton={HeaderBackButton()}
    >
      <Grid container rowSpacing={4.5} columnSpacing={2.75} sx={{ mt: 0 }}>
        <Grid item lg={6}>
          <Card
            sx={{
              borderRadius: 3,
              width: "100%",
              display: "flex",
            }}
          >
            <CardContent sx={{ width: "100%", m: 2 }}>
              <Box
                sx={{
                  display: "flex",
                  height: "100%",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <Box>
                  <Typography>Type</Typography>
                  <Select
                    value={formData.usrType}
                    size="small"
                    sx={{ width: "100%", mt: 1 }}
                    style={{ borderRadius: "7px" }}
                  >
                    {/* <MenuItem value="none">:: 선택 ::</MenuItem> */}
                    <MenuItem value="User">일반 사용자</MenuItem>
                    <MenuItem value="TourUser">관리자</MenuItem>
                  </Select>
                </Box>

                <Box sx={{ mt: 3 }}>
                  <Typography>Group</Typography>
                  <Select
                    value={formData.group}
                    size="small"
                    sx={{ width: "100%", mt: 1 }}
                    style={{ borderRadius: "7px" }}
                  >
                    <MenuItem value="korea/seoul/gangnam">
                      korea/seoul/gangnam
                    </MenuItem>
                    <MenuItem value="korea/seoul/songpa">
                      korea/seoul/songpa
                    </MenuItem>
                    <MenuItem value="korea/seoul/Seongbuk">
                      korea/seoul/Seongbuk
                    </MenuItem>
                  </Select>
                </Box>
                <Box sx={{ mt: 3 }}>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography>Status</Typography>
                    <Snackbar
                      anchorOrigin={{ vertical, horizontal }}
                      autoHideDuration={2000}
                      onClose={handleClose}
                      open={open}
                      message={
                        <Box style={{ display: "flex", alignItems: "center" }}>
                          <NotificationsOutlinedIcon
                            style={{ marginRight: 8 }}
                          />
                          메일을 재발송 하였습니다.
                        </Box>
                      }
                      action={
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          sx={{ p: 0.5 }}
                          onClick={handleClose}
                        >
                          <CloseIcon />
                        </IconButton>
                      }
                      key={vertical + horizontal}
                    />
                    {statusState == false && (
                      <Button
                        sx={{
                          color: "black",
                          textDecoration: "underline",
                          "&:hover": {
                            backgroundColor: "transparent",
                            color: "black",
                            textDecoration: "underline",
                          },
                        }}
                        variant="text"
                        disableRipple
                        size="small"
                        onClick={() => {
                          handleSendEmail(formData.email);
                        }}
                      >
                        재발송
                      </Button>
                    )}
                  </Box>
                  {statusState == false && (
                    <Select
                      value={formData.status}
                      size="small"
                      sx={{ width: "100%", mt: 1 }}
                      style={{ borderRadius: "7px" }}
                    >
                      <MenuItem value="PendingApproval">
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <FiberManualRecordIcon
                            sx={{
                              width: 12,
                              height: 12,
                              mr: 1.5,
                              color: "orange",
                            }}
                          />
                          승인대기
                        </Box>
                      </MenuItem>
                    </Select>
                  )}
                  {statusState == true && (
                    <Select
                      value={formData.status}
                      size="small"
                      sx={{ width: "100%", mt: 1 }}
                      style={{ borderRadius: "7px" }}
                    >
                      <MenuItem value="Active">
                        <FiberManualRecordIcon
                          sx={{
                            width: 12,
                            height: 12,
                            mr: 1.5,
                            color: "#43D697",
                          }}
                        />
                        활동중
                      </MenuItem>
                      <MenuItem value="Withdrawn">
                        <FiberManualRecordIcon
                          sx={{
                            width: 12,
                            height: 12,
                            mr: 1.5,
                            color: "gray",
                          }}
                        />
                        일반 사용자
                      </MenuItem>
                      <MenuItem value="Disabled">
                        <FiberManualRecordIcon
                          sx={{
                            width: 12,
                            height: 12,
                            mr: 1.5,
                            color: "red",
                          }}
                        />
                        관리자
                      </MenuItem>
                    </Select>
                  )}
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item lg={6}>
          <Card
            sx={{
              borderRadius: 3,
              minHeight: 240,
              width: "100%",
              display: "flex",
            }}
          >
            <CardContent sx={{ width: "100%", m: 2 }}>
              <Box
                sx={{
                  display: "flex",
                  height: "100%",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <Box>
                  <Typography>Name</Typography>
                  <TextField
                    sx={{ width: "100%", mt: 1 }}
                    size="small"
                    InputProps={{
                      style: {
                        borderRadius: "7px",
                      },
                    }}
                  />
                </Box>

                <Box>
                  <Typography>Phone Number</Typography>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      mt: 1,
                    }}
                  >
                    <Select
                      value={formData.CountryCode}
                      size="small"
                      sx={{ width: "18.5%" }}
                      style={{ borderRadius: "7px" }}
                    >
                      <MenuItem value="+82">+82</MenuItem>
                      <MenuItem value="+855">+855</MenuItem>
                      <MenuItem value="+1">+1</MenuItem>
                    </Select>

                    <TextField
                      sx={{ width: "78.5%" }}
                      size="small"
                      value={formData.phoneNumber}
                      InputProps={{
                        style: {
                          borderRadius: "7px",
                        },
                      }}
                    />
                  </Box>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item lg={12}>
          <Card
            sx={{
              borderRadius: 3,
              minHeight: 240,
              width: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <CardContent sx={{ width: "100%", height: "100%" }}>
              <CardHeader
                title={"Details"}
                titleTypographyProps={{ fontWeight: "bold", fontSize: 19 }}
                // sx={{ ml: -2, mb: 0 }}
              />
              <Box
                sx={{
                  m: 2,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {/* 첫번째 줄 */}
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Box sx={{ width: "48.5%" }}>
                    <Typography>Role</Typography>
                    <Select
                      value={usrType}
                      size="small"
                      sx={{ width: "100%", mt: 1 }}
                      style={{ borderRadius: "7px" }}
                    >
                      <MenuItem value={1}>활동중</MenuItem>
                      <MenuItem value={2}>일반 사용자</MenuItem>
                      <MenuItem value={3}>관리자</MenuItem>
                    </Select>
                  </Box>
                  <Box sx={{ width: "48.5%" }}>
                    <Typography>Office</Typography>
                    <Select
                      value={usrType}
                      size="small"
                      sx={{ width: "100%", mt: 1 }}
                      style={{ borderRadius: "7px" }}
                    >
                      <MenuItem value={1}>활동중</MenuItem>
                      <MenuItem value={2}>일반 사용자</MenuItem>
                      <MenuItem value={3}>관리자</MenuItem>
                    </Select>
                  </Box>
                </Box>
                {/* 두번째 줄 */}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    mt: 2,
                  }}
                >
                  <Box sx={{ width: "48.5%" }}>
                    <Typography>Email ID</Typography>
                    <TextField
                      sx={{ width: "100%", mt: 1 }}
                      size="small"
                      value={formData.email}
                      InputProps={{
                        style: {
                          borderRadius: "7px",
                        },
                      }}
                    />
                  </Box>
                  <Box sx={{ width: "48.5%" }}>
                    <Typography>Password</Typography>
                    <TextField
                      sx={{ width: "100%", mt: 1 }}
                      size="small"
                      InputProps={{
                        style: {
                          borderRadius: "7px",
                        },
                      }}
                    />
                  </Box>
                </Box>

                {/* 세번째 줄 */}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    mt: 2,
                  }}
                >
                  <Box sx={{ width: "48.5%" }}>
                    <Typography>Birth of Date</Typography>
                    <Box
                      sx={{
                        display: "flex",
                        mt: 1,
                        justifyContent: "space-between",
                      }}
                    >
                      <Select
                        value={usrType}
                        size="small"
                        sx={{ width: "32%" }}
                        style={{ borderRadius: "7px" }}
                      >
                        <MenuItem value={1}>활동중</MenuItem>
                        <MenuItem value={2}>일반 사용자</MenuItem>
                        <MenuItem value={3}>관리자</MenuItem>
                      </Select>
                      <Select
                        value={usrType}
                        size="small"
                        sx={{ width: "32%" }}
                        style={{ borderRadius: "7px" }}
                      >
                        <MenuItem value={1}>활동중</MenuItem>
                        <MenuItem value={2}>일반 사용자</MenuItem>
                        <MenuItem value={3}>관리자</MenuItem>
                      </Select>
                      <Select
                        value={usrType}
                        size="small"
                        sx={{ width: "32%" }}
                        style={{ borderRadius: "7px" }}
                      >
                        <MenuItem value={1}>활동중</MenuItem>
                        <MenuItem value={2}>일반 사용자</MenuItem>
                        <MenuItem value={3}>관리자</MenuItem>
                      </Select>
                    </Box>
                  </Box>
                  <Box sx={{ width: "48.5%" }}>
                    <Typography>Gender</Typography>
                    <Select
                      value={usrType}
                      size="small"
                      sx={{ width: "100%", mt: 1 }}
                      style={{ borderRadius: "7px" }}
                    >
                      <MenuItem value={1}>Man</MenuItem>
                      <MenuItem value={2}>일반 사용자</MenuItem>
                      <MenuItem value={3}>관리자</MenuItem>
                    </Select>
                  </Box>
                </Box>

                {/* 네번째 줄 */}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    mt: 2,
                  }}
                >
                  <Box sx={{ width: "48.5%" }}>
                    <Typography>Address</Typography>
                    <Box
                      sx={{
                        display: "flex",
                        mt: 1,
                        justifyContent: "space-between",
                      }}
                    >
                      <Select
                        value={usrType}
                        size="small"
                        sx={{ width: "48.5%" }}
                        style={{ borderRadius: "7px" }}
                      >
                        <MenuItem value={1}>활동중</MenuItem>
                        <MenuItem value={2}>일반 사용자</MenuItem>
                        <MenuItem value={3}>관리자</MenuItem>
                      </Select>
                      <Select
                        value={usrType}
                        size="small"
                        sx={{ width: "48.5%" }}
                        style={{ borderRadius: "7px" }}
                      >
                        <MenuItem value={1}>활동중</MenuItem>
                        <MenuItem value={2}>일반 사용자</MenuItem>
                        <MenuItem value={3}>관리자</MenuItem>
                      </Select>
                    </Box>
                  </Box>
                  <Box sx={{ width: "48.5%" }}>
                    {/* <Typography> a</Typography> */}
                    <TextField
                      sx={{ width: "100%", mt: 4 }}
                      size="small"
                      InputProps={{
                        style: {
                          borderRadius: "7px",
                        },
                      }}
                    />
                  </Box>
                </Box>

                {/* 다섯번째 줄 */}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    mt: 2,
                  }}
                >
                  <Box sx={{ width: "48.5%" }}>
                    <Typography>Registration Date</Typography>
                    <TextField
                      sx={{ width: "100%", mt: 1 }}
                      size="small"
                      InputProps={{
                        style: {
                          borderRadius: "7px",
                        },
                      }}
                    />
                  </Box>
                  <Box sx={{ width: "48.5%" }}>
                    <Typography>Last Modified Date</Typography>
                    <TextField
                      sx={{ width: "100%", mt: 1 }}
                      size="small"
                      InputProps={{
                        style: {
                          borderRadius: "7px",
                        },
                      }}
                    />
                  </Box>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item lg={12}>
          <Card
            sx={{
              borderRadius: 3,
              minHeight: 140,
              width: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <CardContent sx={{ width: "80%", height: "100%" }}>
              <CardHeader
                title="Role"
                titleTypographyProps={{ fontWeight: "bold", fontSize: 19 }}
                // sx={{ ml: -2, mb: 0 }}
              />
              <Box
                sx={{
                  m: 2,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <CheckBox color="primary" />
                  <Typography>&nbsp;User</Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <CheckBox color="primary" />
                  <Typography>&nbsp;Content</Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <CheckBox color="primary" />
                  <Typography>&nbsp;Management</Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <CheckBox color="primary" />
                  <Typography>&nbsp;Carbon reduction</Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <CheckBox color="primary" />
                  <Typography>&nbsp;System</Typography>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </CommonList>
  );
};

export default AdminDetailPage;
