import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Snackbar,
  SnackbarOrigin,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import CloseIcon from "@mui/icons-material/Close";
import { CheckBox } from "@mui/icons-material";

interface State extends SnackbarOrigin {
  open: boolean;
}

const CreateAdminGrid = () => {
  const [snackState, setSnackState] = useState<State>({
    open: false,
    vertical: "top",
    horizontal: "center",
  });
  const [usrType, setUsrType] = useState("1");
  const [statusState, setStatusState] = useState(false);
  const [formData, setFormData] = useState({
    usrType: "none",
    group: "korea/seoul/gangnam",
    status: "PendingApproval",
    name: "",
    CountryCode: "+82",
    phoneNumber: "",
    role: "",
    office: "",
    email: "",
    password: "",
    birthYear: "1",
    birthMonth: "1",
    birthDay: "1",
    gender: "1",
    registrationDate: "",
    lastModifiedDate: "",
  });

  useEffect(() => {
    if (formData.status == "PendingApproval") {
      setStatusState(false);
    } else if (formData.status != "PendingApproval") {
      setStatusState(true);
    }
  }, [formData]);

  const { vertical, horizontal, open } = snackState;

  const handleClick = (newState: SnackbarOrigin) => () => {
    setSnackState({ ...newState, open: true });
  };

  const handleClose = () => {
    setSnackState({ ...snackState, open: false });
  };

  const handleSendEmail = (email: string) => {
    handleClick({ vertical: "bottom", horizontal: "right" })();
    console.log(email);
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  useEffect(() => {
    console.log(formData);
  }, [formData]);

  return (
    <Grid container rowSpacing={4.5} columnSpacing={2.75}>
      <Grid item lg={6}>
        <Card
          sx={{
            borderRadius: 3,
            width: "100%",
            display: "flex",
          }}
        >
          <CardContent sx={{ width: "100%", m: 2 }}>
            <Box
              sx={{
                display: "flex",
                height: "100%",
                // minHeight: 330,
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <Box>
                <Typography>Type</Typography>
                <Select
                  name="usrType"
                  value={formData.usrType}
                  size="small"
                  sx={{ width: "100%", mt: 1 }}
                  style={{ borderRadius: "7px" }}
                  onChange={handleChange}
                >
                  <MenuItem value="none">:: 선택 ::</MenuItem>
                  <MenuItem value="Administrator">Administrator</MenuItem>
                  {/* <MenuItem value="User">User</MenuItem> */}
                </Select>
              </Box>

              <Box sx={{ mt: 3 }}>
                <Typography>Group</Typography>
                <Select
                  name="group"
                  value={formData.group}
                  size="small"
                  sx={{ width: "100%", mt: 1 }}
                  style={{ borderRadius: "7px" }}
                  onChange={handleChange}
                >
                  <MenuItem value="korea/seoul/gangnam">
                    korea/seoul/gangnam
                  </MenuItem>
                  <MenuItem value="korea/seoul/songpa">
                    korea/seoul/songpa
                  </MenuItem>
                  <MenuItem value="korea/seoul/Seongbuk">
                    korea/seoul/Seongbuk
                  </MenuItem>
                </Select>
              </Box>
              <Box sx={{ mt: 3 }}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography>Status</Typography>
                  <Snackbar
                    anchorOrigin={{ vertical, horizontal }}
                    autoHideDuration={2000}
                    onClose={handleClose}
                    open={open}
                    message={
                      <Box style={{ display: "flex", alignItems: "center" }}>
                        <NotificationsOutlinedIcon style={{ marginRight: 8 }} />
                        메일을 재발송 하였습니다.
                      </Box>
                    }
                    action={
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        sx={{ p: 0.5 }}
                        onClick={handleClose}
                      >
                        <CloseIcon />
                      </IconButton>
                    }
                    key={vertical + horizontal}
                  />
                  {statusState == false && (
                    <Button
                      sx={{
                        color: "black",
                        textDecoration: "underline",
                        "&:hover": {
                          backgroundColor: "transparent",
                          color: "black",
                          textDecoration: "underline",
                        },
                      }}
                      variant="text"
                      disableRipple
                      size="small"
                      onClick={() => {
                        handleSendEmail(formData.email);
                      }}
                    >
                      재발송
                    </Button>
                  )}
                </Box>
                {statusState == false && (
                  <Select
                    name="status"
                    value={formData.status}
                    size="small"
                    disabled
                    sx={{ width: "100%", mt: 1, backgroundColor: "#F2F2F2" }}
                    style={{ borderRadius: "7px" }}
                    onChange={handleChange}
                  >
                    <MenuItem value="PendingApproval">
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <FiberManualRecordIcon
                          sx={{
                            width: 12,
                            height: 12,
                            mr: 1.5,
                            color: "orange",
                          }}
                        />
                        승인대기
                      </Box>
                    </MenuItem>
                  </Select>
                )}
                {statusState == true && (
                  <Select
                    name="status"
                    value={formData.status}
                    size="small"
                    sx={{ width: "100%", mt: 1 }}
                    style={{ borderRadius: "7px" }}
                    onChange={handleChange}
                  >
                    <MenuItem value="Active">
                      <FiberManualRecordIcon
                        sx={{
                          width: 12,
                          height: 12,
                          mr: 1.5,
                          color: "#43D697",
                        }}
                      />
                      활동중
                    </MenuItem>
                    <MenuItem value="Withdrawn">
                      <FiberManualRecordIcon
                        sx={{
                          width: 12,
                          height: 12,
                          mr: 1.5,
                          color: "gray",
                        }}
                      />
                      탈퇴
                    </MenuItem>
                    <MenuItem value="Disabled">
                      <FiberManualRecordIcon
                        sx={{
                          width: 12,
                          height: 12,
                          mr: 1.5,
                          color: "red",
                        }}
                      />
                      사용중지
                    </MenuItem>
                  </Select>
                )}
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Grid item lg={6}>
        <Card
          sx={{
            borderRadius: 3,
            minHeight: 240,
            width: "100%",
            display: "flex",
          }}
        >
          <CardContent sx={{ width: "100%", m: 2 }}>
            <Box
              sx={{
                display: "flex",
                height: "100%",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <Box>
                <Typography>Name</Typography>
                <TextField
                  name="name"
                  sx={{ width: "100%", mt: 1 }}
                  size="small"
                  InputProps={{
                    style: {
                      borderRadius: "7px",
                    },
                  }}
                  onChange={handleChange}
                />
              </Box>

              <Box>
                <Typography>Phone Number</Typography>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    mt: 1,
                  }}
                >
                  <Select
                    name="CountryCode"
                    value={formData.CountryCode}
                    size="small"
                    sx={{ width: "18.5%" }}
                    style={{ borderRadius: "7px" }}
                    onChange={handleChange}
                  >
                    <MenuItem value="+82">+82</MenuItem>
                    <MenuItem value="+855">+855</MenuItem>
                    <MenuItem value="+1">+1</MenuItem>
                  </Select>

                  <TextField
                    name="phoneNumber"
                    sx={{ width: "78.5%" }}
                    size="small"
                    value={formData.phoneNumber}
                    onChange={handleChange}
                    InputProps={{
                      style: {
                        borderRadius: "7px",
                      },
                    }}
                  />
                </Box>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Grid item lg={12}>
        <Card
          sx={{
            borderRadius: 3,
            minHeight: 240,
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <CardContent sx={{ width: "100%", height: "100%" }}>
            <CardHeader
              title="Details"
              titleTypographyProps={{ fontWeight: "bold", fontSize: 19 }}
              // sx={{ ml: -2, mb: 0 }}
            />
            <Box
              sx={{
                m: 2,
                display: "flex",
                flexDirection: "column",
              }}
            >
              {/* 첫번째 줄 */}
              {formData.usrType == "Administrator" && (
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Box sx={{ width: "48.5%" }}>
                    <Typography>Role</Typography>
                    <Select
                      value={usrType}
                      size="small"
                      sx={{ width: "100%", mt: 1 }}
                      style={{ borderRadius: "7px" }}
                    >
                      <MenuItem value={1}>활동중</MenuItem>
                      <MenuItem value={2}>일반 사용자</MenuItem>
                      <MenuItem value={3}>관리자</MenuItem>
                    </Select>
                  </Box>
                  <Box sx={{ width: "48.5%" }}>
                    <Typography>Office</Typography>
                    <Select
                      value={usrType}
                      size="small"
                      sx={{ width: "100%", mt: 1 }}
                      style={{ borderRadius: "7px" }}
                    >
                      <MenuItem value={1}>활동중</MenuItem>
                      <MenuItem value={2}>일반 사용자</MenuItem>
                      <MenuItem value={3}>관리자</MenuItem>
                    </Select>
                  </Box>
                </Box>
              )}
              {/* 두번째 줄 */}
              <Box
                sx={
                  formData.usrType == "Administrator"
                    ? {
                        display: "flex",
                        justifyContent: "space-between",
                        mt: 2,
                      }
                    : {
                        display: "flex",
                        justifyContent: "space-between",
                        mt: 0,
                      }
                }
              >
                <Box sx={{ width: "48.5%" }}>
                  <Typography>Email ID</Typography>
                  <TextField
                    sx={{ width: "100%", mt: 1 }}
                    size="small"
                    value={formData.email}
                    InputProps={{
                      style: {
                        borderRadius: "7px",
                      },
                    }}
                  />
                </Box>
                <Box sx={{ width: "48.5%" }}>
                  <Typography>Password</Typography>
                  <TextField
                    sx={{ width: "100%", mt: 1 }}
                    size="small"
                    InputProps={{
                      style: {
                        borderRadius: "7px",
                      },
                    }}
                  />
                </Box>
              </Box>

              {/* 세번째 줄 */}
              <Box
                sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}
              >
                <Box sx={{ width: "48.5%" }}>
                  <Typography>Birth of Date</Typography>
                  <Box
                    sx={{
                      display: "flex",
                      mt: 1,
                      justifyContent: "space-between",
                    }}
                  >
                    <Select
                      value={usrType}
                      size="small"
                      sx={{ width: "32%" }}
                      style={{ borderRadius: "7px" }}
                    >
                      <MenuItem value={1}>활동중</MenuItem>
                      <MenuItem value={2}>일반 사용자</MenuItem>
                      <MenuItem value={3}>관리자</MenuItem>
                    </Select>
                    <Select
                      value={usrType}
                      size="small"
                      sx={{ width: "32%" }}
                      style={{ borderRadius: "7px" }}
                    >
                      <MenuItem value={1}>활동중</MenuItem>
                      <MenuItem value={2}>일반 사용자</MenuItem>
                      <MenuItem value={3}>관리자</MenuItem>
                    </Select>
                    <Select
                      value={usrType}
                      size="small"
                      sx={{ width: "32%" }}
                      style={{ borderRadius: "7px" }}
                    >
                      <MenuItem value={1}>활동중</MenuItem>
                      <MenuItem value={2}>일반 사용자</MenuItem>
                      <MenuItem value={3}>관리자</MenuItem>
                    </Select>
                  </Box>
                </Box>
                <Box sx={{ width: "48.5%" }}>
                  <Typography>Gender</Typography>
                  <Select
                    value={usrType}
                    size="small"
                    sx={{ width: "100%", mt: 1 }}
                    style={{ borderRadius: "7px" }}
                  >
                    <MenuItem value={1}>Man</MenuItem>
                    <MenuItem value={2}>일반 사용자</MenuItem>
                    <MenuItem value={3}>관리자</MenuItem>
                  </Select>
                </Box>
              </Box>

              {/* 네번째 줄 */}
              <Box
                sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}
              >
                <Box sx={{ width: "48.5%" }}>
                  <Typography>Birth of Date</Typography>
                  <Box
                    sx={{
                      display: "flex",
                      mt: 1,
                      justifyContent: "space-between",
                    }}
                  >
                    <Select
                      value={usrType}
                      size="small"
                      sx={{ width: "48.5%" }}
                      style={{ borderRadius: "7px" }}
                    >
                      <MenuItem value={1}>활동중</MenuItem>
                      <MenuItem value={2}>일반 사용자</MenuItem>
                      <MenuItem value={3}>관리자</MenuItem>
                    </Select>
                    <Select
                      value={usrType}
                      size="small"
                      sx={{ width: "48.5%" }}
                      style={{ borderRadius: "7px" }}
                    >
                      <MenuItem value={1}>활동중</MenuItem>
                      <MenuItem value={2}>일반 사용자</MenuItem>
                      <MenuItem value={3}>관리자</MenuItem>
                    </Select>
                  </Box>
                </Box>
                <Box sx={{ width: "48.5%" }}>
                  <Typography>Gender</Typography>
                  <TextField
                    sx={{ width: "100%", mt: 1 }}
                    size="small"
                    InputProps={{
                      style: {
                        borderRadius: "7px",
                      },
                    }}
                  />
                </Box>
              </Box>

              {/* 다섯번째 줄 */}
              <Box
                sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}
              >
                <Box sx={{ width: "48.5%" }}>
                  <Typography>Registration Date</Typography>
                  <TextField
                    sx={{ width: "100%", mt: 1 }}
                    size="small"
                    InputProps={{
                      style: {
                        borderRadius: "7px",
                      },
                    }}
                  />
                </Box>
                <Box sx={{ width: "48.5%" }}>
                  <Typography>Last Modified Date</Typography>
                  <TextField
                    sx={{ width: "100%", mt: 1 }}
                    size="small"
                    InputProps={{
                      style: {
                        borderRadius: "7px",
                      },
                    }}
                  />
                </Box>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Grid item lg={12}>
        <Card
          sx={{
            borderRadius: 3,
            minHeight: 140,
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <CardContent sx={{ width: "80%", height: "100%" }}>
            <CardHeader
              title="Role"
              titleTypographyProps={{ fontWeight: "bold", fontSize: 19 }}
              // sx={{ ml: -2, mb: 0 }}
            />
            <Box
              sx={{
                m: 2,
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <CheckBox color="primary" />
                <Typography>&nbsp;User</Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <CheckBox color="primary" />
                <Typography>&nbsp;Content</Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <CheckBox color="primary" />
                <Typography>&nbsp;Management</Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <CheckBox color="primary" />
                <Typography>&nbsp;Carbon reduction</Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <CheckBox color="primary" />
                <Typography>&nbsp;System</Typography>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default CreateAdminGrid;
