import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridEventListener,
  GridPagination,
  GridRowId,
  GridRowsProp,
  gridPageCountSelector,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid";
import { useState } from "react";
import MuiPagination from "@mui/material/Pagination";
import { useNavigate } from "react-router-dom";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import {
  Box,
  IconButton,
  TablePaginationProps,
  Typography,
} from "@mui/material";

interface FAQMngGridProps {
  faqRows?: GridRowsProp;
  setRowsPerPage?: (value: number) => void;
  rowsPerPage?: number;
}

const FAQMngGrid = ({
  faqRows = [],
  setRowsPerPage,
  rowsPerPage = 10,
}: FAQMngGridProps) => {
  const [page, setPage] = useState(0); // 페이지 번호를 관리할 상태

  const navigate = useNavigate();

  const [expandedRows, setExpandedRows] = useState<{ [key: string]: boolean }>(
    {}
  );
  const toggleExpandRow = (params: any) => {
    setExpandedRows((prev) => ({
      ...prev,
      [params.id]: !prev[params.id],
    }));
  };

  const rowsWithExpansion: GridRowsProp = faqRows.flatMap((row) => {
    const isExpanded = expandedRows[row.id as string];
    return isExpanded
      ? [
          row,
          {
            ...row,
            id: `expanded-${row.id}`,
            no: "",
            title: (
              <Box>
                <Typography variant="body2" style={{ whiteSpace: "pre-wrap" }}>
                  {row.reply.content}
                </Typography>
              </Box>
            ),
            registrationDate: "",
            actions: "",
          },
        ]
      : [row];
  });

  const Pagination = ({
    page,
    onPageChange,
    className,
  }: Pick<TablePaginationProps, "page" | "onPageChange" | "className">) => {
    const apiRef = useGridApiContext();
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    return (
      <MuiPagination
        color="primary"
        className={className}
        count={pageCount}
        page={page + 1}
        shape="rounded"
        onChange={(event, newPage) => {
          onPageChange(event as any, newPage - 1);
        }}
      />
    );
  };

  function CustomPagination(props: any) {
    return <GridPagination ActionsComponent={Pagination} {...props} />;
  }

  const handlePageChange = (newPaginationModel: any) => {
    setPage(newPaginationModel.page);
  };

  const columns: GridColDef[] = [
    {
      field: "no",
      headerName: "No",
      headerAlign: "center",
      align: "center",
      flex: 0.3,
    },
    {
      field: "title",
      headerName: "Title",
      headerAlign: "left",
      renderCell: (params) => {
        const isExpanded = expandedRows[params.id as string];

        return (
          <>
            {params.value}
            <IconButton onClick={() => toggleExpandRow(params)}>
              {isExpanded ? (
                <KeyboardArrowUpIcon fontSize="small" />
              ) : (
                <KeyboardArrowDownIcon fontSize="small" />
              )}
            </IconButton>
          </>
        );
      },
      flex: 3.5,
    },
    {
      field: "registrationDate",
      headerName: "Registration Date",
      type: "string",
      flex: 1,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Show/Hide",
      align: "left",
      headerAlign: "left",
      flex: 1,
      cellClassName: "actions",
      getActions: ({ id }) => [
        <GridActionsCellItem
          icon={<DescriptionOutlinedIcon />}
          label="document"
          className="textPrimary"
          color="inherit"
        />,
      ],
    },
  ];

  const handleRowClick: GridEventListener<"rowClick"> = (params) => {
    navigate(`/grpMng/grpDetails/${params.id}`);
  };

  return (
    <Box
      sx={{
        width: "100%",
        "& .actions": {
          color: "text.secondary",
        },
        "& .textPrimary": {
          color: "text.primary",
        },
        mt: 3,
        borderRadius: 3,
        overflowY: "hidden",
      }}
    >
      <DataGrid
        autoHeight
        rows={rowsWithExpansion}
        columns={columns}
        paginationModel={{ pageSize: rowsPerPage, page }}
        onPaginationModelChange={handlePageChange}
        disableRowSelectionOnClick
        isRowSelectable={() => false}
        pagination
        slots={{
          pagination: CustomPagination,
        }}
        // columnVisibilityModel={{ id: false }}
        isCellEditable={() => false}
        sx={{
          backgroundColor: "white",
          border: 0,
          borderRadius: 4,
          "& .MuiDataGrid-main": {
            borderTopLeftRadius: 18,
            borderTopRightRadius: 18,
          },
        }}
      />
    </Box>
  );
};

export default FAQMngGrid;
