// import React, {
//   ReactNode,
//   createContext,
//   useContext,
//   useEffect,
//   useState
// } from "react";

// // Define the structure of your Methodology data
// interface Methodology {
//   id: number;
//   title: string;
//   methodologyIdName: string;
// }

// // Create the context with an empty array as the default value
// const MethodologyContext = createContext<Methodology[]>([]);

// interface MethodologyProviderProps {
//   children: ReactNode;
// }

// export const MethodologyProvider: React.FC<MethodologyProviderProps> = ({
//   children
// }) => {
//   const [methodologies, setMethodologies] = useState<Methodology[]>([]);

//   useEffect(() => {
//     const fetchMethodologies = async () => {
//       try {
//         const response = await fetch("https://api-kisa.greenery.im/methodology");
//         const data = await response.json();
//         setMethodologies(data.content);
//       } catch (error) {
//         console.error("Error fetching methodologies", error);
//       }
//     };

//     fetchMethodologies();
//   }, []);

//   return (
//     <MethodologyContext.Provider value={methodologies}>
//       {children}
//     </MethodologyContext.Provider>
//   );
// };

// export const useMethodologies = () => useContext(MethodologyContext);
import React, {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState
} from "react";

// Define the structure of your Methodology data
export interface Methodology {
  id: number;
  title: string;
  methodologyIdName: string;
  area?: string;
  subArea?: string;
}

// Create the context with undefined as the default value
const MethodologyContext = createContext<Methodology[] | undefined>(undefined);

interface MethodologyProviderProps {
  children: ReactNode;
}

export const MethodologyProvider: React.FC<MethodologyProviderProps> = ({
  children
}) => {
  const [methodologies, setMethodologies] = useState<Methodology[]>([]);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchMethodologies = async () => {
      try {
        const response = await fetch(
          "https://api-kisa.greenery.im/methodology?size=100"
        );
        const data = await response.json();
        // Validate data structure if needed
        if (Array.isArray(data.content)) {
          setMethodologies(data.content);
        } else {
          console.error("Invalid data structure", data);
          setError("Invalid data structure");
        }
      } catch (error) {
        console.error("Error fetching methodologies", error);
        setError("Failed to fetch methodologies");
      }
    };

    fetchMethodologies();
  }, []);

  return (
    <MethodologyContext.Provider value={methodologies}>
      {children}
      {/* Optionally render an error message or fallback UI */}
      {error && <div>Error: {error}</div>}
    </MethodologyContext.Provider>
  );
};

export const useMethodologies = () => {
  const context = useContext(MethodologyContext);
  if (context === undefined) {
    throw new Error(
      "useMethodologies must be used within a MethodologyProvider"
    );
  }
  return context;
};
