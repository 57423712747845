import { Box, Button, CardHeader } from "@mui/material";
import React, { ReactNode } from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

interface CommonListProps {
  title: string;
  setModal?: any;
  children?: ReactNode;
  headerButton?: ReactNode;
  headerBackButton?: ReactNode;
  sx?: React.CSSProperties;
}

const CommonList = ({
  title,
  children,
  headerButton,
  headerBackButton
}: CommonListProps) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("xl"));

  return (
    <Box sx={{ mt: 3, pb: 3, width: isSmallScreen ? "100%" : 1100 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 1
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {headerBackButton}
          <CardHeader
            title={title}
            titleTypographyProps={{ fontWeight: "bold", fontSize: 30 }}
            sx={{ ml: -2, mb: 0 }}
          />
        </Box>
        {headerButton}
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column"
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default CommonList;
