import React from "react";
import ClippedDrawer from "./layout/BaseLayout/CommonLayout";
import { MethodologyProvider } from "./context/MethodologyContext";

function App() {
  return (
    <MethodologyProvider>
      <ClippedDrawer />
    </MethodologyProvider>
  );
}

export default App;
