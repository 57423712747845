import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  Box,
  Divider,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

const TAX_RATE = 0.07;

function ccyFormat(num: number) {
  return `${num.toFixed(2)}`;
}

function priceRow(qty: number, unit: number) {
  return qty * unit;
}

function createRow(desc: string, qty: number, unit: number) {
  const price = priceRow(qty, unit);
  return { desc, qty, unit, price };
}

interface Row {
  desc: string;
  qty: number;
  unit: number;
  price: number;
}

function subtotal(items: readonly Row[]) {
  return items.map(({ price }) => price).reduce((sum, i) => sum + i, 0);
}

const rows = [
  {
    type: "init",
    name: "Repair Cost",
    qty: 1,
    price: 3000,
  },
  { type: "new", name: "Product1", qty: 3, price: 1000 },
  { type: "new", name: "Product2", qty: 2, price: 1000 },
];

// const invoiceSubtotal = subtotal(rows);
// const invoiceTaxes = TAX_RATE * invoiceSubtotal;
// const invoiceTotal = invoiceTaxes + invoiceSubtotal;

const SpanningTable = () => {
  return (
    <Box>
      <TableContainer component={Paper} elevation={0}>
        <Table sx={{ minWidth: 700 }} aria-label="spanning table">
          <TableHead>
            <TableRow>
              <TableCell align="left">Name</TableCell>
              <TableCell sx={{ width: 120 }} align="left">
                Count
              </TableCell>
              <TableCell align="left">Price</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row.name}
                sx={{
                  ".MuiTableCell-root": {
                    border: 0,
                    paddingTop: 0.5,
                    paddingBottom: 0.5,
                  },
                }}
              >
                <TableCell>
                  {row.type == "init" ? (
                    <TextField
                      sx={{ width: "100%" }}
                      value={row.name}
                      InputProps={{
                        style: {
                          borderRadius: "10px",
                        },
                      }}
                    />
                  ) : (
                    <Select sx={{ width: "100%", borderRadius: 2.5 }}>
                      <MenuItem value="Product1">Product1</MenuItem>
                      <MenuItem value="Product2">Product2</MenuItem>
                      <MenuItem value="Product3">Product3</MenuItem>
                    </Select>
                  )}
                </TableCell>
                <TableCell align="right">
                  {row.type == "init" ? null : (
                    <TextField
                      inputProps={{ min: 0, style: { textAlign: "right" } }}
                      value={row.qty}
                      InputProps={{
                        style: {
                          borderRadius: "10px",
                        },
                      }}
                    />
                  )}
                </TableCell>
                <TableCell align="right">
                  <TextField
                    sx={{ width: "100%" }}
                    inputProps={{ min: 0, style: { textAlign: "right" } }}
                    value={row.price * row.qty}
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                      },
                    }}
                  />
                </TableCell>
                {/* <TableCell align="right">{ccyFormat(row.price)}</TableCell> */}
              </TableRow>
            ))}
            {/* <TableRow>
            <TableCell rowSpan={3} />
            <TableCell colSpan={2}>Subtotal</TableCell>
            <TableCell align="right">{ccyFormat(invoiceSubtotal)}</TableCell>
          </TableRow> */}
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
        <IconButton
          sx={{
            backgroundColor: "#53515A",
            color: "white",

            ":hover": { backgroundColor: "#8E8E8E" },
          }}
        >
          <AddIcon fontSize={"large"} />
        </IconButton>
      </Box>
      <Divider sx={{ mt: 3 }} />
      <Box sx={{ mt: 1, display: "flex", justifyContent: "space-between" }}>
        <Typography fontSize={30} fontWeight={"bold"}>
          Total Fee
        </Typography>
        <Typography fontSize={30} fontWeight={"bold"}>
          $ 6,000
        </Typography>
      </Box>
      <Divider sx={{ mt: 3 }} />
    </Box>
  );
};
export default SpanningTable;
