import { Button, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { useEffect, useState } from "react";
import CommonList from "../../component/CommonList";
import CommonSearch from "../../component/CommonSearch";
import AddIcon from "@mui/icons-material/Add";
import { GridRowsProp } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import AnnounceGrid from "./section/AnnounceGrid";

const AnnouncementPage = () => {
  const navigate = useNavigate();

  const [usrStatus, setUsrStatus] = useState("All");

  const handleUsrStatus = (event: SelectChangeEvent) => {
    setUsrStatus(event.target.value as string);
  };

  const renderValue = (value: any) => {
    if (value === "All") {
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            // justifyContent: "center",
          }}
        >
          All
        </div>
      );
    }
    if (value === "show") {
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            // justifyContent: "center",
          }}
        >
          <VisibilityOutlinedIcon /> &nbsp; Show
        </div>
      );
    }
    if (value === "hide") {
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            // justifyContent: "center",
          }}
        >
          <VisibilityOffOutlinedIcon /> &nbsp; Hide
        </div>
      );
    }
    return value;
  };

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [announceRows, setFaqRows] = useState<GridRowsProp>([
    {
      id: "test",
      no: "1",
      title: "스테이션 운영시간 변경 안내",
      status: "Show",
      openDate: "22/12/26 23:00",
      registrationDate: "2022/12/26 23:00",
      view: "15",
    },
    {
      id: "test2",
      no: "2",
      title: "스테이션 운영시간 변경 안내",
      status: "Hide",
      openDate: "22/12/26 23:00",
      registrationDate: "2022/12/26 23:00",
      view: "15",
    },
  ]);

  const HeaderButton = () => {
    return (
      <Button
        sx={{
          height: 40,
          backgroundColor: "#5D38E5",
          color: "white",
          borderRadius: 5,
          width: 110,
          ":hover": {
            backgroundColor: "#A38BFC",
          },
        }}
        onClick={() => {
          navigate("/announcement/createAnnounce");
        }}
      >
        <AddIcon fontSize="small" />
        등록
      </Button>
    );
  };

  return (
    <CommonList title="공지사항 관리" headerButton={HeaderButton()}>
      <CommonSearch setRowsPerPage={setRowsPerPage}>
        <Select
          id="paging"
          value={usrStatus}
          onChange={handleUsrStatus}
          size="small"
          sx={{
            width: "15%",
            borderRadius: "10px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          renderValue={renderValue}
        >
          <MenuItem value={"All"}>All</MenuItem>
          <MenuItem value={"show"}>
            <VisibilityOutlinedIcon /> &nbsp; Show
          </MenuItem>
          <MenuItem value={"hide"}>
            <VisibilityOffOutlinedIcon /> &nbsp; Hide
          </MenuItem>
        </Select>
      </CommonSearch>
      <AnnounceGrid
        setRowsPerPage={setRowsPerPage}
        rowsPerPage={rowsPerPage}
        rows={announceRows}
      />
    </CommonList>
  );
};

export default AnnouncementPage;
