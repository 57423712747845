import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import styled from "styled-components";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import {
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormGroup,
  FormControl,
  FormLabel,
  Table,
  TableRow,
  TableCell,
  Checkbox,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  Typography,
  Box,
  Grid,
  IconButton,
  InputAdornment
} from "@mui/material";
import { ReactNode } from "react";
import { SelectChangeEvent } from "@mui/material";
import React, { SyntheticEvent, ChangeEvent, useEffect, useState } from "react";
import { ArgumentDetailFormData, ValueListItem } from "../ParamDetailsPage";
import { ParamType } from "../../../../store/paramType";
import { Methodology } from "../../../../context/MethodologyContext";
import DoNotDisturbOnIcon from "@mui/icons-material/DoNotDisturbOn";
import { PurposeEnum } from "../../../../store/paramType";
interface ParamFixedProps {
  formData: ArgumentDetailFormData;
  setFormData: React.Dispatch<React.SetStateAction<ArgumentDetailFormData>>;
  methodologies: Methodology[];
  onHandleMethodologyChange: (event: SelectChangeEvent<number>) => void;
}
interface Country {
  translations: {
    kor: { common: string };
  };
}

interface CustomTypographyProps {
  children: ReactNode;
  sx?: object;
}

const CustomTextField = styled(TextField)(({ theme }) => ({
  width: "100%",
  minHeight: "38px",
  padding: "12px 16px",
  borderRadius: "10px",
  border: "1px solid transparent",
  opacity: 1,
  marginLeft: "10px"
}));

const ParamFixed: React.FC<ParamFixedProps> = ({
  formData,
  setFormData,
  methodologies,
  onHandleMethodologyChange
}) => {
  const [valueList, setValueList] = useState<ValueListItem[] | []>([]);
  const [load, setLoad] = useState(false);
  const [test, setTest] = useState("");
  const [isComposing, setIsComposing] = useState(false);

  const handleInputChange: React.ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (event) => {
    const { name, value } = event.target;
    setFormData((prev: ArgumentDetailFormData) => ({ ...prev, [name]: value }));
  };

  const handleValueInputChange = (event: any, idx: number) => {
    event.stopPropagation();
    event.preventDefault();
    const { value, name } = event.target;
    console.log("event ------------>", event);
    setTest(value);
    setValueList((prev) => {
      return [...prev].map((el: any, index) => {
        return idx === index
          ? {
              ...el,
              [name]: value
            }
          : el;
      });
    });
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    idx: number
  ) => {
    if (!isComposing) {
      handleValueInputChange(e, idx);
    }
  };

  const handleCheckboxChange = (
    name: string,
    value: string,
    checked: boolean
  ) => {
    setFormData((prev) => {
      const prevList: PurposeEnum[] = Array.isArray(prev?.purpose)
        ? prev.purpose
        : [];
      const newValue = prevList.includes(value as PurposeEnum)
        ? prevList.filter((v: PurposeEnum) => v !== value)
        : [...prevList, value as PurposeEnum];

      return { ...prev, purpose: newValue };
    });
  };

  const handleSelectChange = (event: SelectChangeEvent<ParamType>) => {
    const { name, value } = event.target;
    setFormData((prev: ArgumentDetailFormData) => ({ ...prev, [name]: value }));
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0] || null;
    setFormData((prevFormData: ArgumentDetailFormData) => ({
      ...prevFormData,
      relatedDocumentFile: file
    }));
  };

  const addItem = () => {
    setValueList([...valueList, { id: Date.now(), item: "", value: 0 }]);
  };

  const removeItem = (index: number) => {
    setValueList((prev) => prev.filter((_, idx) => idx !== index));
  };

  useEffect(() => {
    setFormData((prevFormData: ArgumentDetailFormData) => ({
      ...prevFormData,
      valueList: valueList
    }));
  }, [valueList, setFormData]);

  useEffect(() => {
    if (formData.valueList) {
      setValueList(formData.valueList);
    }
    setLoad(true);
  }, []);

  const [countries, setCountries] = useState<string[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await fetch("https://restcountries.com/v3.1/all");
        const data = await response.json();

        const countryNames = data.map(
          (country: Country) => country.translations.kor.common
        );

        setCountries(countryNames);
      } catch (error) {
        console.error("Error fetching countries:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchCountries();
  }, []);

  // Filter countries based on search input
  const filteredCountries = countries.filter((country) =>
    country.includes(searchTerm)
  );

  const CustomTypography: React.FC<CustomTypographyProps> = ({
    children,
    ...props
  }) => {
    return (
      <Typography
        {...props}
        sx={{
          color: "#99989D",
          ...props.sx,
          lineHeight: "18px",
          fontSize: "14px",
          fontWeight: 700,
          width: "80px"
        }}
      >
        {children}
      </Typography>
    );
  };

  return (
    <Grid container spacing={6}>
      <Grid item xs={6}>
        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mt: 5
            }}
          >
            <CustomTypography
              sx={{
                fontWeight: "bold",
                mt: 2.5
              }}
            >
              데이터/인자
            </CustomTypography>
            <CustomTextField
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              InputProps={{
                style: {
                  borderRadius: "10px",
                  height: "40px"
                }
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mt: 2
            }}
          >
            <CustomTypography sx={{ fontWeight: "bold", mt: 2.5 }}>
              단위
            </CustomTypography>
            <CustomTextField
              name="unit"
              value={formData.unit}
              onChange={handleInputChange}
              InputProps={{
                style: {
                  borderRadius: "10px",
                  height: "40px"
                }
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mt: 2
            }}
          >
            <CustomTypography sx={{ fontWeight: "bold", mt: 2.5 }}>
              정의
            </CustomTypography>
            <CustomTextField
              name="definition"
              value={formData.definition}
              onChange={handleInputChange}
              InputProps={{
                style: {
                  borderRadius: "10px",
                  height: "40px"
                }
              }}
            />
          </Box>

          <Box sx={{ mt: 4, mb: 6 }}>
            <CustomTypography sx={{ fontWeight: "bold", mt: 2.5, mb: 2 }}>
              적용된 값
            </CustomTypography>
            <Table>
              <TableRow>
                <TableCell>항목</TableCell>
                <TableCell>값</TableCell>
              </TableRow>
              {formData?.valueList?.map((el, idx) => (
                <TableRow key={`form-data-value-list-${idx}`}>
                  <TableCell sx={{ padding: "5px 4px", border: "none" }}>
                    <CustomTextField
                      name={"item"}
                      value={el.item}
                      onChange={(e) => {
                        handleValueInputChange(e, idx);
                      }}
                      sx={{
                        width: "100%",
                        borderRadius: "10px"
                      }}
                      InputProps={{
                        style: {
                          borderRadius: "10px",
                          height: "40px"
                        }
                      }}
                    />
                  </TableCell>
                  <TableCell sx={{ padding: "2px 8px", border: "none" }}>
                    <CustomTextField
                      name="value"
                      value={el.value}
                      onChange={(e) => handleValueInputChange(e, idx)}
                      sx={{
                        borderRadius: "10px"
                      }}
                      InputProps={{
                        style: {
                          borderRadius: "10px",
                          height: "40px"
                        }
                      }}
                    />
                  </TableCell>
                  <TableCell
                    padding="none"
                    sx={{ width: "5%", padding: "4px", border: 0 }}
                  >
                    <IconButton onClick={() => removeItem(idx)}>
                      <DoNotDisturbOnIcon sx={{ fontSize: 20 }} />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </Table>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                mt: 2,
                marginRight: "15px"
              }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={addItem}
                sx={{
                  mt: 2,
                  width: "50%",
                  display: "flex",
                  justifyContent: "center",
                  borderRadius: "20px",
                  height: "35px",
                  background: "gray"
                }}
                startIcon={<AddIcon />}
              >
                Add to List
              </Button>
            </Box>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={6}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mt: 2
          }}
        >
          <CustomTypography sx={{ fontWeight: "bold", mt: 5 }}>
            방법론명
          </CustomTypography>
          <Select
            name="methodologyId"
            value={formData.methodologyId || ""}
            onChange={onHandleMethodologyChange}
            IconComponent={(props) => (
              <KeyboardArrowDown {...props} sx={{ fontSize: "16px" }} />
            )}
            sx={{
              marginRight: "15px",
              height: "40px",
              width: "392px",
              minHeight: "40px",
              opacity: 1,
              borderRadius: "10px",
              mt: 4.2,
              gap: "2px",
              backgroundColor: "#ffff",
              "&:hover": {
                borderColor: "#F9F6FD"
              }
            }}
            style={{ borderRadius: "10px" }}
            renderValue={(val) => {
              const selected =
                methodologies.find((e: any) => e.id === val) || null;
              return selected ? `${selected?.title}` : `방법론 선택`;
            }}
          >
            {methodologies.length > 0 ? (
              methodologies.map((methodology) => (
                <MenuItem key={methodology.id} value={methodology.id}>
                  {methodology.title}
                </MenuItem>
              ))
            ) : (
              <MenuItem value="" disabled>
                조회 데이터가 없습니다.
              </MenuItem>
            )}
          </Select>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mt: 2
          }}
        >
          <CustomTypography sx={{ fontWeight: "bold", mt: 2.5 }}>
            데이터 목적
          </CustomTypography>
          <FormGroup row sx={{ width: "80%", mt: 1 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.purpose?.includes(PurposeEnum.BASELINE)}
                  name="BASELINE"
                  onChange={(
                    event: SyntheticEvent<Element, Event>,
                    checked: boolean
                  ) => handleCheckboxChange("purpose", "BASELINE", checked)}
                />
              }
              label={
                <Typography sx={{ fontSize: "13px" }}>베이스라인</Typography>
              }
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.purpose?.includes(PurposeEnum.PROJECT)}
                  name="PROJECT"
                  onChange={(
                    event: SyntheticEvent<Element, Event>,
                    checked: boolean
                  ) => handleCheckboxChange("purpose", "PROJECT", checked)}
                />
              }
              label={
                <Typography sx={{ fontSize: "13px" }}>프로젝트</Typography>
              }
            />
          </FormGroup>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mt: 2
          }}
        >
          <CustomTypography sx={{ fontWeight: "bold", mt: 2.5 }}>
            데이터 출처
          </CustomTypography>
          <CustomTextField
            name="output"
            value={formData.output}
            onChange={(e) =>
              setFormData({ ...formData, output: e.target.value })
            }
            InputProps={{
              style: {
                borderRadius: "10px",
                height: "40px"
              }
            }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mt: 2
          }}
        >
          <CustomTypography sx={{ fontWeight: "bold", mt: 2.5 }}>
            버전
          </CustomTypography>
          <CustomTextField
            name="version"
            value={formData.version}
            onChange={handleInputChange}
            InputProps={{
              style: {
                borderRadius: "10px",
                height: "40px"
              }
            }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mt: 2
          }}
        >
          <CustomTypography sx={{ fontWeight: "bold", mt: 2.5 }}>
            지역
          </CustomTypography>
          <Select
            name="region"
            onChange={handleSelectChange}
            value={formData.region as "" | undefined}
            style={{ borderRadius: "10px" }}
            renderValue={(val) => val || "선택하세요"}
            IconComponent={(props) => (
              <KeyboardArrowDown {...props} sx={{ fontSize: "16px" }} />
            )}
            sx={{
              marginRight: "15px",
              width: "392px",
              opacity: 1,
              borderRadius: "10px",
              mt: 2,
              gap: "2px",
              height: "40px",
              backgroundColor: "#ffff",
              "&:hover": {
                borderColor: "#F9F6FD"
              }
            }}
          >
            <CustomTextField
              placeholder="검색..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              InputProps={{
                style: { borderRadius: "10px", height: "40px", width: "380px" },
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                )
              }}
              sx={{ mb: 1, ml: 1 }}
            />
            {filteredCountries.map((country, index) => (
              <MenuItem key={index} value={country}>
                {country}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mt: 2
          }}
        >
          <CustomTypography sx={{ fontWeight: "bold", mt: 2.5 }}>
            기타 의견
          </CustomTypography>
          <CustomTextField
            name="otherComments"
            value={formData.otherComments}
            onChange={handleInputChange}
            multiline
            minRows={4}
            InputProps={{
              style: {
                borderRadius: "10px",
                height: "95px"
              }
            }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mt: 2
          }}
        >
          <CustomTypography sx={{ fontWeight: "bold", mt: 2.5 }}>
            관련 문서
          </CustomTypography>
          <CustomTextField
            type="file"
            onChange={handleFileChange}
            InputProps={{
              style: {
                borderRadius: "10px",
                height: "40px"
              }
            }}
            id="file-upload"
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export default ParamFixed;
