import {
  Dialog,
  IconButton,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useEffect, useState } from "react";

import { format, addDays, addHours, addMinutes } from "date-fns";
import { useNavigate } from "react-router-dom";

const CustomCell = ({
  title,
  align,
}: {
  title: string;
  align: "left" | "center" | "right";
}) => {
  return (
    <TableCell
      sx={{
        backgroundColor: "white",
        color: "black",
        fontWeight: 600,
        fontFamily: "var(--bs-font-sans-serif)",
      }}
      align={align}
    >
      {title}
    </TableCell>
  );
};

const createProductData = (
  No: number,
  UsrId: number,
  UserType: string,
  Email: string,
  UserName: string,
  PhoneNumber: string,
  Status: string,
  RegistrationDate: string
) => {
  return {
    No,
    UsrId,
    UserType,
    Email,
    UserName,
    PhoneNumber,
    Status,
    RegistrationDate,
  };
};

const generateRandomDateTime = () => {
  const startDate = new Date(2020, 0, 1); // 시작 날짜 설정
  const endDate = new Date(); // 현재 날짜
  const randomDate = addDays(
    startDate,
    Math.floor(
      (Math.random() * (endDate.getTime() - startDate.getTime())) /
        (1000 * 60 * 60 * 24)
    )
  );
  const randomDateTime = addHours(randomDate, Math.floor(Math.random() * 24)); // 시간 추가
  const randomDateTimeWithMinutes = addMinutes(
    randomDateTime,
    Math.floor(Math.random() * 60)
  ); // 분 추가
  return format(randomDateTimeWithMinutes, "yyyy-MM-dd HH:mm:ss"); // ISO 형식으로 반환 (시간:분:초 포함)
};

const generateRandomEmail = () => {
  const domainList = [
    "gmail.com",
    "yahoo.com",
    "hotmail.com",
    "outlook.com",
    "aol.com",
  ];
  const usernameLength = Math.floor(Math.random() * 10) + 5; // 5~14 글자의 임의의 길이
  const username = Array.from(
    { length: usernameLength },
    () => Math.random().toString(36)[2]
  ).join(""); // 임의의 문자열 생성
  const domain = domainList[Math.floor(Math.random() * domainList.length)]; // 무작위 도메인 선택
  return `${username}@${domain}`;
};

const generateRandomPhoneNumber = () => {
  const firstPart = Math.floor(Math.random() * 10000); // 첫 번째 일련 번호 생성 (0 ~ 9999)
  const secondPart = Math.floor(Math.random() * 10000); // 두 번째 일련 번호 생성 (0 ~ 9999)
  return `010-${String(firstPart).padStart(4, "0")}-${String(
    secondPart
  ).padStart(4, "0")}`;
};

const generateRandomUserName = () => {
  const firstNameList = [
    "James",
    "John",
    "Robert",
    "Michael",
    "William",
    "David",
    "Richard",
    "Joseph",
    "Thomas",
    "Charles",
  ];
  const lastNameList = [
    "Smith",
    "Johnson",
    "Williams",
    "Brown",
    "Jones",
    "Garcia",
    "Miller",
    "Davis",
    "Rodriguez",
    "Martinez",
  ];
  const firstName =
    firstNameList[Math.floor(Math.random() * firstNameList.length)];
  const lastName =
    lastNameList[Math.floor(Math.random() * lastNameList.length)];
  return `${firstName} ${lastName}`;
};

const generateRandomProductData = () => {
  const randomData = [];
  const userTypes = ["관리자", "일반 사용자"]; // 사용자 유형 목록

  for (let i = 0; i < 100; i++) {
    const areaCode = Math.floor(Math.random() * 10000000) + 100; // 지역 코드 생성 (100 ~ 999)
    const registrationDate = generateRandomDateTime(); // 무작위 날짜 생성
    const userType = userTypes[Math.floor(Math.random() * userTypes.length)];
    const email = generateRandomEmail(); // 무작위 이메일 생성
    const phoneNumber = generateRandomPhoneNumber(); // 무작위 전화번호 생성
    const userName = generateRandomUserName(); // 무작위 사용자 이름 생성
    randomData.push(
      createProductData(
        i + 1,
        areaCode,
        userType,
        email,
        userName,
        phoneNumber,
        `활동`,
        registrationDate
      )
    );
  }
  return randomData;
};

const userRows = generateRandomProductData();

const UserTable = ({ rowsPerPage }: { rowsPerPage: number }) => {
  const navigate = useNavigate();

  const [page, setPage] = useState(0);
  const [userDetail, setUserDetail] = useState(false);
  const [userVehicle, setUserVehicle] = useState(false);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage - 1);
  };

  const handleUserDetail = () => {
    setUserDetail(true);
  };

  const handleUserVehicle = () => {
    setUserVehicle(true);
  };

  const handleRowClick = (RowId: number) => {
    console.log(RowId);
    navigate(`/usrMng/usrDetails/${RowId}`);
  };

  useEffect(() => {
    console.log(userRows);
    setPage(0);
  }, [rowsPerPage]);

  return (
    <Paper
      sx={{
        width: "100%",
        border: 1,
        borderColor: "#F3F3F4",
        borderRadius: 3,
        mt: 3,
      }}
      elevation={0}
    >
      <TableContainer sx={{ borderRadius: 3 }}>
        <Table
          sx={{
            width: "100%",
            // m: 3,
            // width: 900,
          }}
          stickyHeader
          aria-label="sticky table"
        >
          <TableHead>
            <TableRow sx={{}}>
              <CustomCell title="No" align="center" />
              <CustomCell title="User Type" align="center" />
              <CustomCell title="Email ID" align="center" />
              <CustomCell title="User Name" align="center" />
              <CustomCell title="Phone Number" align="center" />
              <CustomCell title="Status" align="center" />
              <CustomCell title="Registration Date" align="center" />
            </TableRow>
          </TableHead>
          <TableBody>
            {userRows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => (
                <TableRow
                  key={row.No}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    ":hover": { backgroundColor: "#F6F9FC", cursor: "pointer" },
                  }}
                  onClick={() => handleRowClick(row.UsrId)}
                >
                  <TableCell component="th" scope="row" align="center">
                    {row.No}
                  </TableCell>
                  <TableCell align="center">{row.UserType}</TableCell>
                  <TableCell align="center">{row.Email}</TableCell>
                  <TableCell align="center">{row.UserName}</TableCell>
                  <TableCell align="center">{row.PhoneNumber}</TableCell>
                  <TableCell align="center">{row.Status}</TableCell>
                  <TableCell align="center">{row.RegistrationDate}</TableCell>
                  {/* <TableCell align="center">
                    <IconButton
                      sx={{
                        height: 20,
                        width: 20,
                        color: "#539BFF",
                        ":hover": { backgroundColor: "#F6F9FC" },
                      }}
                      onClick={handleUserDetail}
                    >
                      <PersonOutlineOutlinedIcon fontSize="small" />
                    </IconButton>
                    <IconButton
                      sx={{
                        height: 20,
                        width: 20,
                        color: "#539BFF",
                        ":hover": { backgroundColor: "#F6F9FC" },
                      }}
                      onClick={handleUserVehicle}
                    >
                      <ElectricMopedOutlinedIcon fontSize="small" />
                    </IconButton>
                  </TableCell> */}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        sx={{ mt: 2, mb: 2, ml: 2, width: "100%" }}
        count={Math.ceil(userRows.length / rowsPerPage)}
        shape="rounded"
        defaultPage={1}
        page={page + 1}
        color="primary"
        onChange={handleChangePage}
      />
    </Paper>
  );
};

export default UserTable;
