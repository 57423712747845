import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText
} from "@mui/material";
import { ReactNode } from "react";

interface CommonDialogProps {
  children?: ReactNode;
  open?: any;
  handleClose?: any;
  handleOk?: any;
}

const CommonDialog = ({
  children,
  open,
  handleClose,
  handleOk
}: CommonDialogProps) => {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogContent
        sx={{
          width: 450,
          height: 170,
          // mt: -1,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column"
        }}
      >
        <DialogContentText
          id="alert-dialog-description"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            fontSize: 15,
            color: "black"
          }}
        >
          {children}
        </DialogContentText>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            mt: 1.5
          }}
        >
          <Button
            onClick={handleClose}
            sx={{
              textTransform: "none",
              height: 40,
              //   backgroundColor: "#5D38E5",
              color: "#5D38E5",
              borderRadius: 5,
              border: 1,
              borderColor: "#5D38E5",
              width: 95,
              ":hover": {
                backgroundColor: "#E9E9E9"
              }
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleOk}
            sx={{
              textTransform: "none",
              height: 40,
              backgroundColor: "#5D38E5",
              color: "white",
              borderRadius: 5,
              width: 75,
              ml: 2,
              ":hover": {
                backgroundColor: "#A38BFC"
              }
            }}
            autoFocus
          >
            OK
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default CommonDialog;
