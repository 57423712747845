import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  IconButton,
} from "@mui/material";
import CommonList from "../../component/CommonList";
import { useNavigate } from "react-router-dom";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import AnnounceCreateGrid from "./section/AnnounceCreateGrid";
import { useEffect, useState } from "react";

const AnnouncementCreatePage = () => {
  const navigate = useNavigate();

  const HeaderButton = () => {
    return (
      <Button
        sx={{
          height: 40,
          backgroundColor: "#5D38E5",
          color: "white",
          borderRadius: 5,
          width: 85,
          ":hover": {
            backgroundColor: "#A38BFC",
          },
        }}
        onClick={() => {
          //   navigate("/FAQMng/FAQCreate");
        }}
      >
        등록
      </Button>
    );
  };
  // const handleNavigate = (route: string) => {
  //   navigate(route);
  //   // 페이지를 새로고침
  //   window.location.reload();
  // };
  const HeaderBackButton = () => {
    return (
      <IconButton
        sx={{
          ":hover": { backgroundColor: "#F2F2F2" },
          mb: 0.5,
          mr: 1,
        }}
        onClick={() => {
          handleClickOpen();
        }}
      >
        <ArrowBackIosNewIcon fontSize="medium" />
      </IconButton>
    );
  };

  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleOk = () => {
    navigate("/announcement");
    window.location.reload();
  };
  return (
    <CommonList
      title="공지사항 등록"
      headerButton={HeaderButton()}
      headerBackButton={HeaderBackButton()}
    >
      <Dialog open={open} onClose={handleClose}>
        <DialogContent
          sx={{
            width: 450,
            height: 170,
            // mt: -1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <DialogContentText
            id="alert-dialog-description"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
              fontSize: 15,
              color: "black",
            }}
          >
            작성하던 것을 중단하고 이동하시겠습니까? <br />
            해당 사항은 저장되지 않습니다.
          </DialogContentText>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
              mt: 1.5,
            }}
          >
            <Button
              onClick={handleClose}
              sx={{
                textTransform: "none",
                height: 40,
                //   backgroundColor: "#5D38E5",
                color: "#5D38E5",
                borderRadius: 5,
                border: 1,
                borderColor: "#5D38E5",
                width: 95,
                ":hover": {
                  backgroundColor: "#E9E9E9",
                },
              }}
            >
              Cancle
            </Button>
            <Button
              onClick={handleOk}
              sx={{
                textTransform: "none",
                height: 40,
                backgroundColor: "#5D38E5",
                color: "white",
                borderRadius: 5,
                width: 75,
                ml: 2,
                ":hover": {
                  backgroundColor: "#A38BFC",
                },
              }}
              autoFocus
            >
              OK
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
      <AnnounceCreateGrid />
    </CommonList>
  );
};

export default AnnouncementCreatePage;
