import AirlineStopsOutlinedIcon from "@mui/icons-material/AirlineStopsOutlined";
import ManageHistoryOutlinedIcon from "@mui/icons-material/ManageHistoryOutlined";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import DnsOutlinedIcon from "@mui/icons-material/DnsOutlined";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import NavigationOutlinedIcon from "@mui/icons-material/NavigationOutlined";
import FiberManualRecordOutlinedIcon from "@mui/icons-material/FiberManualRecordOutlined";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";
import BatteryCharging30OutlinedIcon from "@mui/icons-material/BatteryCharging30Outlined";
import BallotOutlinedIcon from "@mui/icons-material/BallotOutlined";
import ConstructionOutlinedIcon from "@mui/icons-material/ConstructionOutlined";
import RoomOutlinedIcon from "@mui/icons-material/RoomOutlined";
import EngineeringOutlinedIcon from "@mui/icons-material/EngineeringOutlined";
import MiscellaneousServicesOutlinedIcon from "@mui/icons-material/MiscellaneousServicesOutlined";

export interface EvmsMenuItem {
  title: string;
  type: string;
  url: string;
  Icon?: JSX.Element;
  Children?: EvmsMenuItem[];
}

const ItemIcon = () => {
  return (
    <FiberManualRecordOutlinedIcon
      sx={{
        width: 12,
        height: 12,
        ml: 1.5,
      }}
    />
  );
};

export const EvmsMenuList: { [key: string]: EvmsMenuItem } = {
  Home: {
    title: "",
    type: "group",
    url: "",
    Children: [
      {
        title: "DashBoard",
        type: "collapse",
        url: "",
        Icon: <DashboardOutlinedIcon />,
        Children: [
          {
            title: "제조",
            type: "item",
            url: "/manufacture",
            Icon: ItemIcon(),
          },
          {
            title: "운영",
            type: "item",
            url: "/operate",
            Icon: ItemIcon(),
          },
        ],
      },
      {
        title: "제조 관리",
        type: "group",
        url: "",
        Children: [
          {
            title: "제조품목 관리",
            type: "collapse",
            url: "",
            Icon: <Inventory2OutlinedIcon />,
            Children: [
              {
                title: "Material",
                type: "item",
                url: "/material",
                Icon: ItemIcon(),
              },
              {
                title: "Product",
                type: "item",
                url: "/product",
                Icon: ItemIcon(),
              },
            ],
          },
          {
            title: "소모품 관리",
            type: "item",
            url: "/expendMng",
            Icon: <BatteryCharging30OutlinedIcon />,
          },
          {
            title: "스펙관리",
            type: "item",
            url: "/specMng",
            Icon: <BallotOutlinedIcon />,
          },
        ],
      },
      {
        title: "운영",
        type: "group",
        url: "",
        Children: [
          {
            title: "운영품목 관리",
            type: "collapse",
            url: "",
            Icon: <MiscellaneousServicesOutlinedIcon />,
            Children: [
              {
                title: "Station",
                type: "item",
                url: "/station",
                Icon: ItemIcon(),
              },
              {
                title: "Vehicle",
                type: "item",
                url: "/vehicle",
                Icon: ItemIcon(),
              },
              {
                title: "Bettery",
                type: "item",
                url: "/bettery",
                Icon: ItemIcon(),
              },
            ],
          },
        ],
      },
      {
        title: "another",
        type: "another",
        url: "",
        Children: [
          {
            title: "AS 관리",
            type: "item",
            url: "/ASList",
            Icon: <ConstructionOutlinedIcon />,
          },
          {
            title: "유저관리",
            type: "collapse",
            url: "",
            Icon: <PeopleOutlineIcon />,
            Children: [
              {
                title: "회원 관리",
                type: "item",
                url: "/usrMng",
                Icon: ItemIcon(),
              },
              {
                title: "RFID 관리",
                type: "item",
                url: "/RFIDList",
                Icon: ItemIcon(),
              },
            ],
          },
          {
            title: "지점 관리",
            type: "item",
            url: "/spotMng",
            Icon: <RoomOutlinedIcon />,
          },
          {
            title: "관리자 계정 관리",
            type: "item",
            url: "/adminMng",
            Icon: <EngineeringOutlinedIcon />,
          },
        ],
      },
    ],
  },
};
