export enum ParamType {
  // Categories
  BASELINE = "BASELINE",
  PROJECT = "PROJECT",

  // Types
  FUEL_EFFICIENCY = "FUEL_EFFICIENCY",
  EMISSION_FACTOR = "EMISSION_FACTOR",
  NET_CALORIC_VALUE = "NET_CALORIC_VALUE",

  // Units
  MJ_I = "MJ_I",
  KG_CO2_EQ_KM = "KG_CO2_EQ_KM",

  //Standard
  CHANGE = "CHANGE",
  FIXED = "FIXED",
  RESULT = "RESULT"

  //Purpose
}

export enum PurposeEnum {
  BASELINE = "BASELINE",
  PROJECT = "PROJECT",
  FINAL_REDUCTION = "FINAL_REDUCTION"
}

export interface Param {
  id: number;
  name: string;
  unit: ParamType;
  definition: string;
  version: string;
  methodology: {
    methodologyIdName: string;
  };

  standard: ParamType;
  region?: string | undefined;
  relatedDocumentFile?: string | null;
  createdAt: string;
  updatedAt: string;
  purpose: ParamType;
  output: string;
  qaqcProcedure: string;
  otherComments: string;
  valueList: any[];
}

interface ValueListItem {
  id: number;
  item: string;
  value: number;
}

export interface FormData {
  id?: number;
  standard: ParamType;
  name: string;
  unit: ParamType;
  definition: string;
  purpose: ParamType;
  output: string;
  measurementProcedure?: string;
  methodology: {
    methodologyIdName: string;
  };
  monitoringWeeks?: string;
  qaqcProcedure?: string;
  otherComments?: string;
  version: string;
  region?: string;
  relatedDocumentFile?: string | null;
  valueList?: any[];
  createdAt?: string;
  updatedAt?: string;
  fomula?: string;
}

export function getStandardString(type: any): string {
  switch (type) {
    case "FIXED":
      return "고정";
    case "CHANGE":
      return "변동";
    default:
      return "-";
  }
}

export function getTypeString(type: any): string {
  switch (type) {
    case "NET_CALORIC_VALUE":
      return "순발열량";
    case "FUEL_EFFICIENCY":
      return "연비";
    case "EMISSION_FACTOR":
      return "배출계수";
    default:
      return "-";
  }
}
