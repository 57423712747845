import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
  Divider,
  Radio,
  useTheme,
  Theme,
  Button,
  IconButton,
  Link,
} from "@mui/material";
import TextEditor from "../../../component/TextEditor";
import { useEffect, useState } from "react";
import LinkOutlinedIcon from "@mui/icons-material/LinkOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const attachFile = [
  "파일명-medium.jpg",
  "파일명-long-long.png.jpg",
  "파일명.jpg",
];

const AnnounceDetailsGrid = () => {
  const [data, setData] = useState(`
    <p>안녕하세요. <br />
    오는 5월4일(월)부터 팝플투어 운영 및 문의 업무시간이 아래와 같이 변경되오니 이용에 참고해 주시기 바랍니다.<br /><br />
    시행일자 : 2020년 5월 4일(월)부터 시행</p><br />
    <img src="https://via.placeholder.com/150" alt="Example Image" />
  `);
  useEffect(() => {
    console.log("data is : ", data);
  }, [data]);

  const [selectedValue, setSelectedValue] = useState("a");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(event.target.value);
  };
  return (
    <Grid container rowSpacing={4.5} columnSpacing={2.75} sx={{ mt: -5 }}>
      {/* 첫번째 카드 */}
      <Grid item lg={8}>
        <Card
          sx={{
            borderRadius: 3,

            width: "100%",
            display: "flex",
          }}
        >
          <CardContent sx={{ width: "100%" }}>
            <Box
              sx={{
                m: 2,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box sx={{ mt: 0 }}>
                <Typography fontWeight={"bold"}>Title</Typography>
                <TextField
                  sx={{ width: "100%", mt: 1 }}
                  placeholder="제목 입력"
                  InputProps={{
                    style: {
                      borderRadius: "12px",
                    },
                  }}
                />
              </Box>
              <Divider sx={{ mt: 3 }} />
              <Box sx={{ mt: 3 }}>
                <TextEditor setData={setData} minHeight={450} data={data} />
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      {/* 두번째 카드 */}
      <Grid item lg={4}>
        <Card
          sx={{
            borderRadius: 3,

            width: "100%",
            display: "flex",
          }}
        >
          <CardContent sx={{ width: "100%" }}>
            <CardHeader
              title="Information"
              titleTypographyProps={{ fontWeight: "bold", fontSize: 19 }}
            />
            <Box
              sx={{
                m: 2,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box sx={{}}>
                <Typography fontWeight={"bold"} color={"#99989D"}>
                  Show/Hide
                </Typography>
                <Box sx={{ display: "flex" }}>
                  <Box>
                    <Radio
                      checked={selectedValue === "a"}
                      onChange={handleChange}
                      value="a"
                      name="radio-buttons"
                      inputProps={{ "aria-label": "A" }}
                    />
                    Show
                  </Box>
                  <Box sx={{ ml: 5 }}>
                    <Radio
                      checked={selectedValue === "b"}
                      onChange={handleChange}
                      value="b"
                      name="radio-buttons"
                      inputProps={{ "aria-label": "B" }}
                    />
                    Hide
                  </Box>
                </Box>
              </Box>
              <Box sx={{ mt: 3 }}>
                <Typography fontWeight={"bold"} color={"#99989D"}>
                  Open Date
                </Typography>
                <TextField
                  sx={{ width: "100%", mt: 1 }}
                  value={"05 May 2024"}
                  InputProps={{
                    style: {
                      borderRadius: "12px",
                      backgroundColor: "#E9E9EA",
                    },
                  }}
                  disabled
                />
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Select
                    value={12}
                    sx={{
                      width: "48.5%",
                      mt: 1,
                      borderRadius: "12px",
                      backgroundColor: "#E9E9EA",
                    }}
                    MenuProps={MenuProps}
                    disabled
                  >
                    {Array.from({ length: 24 }, (_, i) => (
                      <MenuItem key={i + 1} value={i + 1}>
                        {i + 1}
                      </MenuItem>
                    ))}
                  </Select>
                  <Select
                    value={30}
                    sx={{
                      width: "48.5%",
                      mt: 1,
                      borderRadius: "12px",
                      backgroundColor: "#E9E9EA",
                    }}
                    MenuProps={MenuProps}
                    disabled
                  >
                    {Array.from({ length: 12 }, (_, i) => (i + 1) * 5).map(
                      (value) => (
                        <MenuItem key={value} value={value}>
                          {value}
                        </MenuItem>
                      )
                    )}
                  </Select>
                </Box>
              </Box>
              <Box sx={{ mt: 3 }}>
                <Typography fontWeight={"bold"} color={"#99989D"}>
                  Writer
                </Typography>
                <TextField
                  name="description"
                  sx={{ width: "100%", mt: 1 }}
                  value={"Very Kim"}
                  // onChange={handleChange}
                  InputProps={{
                    style: {
                      borderRadius: "12px",
                      backgroundColor: "#E9E9EA",
                    },
                  }}
                  disabled
                />
              </Box>
              <Box sx={{ mt: 3, display: "flex", alignItems: "center" }}>
                <Typography fontWeight={"bold"} color={"#99989D"}>
                  File
                </Typography>
                <Button
                  sx={{
                    textTransform: "none",
                    height: 25,
                    //   backgroundColor: "#5D38E5",
                    color: "#5D38E5",
                    borderRadius: 5,
                    border: 1,
                    ml: 2,
                    borderColor: "#5D38E5",
                    width: 95,
                    ":hover": {
                      backgroundColor: "#E9E9E9",
                    },
                  }}
                >
                  Add File
                </Button>
              </Box>
              <Typography sx={{ fontSize: 12, color: "#99989D", mt: 2 }}>
                15MB이하의 파일(jpg,png,mp4) 3개까지 첨부 가능
              </Typography>
              <Box
                sx={{
                  mt: 2,
                  border: 1,
                  borderRadius: 3,
                  borderColor: "#99989D",
                }}
              >
                <Box sx={{ m: 1, ml: 1.5 }}>
                  {attachFile.map((value) => (
                    <Box sx={{ display: "flex", alignItems: "center", m: 0.5 }}>
                      <LinkOutlinedIcon
                        fontSize="small"
                        sx={{
                          color: "#676665",
                          transform: "rotate(135deg)",
                          width: 17,
                        }}
                      />
                      &nbsp;
                      <Link
                        href="#"
                        underline="always"
                        color="inherit"
                        sx={{ fontSize: 12 }}
                      >
                        {value}
                      </Link>
                      <IconButton sx={{ width: 0, height: 0 }}>
                        <CancelOutlinedIcon
                          fontSize="small"
                          sx={{
                            color: "#99989D",
                            width: 14,
                            mt: 0.2,
                            ml: 0.6,
                          }}
                        />
                      </IconButton>
                    </Box>
                  ))}
                </Box>
              </Box>
              <Divider sx={{ mt: 3, mb: 3 }} />
              <Box>
                <Box
                  sx={{
                    display: "flex",

                    color: "#99989D",
                  }}
                >
                  <Typography fontSize={14}>등록 일시</Typography>
                  <Typography sx={{ ml: 3 }} fontSize={14}>
                    2022.01.01 14:24
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",

                    color: "#99989D",
                    mt: 1,
                  }}
                >
                  <Typography fontSize={14}>답변 일시</Typography>
                  <Typography sx={{ ml: 3 }} fontSize={14}>
                    2022.01.04 14:24
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",

                    color: "#99989D",
                    mt: 1,
                  }}
                >
                  <Typography fontSize={14}>삭제 일시</Typography>
                  <Typography sx={{ ml: 3 }} fontSize={14}>
                    2022.01.05 14:24
                  </Typography>
                </Box>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default AnnounceDetailsGrid;
