import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  IconButton,
  Typography,
} from "@mui/material";
import CommonList from "../../component/CommonList";
import { useNavigate } from "react-router-dom";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { useEffect, useState } from "react";
import AnnounceDetailsGrid from "./section/AnnounceDetailsGrid";
import CommonDialog from "../../component/CommonDialog";

const AnnouncementDetailsPage = () => {
  const navigate = useNavigate();

  const HeaderButton = () => {
    return (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Button
          sx={{
            height: 40,
            backgroundColor: "#5D38E5",
            color: "white",
            borderRadius: 5,
            width: 110,
            ":hover": {
              backgroundColor: "#A38BFC",
            },
          }}
          onClick={() => {}}
        >
          업데이트
        </Button>
        &nbsp; &nbsp;
        <Typography fontSize={30} sx={{ mb: 0.5, color: "gray" }}>
          |
        </Typography>
        <IconButton
          sx={{ color: "red" }}
          onClick={() => {
            handleDeleteOpen();
          }}
        >
          <DeleteOutlineOutlinedIcon fontSize="medium" />
        </IconButton>
      </Box>
    );
  };
  const HeaderBackButton = () => {
    return (
      <IconButton
        sx={{
          ":hover": { backgroundColor: "#F2F2F2" },
          mb: 0.5,
          mr: 1,
        }}
        onClick={() => {
          handleBackOpen();
        }}
      >
        <ArrowBackIosNewIcon fontSize="medium" />
      </IconButton>
    );
  };

  const [open, setOpen] = useState(false);

  const handleBackOpen = () => {
    setOpen(true);
  };
  const handleBackClose = () => {
    setOpen(false);
  };
  const handleBackOk = () => {
    navigate("/announcement");
    window.location.reload();
  };

  const [deleteOpen, setDeleteOpen] = useState(false);
  const handleDeleteOpen = () => {
    setDeleteOpen(true);
  };
  const handleDeleteClose = () => {
    setDeleteOpen(false);
  };

  const handleDeleteOk = () => {
    console.log("asdasdadasdsadasdsa");
    // setDeleteOpen(false);
  };

  return (
    <CommonList
      title="공지사항 상세"
      headerButton={HeaderButton()}
      headerBackButton={HeaderBackButton()}
    >
      <CommonDialog
        open={open}
        handleClose={handleBackClose}
        handleOk={handleBackOk}
      >
        작성하던 것을 중단하고 이동하시겠습니까? <br />
        해당 사항은 저장되지 않습니다.
      </CommonDialog>
      <CommonDialog
        open={deleteOpen}
        handleClose={handleDeleteClose}
        handleOk={handleDeleteOk}
      >
        <Typography>
          공지 사항을{" "}
          <Typography component="span" sx={{ color: "red" }}>
            삭제
          </Typography>
          하시겠습니까? <br />
          이후 다시 복구할 수 없습니다.
        </Typography>
      </CommonDialog>
      <AnnounceDetailsGrid />
    </CommonList>
  );
};

export default AnnouncementDetailsPage;
