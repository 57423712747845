import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import DnsOutlinedIcon from "@mui/icons-material/DnsOutlined";
import FiberManualRecordOutlinedIcon from "@mui/icons-material/FiberManualRecordOutlined";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";
import MiscellaneousServicesOutlinedIcon from "@mui/icons-material/MiscellaneousServicesOutlined";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";

export interface MenuItem {
  title: string;
  type: string;
  url: string;
  Icon?: JSX.Element;
  Children?: MenuItem[];
}

const ItemIcon = () => {
  return (
    <FiberManualRecordOutlinedIcon
      sx={{
        width: 12,
        height: 12,
        ml: 1.5,
      }}
    />
  );
};

export const MenuList: { [key: string]: MenuItem } = {
  Home: {
    title: "",
    type: "group",
    url: "",
    Children: [
      {
        title: "운영",
        type: "group",
        url: "",
        Children: [
          {
            title: "통계",
            type: "item",
            url: "/dashboard",
            Icon: <DashboardOutlinedIcon />,
          },
          {
            title: "사용자 관리",
            type: "collapse",
            url: "",
            Icon: <PeopleOutlineIcon />,
            Children: [
              {
                title: "회원 관리",
                type: "item",
                url: "/usrMng",
                Icon: ItemIcon(),
              },
              {
                title: "관리자 관리",
                type: "item",
                url: "/adminMng",
                Icon: ItemIcon(),
              },
              {
                title: "그룹 관리",
                type: "item",
                url: "/grpMng",
                Icon: ItemIcon(),
              },
            ],
          },
          {
            title: "컨텐츠 관리",
            type: "collapse",
            url: "",
            Icon: <ArticleOutlinedIcon />,
            Children: [
              {
                title: "FAQ 관리",
                type: "item",
                url: "/FAQMng",
                Icon: ItemIcon(),
              },
              {
                title: "1:1문의 관리",
                type: "item",
                url: "/inquiryMng",
                Icon: ItemIcon(),
              },
              {
                title: "CS 관리",
                type: "item",
                url: "/csMng",
                Icon: ItemIcon(),
              },
              {
                title: "공지사항 관리",
                type: "item",
                url: "/announcement",
                Icon: ItemIcon(),
              },
              {
                title: "푸시 알람 관리",
                type: "item",
                url: "/pushMng",
                Icon: ItemIcon(),
              },
              {
                title: "팝업 관리",
                type: "item",
                url: "/popupMng",
                Icon: ItemIcon(),
              },
            ],
          },
          {
            title: "운영 품목 관리",
            type: "collapse",
            url: "",
            Icon: <MiscellaneousServicesOutlinedIcon />,
            Children: [
              {
                title: "스쿠터 운영 관리",
                type: "item",
                url: "/vehicleMng",
                Icon: ItemIcon(),
              },
              {
                title: "배터리 운영 관리",
                type: "item",
                url: "/batteryMng",
                Icon: ItemIcon(),
              },
              {
                title: "스테이션 운영 관리",
                type: "item",
                url: "/stationMng",
                Icon: ItemIcon(),
              },
              {
                title: "NFC 카드 관리",
                type: "item",
                url: "/NFCMng",
                Icon: ItemIcon(),
              },
            ],
          },

          {
            title: "시스템 관리",
            type: "collapse",
            url: "",
            Icon: <DnsOutlinedIcon />,
            Children: [
              {
                title: "알림 설정",
                type: "item",
                url: "/notiMng",
                Icon: ItemIcon(),
              },
              {
                title: "다국어 설정",
                type: "item",
                url: "/multilingual",
                Icon: ItemIcon(),
              },
              {
                title: "코드 설정",
                type: "item",
                url: "/commonCode",
                Icon: ItemIcon(),
              },
              {
                title: "이력 관리",
                type: "item",
                url: "/historyMng",
                Icon: ItemIcon(),
              },
              {
                title: "연계/배치 관리",
                type: "item",
                url: "/linkBatchMng",
                Icon: ItemIcon(),
              },
              {
                title: "프레임워크 인증",
                type: "item",
                url: "/authFramework",
                Icon: ItemIcon(),
              },
            ],
          },
          {
            title: "탄소감축량 관리",
            type: "collapse",
            url: "",
            Icon: <DnsOutlinedIcon />,
            Children: [
              {
                title: "방법론 관리",
                type: "item",
                url: "/methodologyMng",
                Icon: ItemIcon(),
              },
              {
                title: "프로젝트 관리",
                type: "item",
                url: "/projectMng",
                Icon: ItemIcon(),
              },
              {
                title: "데이터/인자 관리",
                type: "item",
                url: "/paramDataMng",
                Icon: ItemIcon(),
              },
              {
                title: "모니터링 관리",
                type: "item",
                url: "/monitoringMng",
                Icon: ItemIcon(),
              },
            ],
          },
        ],
      },

      {
        title: "제조",
        type: "group",
        url: "",
        Children: [
          {
            title: "제조품목 관리",
            type: "collapse",
            url: "",
            Icon: <Inventory2OutlinedIcon />,
            Children: [
              {
                title: "소모품",
                type: "item",
                url: "/manufExpendMng",
                Icon: ItemIcon(),
              },
              {
                title: "device",
                type: "item",
                url: "/manufDeviceMng",
                Icon: ItemIcon(),
              },
              {
                title: "스쿠터",
                type: "item",
                url: "/manufScooter",
                Icon: ItemIcon(),
              },
              {
                title: "배터리",
                type: "item",
                url: "/manufBatteryMng",
                Icon: ItemIcon(),
              },
              {
                title: "스테이션",
                type: "item",
                url: "/manufStation",
                Icon: ItemIcon(),
              },
              {
                title: "NFC 카드",
                type: "item",
                url: "/manufNFC",
                Icon: ItemIcon(),
              },
            ],
          },
        ],
      },
      // {
      //   title: "MQTT",
      //   type: "item",
      //   url: "/mqtt",
      //   Icon: <DashboardOutlinedIcon />,
      // },
    ],
  },
};
