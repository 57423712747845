import { MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { useEffect, useState } from "react";
import CommonList from "../../component/CommonList";
import CommonSearch from "../../component/CommonSearch";
import UserAddModal from "./section/UserAddModal";
import UserTable from "./section/UserTable";

const UsrMngListPage = () => {
  const [modal, setModal] = useState(false);

  const [usrType, setUsrType] = useState("10");
  const [usrStatus, setUsrStatus] = useState("10");
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleUsrTypeChange = (event: SelectChangeEvent) => {
    setUsrType(event.target.value as string);
  };

  const handleUsrStatus = (event: SelectChangeEvent) => {
    setUsrStatus(event.target.value as string);
  };

  useEffect(() => {
    console.log(modal);
  }, [modal]);

  return (
    <CommonList title="회원 관리" setModal={setModal}>
      <CommonSearch setRowsPerPage={setRowsPerPage}>
        <Select
          id="paging"
          value={usrType}
          onChange={handleUsrTypeChange}
          size="small"
          sx={{ width: "15%" }}
          style={{ borderRadius: "10px" }}
        >
          <MenuItem value={10}>:: 선택 ::</MenuItem>
          <MenuItem value={20}>일반 사용자</MenuItem>
          <MenuItem value={30}>투어 사용자</MenuItem>
          <MenuItem value={40}>관리자</MenuItem>
        </Select>
        <Select
          id="paging"
          value={usrStatus}
          onChange={handleUsrStatus}
          size="small"
          sx={{ width: "15%" }}
          style={{ borderRadius: "10px" }}
        >
          <MenuItem value={10}>:: 선택 ::</MenuItem>
          <MenuItem value={20}>활동</MenuItem>
          <MenuItem value={30}>승인대기</MenuItem>
          <MenuItem value={40}>탈퇴</MenuItem>
          <MenuItem value={50}>사용중지</MenuItem>
        </Select>
      </CommonSearch>
      <UserTable rowsPerPage={rowsPerPage} />
      <UserAddModal modal={modal} setModal={setModal} />
    </CommonList>
  );
};

export default UsrMngListPage;
