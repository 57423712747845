import AirlineStopsOutlinedIcon from "@mui/icons-material/AirlineStopsOutlined";
import ManageHistoryOutlinedIcon from "@mui/icons-material/ManageHistoryOutlined";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import DnsOutlinedIcon from "@mui/icons-material/DnsOutlined";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import NavigationOutlinedIcon from "@mui/icons-material/NavigationOutlined";
import FiberManualRecordOutlinedIcon from "@mui/icons-material/FiberManualRecordOutlined";

export interface AsMenuItem {
  title: string;
  type: string;
  url: string;
  Icon?: JSX.Element;
  Children?: AsMenuItem[];
}

const ItemIcon = () => {
  return (
    <FiberManualRecordOutlinedIcon
      sx={{
        width: 12,
        height: 12,
        ml: 1.5,
      }}
    />
  );
};

export const AsMenuList: { [key: string]: AsMenuItem } = {
  Home: {
    title: "HOME",
    type: "group",
    url: "",
    Children: [
      {
        title: "DashBoard",
        type: "item",
        url: "/dashboard",
        Icon: <DashboardOutlinedIcon />,
      },
      {
        title: "사용자 관리",
        type: "collapse",
        url: "",
        Icon: <PeopleOutlineIcon />,
        Children: [
          {
            title: "관리자 관리",
            type: "item",
            url: "/adminMng",
            Icon: ItemIcon(),
          },
          {
            title: "회원 관리",
            type: "item",
            url: "/usrMng",
            Icon: ItemIcon(),
          },
          {
            title: "접속아이피 관리",
            type: "item",
            url: "/connectionIp",
            Icon: ItemIcon(),
          },
        ],
      },
      {
        title: "서비스 운영 관리",
        type: "collapse",
        url: "",
        Icon: <NavigationOutlinedIcon />,
        Children: [
          {
            title: "서비스 관리",
            type: "item",
            url: "/svcMng",
            Icon: ItemIcon(),
          },
          {
            title: "배터리 관리",
            type: "item",
            url: "/batteryMng",
            Icon: ItemIcon(),
          },
          {
            title: "차량 모델 관리",
            type: "item",
            url: "/vehicleModelMng",
            Icon: ItemIcon(),
          },
          {
            title: "차량 관리",
            type: "item",
            url: "/vehicleMng",
            Icon: ItemIcon(),
          },
        ],
      },
      {
        title: "연계/배치 관리",
        type: "collapse",
        url: "",
        Icon: <AirlineStopsOutlinedIcon />,
        Children: [
          {
            title: "스테이션 연계",
            type: "item",
            url: "/stationLink",
            Icon: ItemIcon(),
          },
          {
            title: "IOT 연계",
            type: "item",
            url: "/iotLink",
            Icon: ItemIcon(),
          },
          {
            title: "배치관리",
            type: "item",
            url: "/batchMng",
            Icon: ItemIcon(),
          },
        ],
      },
      {
        title: "MQTT",
        type: "item",
        url: "/mqtt",
        Icon: <DashboardOutlinedIcon />,
      },
    ],
  },
};
