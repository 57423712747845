import { useEffect } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import FAQCreatePage from "../pages/FAQ/FAQCreatePage";
import FAQMngListPage from "../pages/FAQ/FAQMngListPage";
import InquiryDetailsPage from "../pages/Inquiry/InquiryDetailsPage";
import InquiryPage from "../pages/Inquiry/InquiryPage";
import AdminDetailPage from "../pages/adminMngList/AdminDetailPage";
import AdminMngListPage from "../pages/adminMngList/AdminMngListPage";
import CreateAdminPage from "../pages/adminMngList/section/CreateAdminPage";
import AnnouncementCreatePage from "../pages/announcement/AnnouncementCreatePage";
import AnnouncementDetailsPage from "../pages/announcement/AnnouncementDetailsPage";
import AnnouncementPage from "../pages/announcement/AnnouncementPage";
import CreateMethodologyPage from "../pages/carbonReductionMng/methodology/CreateMethodologyPage";
import MethodologyMngListPage from "../pages/carbonReductionMng/methodology/MethodologyMngListPage";
import CreateMonitoringPage from "../pages/carbonReductionMng/monitoring/CreateMonitoringPage";
import MonitoringMngListPage from "../pages/carbonReductionMng/monitoring/MonitoringMngListPage";
import ParamDataMngListPage from "../pages/carbonReductionMng/paramData/ParamDataMngListPage";
import { default as CreateProjectPage } from "../pages/carbonReductionMng/project/CreateProjectPage";
import ProjectMngListPage from "../pages/carbonReductionMng/project/ProjectMngListPage";
import CsDetailsPage from "../pages/csMng/CsDetailsPage";
import CsMngPage from "../pages/csMng/CsMngPage";
import GrpCreatePage from "../pages/grpMngList/GrpCreatePage";
import GrpDetailsPage from "../pages/grpMngList/GrpDetailsPage";
import GrpMngListPage from "../pages/grpMngList/GrpMngListPage";
import Multilingual from "../pages/multilingual/Multilingual";
import OperateDashBoardPage from "../pages/operate/OperateDashBoardPage";
import UserDetails from "../pages/usrMngList/UsrDetails";
import UsrMngListPage from "../pages/usrMngList/UsrMngListPage";

const MainRoutes = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // 새로고침 시 현재 경로를 저장합니다.
    const currentPath = location.pathname;
    if (currentPath == "/login") {
      console.log("11111");
      sessionStorage.setItem("lastPath", "/manufacture");
    } else {
      sessionStorage.setItem("lastPath", currentPath);
    }
  }, [location.pathname]); // 경로가 변경될 때마다 실행됩니다.

  useEffect(() => {
    // 컴포넌트가 마운트될 때, 새로고침 시 마지막 경로로 이동합니다.
    const lastPath = sessionStorage.getItem("lastPath");
    if (lastPath) {
      navigate(lastPath);
    }
  }, []);

  return (
    <Routes>
      <Route path="/dashboard" element={<OperateDashBoardPage />} />

      {/* 회원관리 라우트 */}
      <Route path="/usrMng" element={<UsrMngListPage />} />
      <Route path="/usrMng/usrDetails/:userId" element={<UserDetails />} />

      {/* 관리자관리 라우트 */}
      <Route path="/adminMng" element={<AdminMngListPage />} />
      <Route
        path="/adminMng/adminDetails/:userId"
        element={<AdminDetailPage />}
      />
      <Route path="/adminMng/createAdmin" element={<CreateAdminPage />} />

      {/* 그룹관리 라우트 */}
      <Route path="/grpMng" element={<GrpMngListPage />} />
      <Route path="/grpMng/grpCreate" element={<GrpCreatePage />} />
      <Route path="/grpMng/grpDetails/:grpId" element={<GrpDetailsPage />} />

      {/* FAG관리 라우트 */}
      <Route path="/FAQMng" element={<FAQMngListPage />} />
      <Route path="/FAQMng/FAQCreate" element={<FAQCreatePage />} />

      {/* 1:1문의 관리 라우트 */}
      <Route path="/inquiryMng" element={<InquiryPage />} />
      <Route
        path="/inquiryMng/inquiryDetails/:inquiryId"
        element={<InquiryDetailsPage />}
      />

      {/* AS 관리 라우트 */}
      <Route path="/csMng" element={<CsMngPage />} />
      <Route path="/csMng/csDetails/:csId" element={<CsDetailsPage />} />

      {/* 공지사항 관리 라우트 */}
      <Route path="/announcement" element={<AnnouncementPage />} />
      <Route
        path="/announcement/createAnnounce"
        element={<AnnouncementCreatePage />}
      />
      <Route
        path="/announcement/announceDetails/:announceId"
        element={<AnnouncementDetailsPage />}
      />

      <Route path="/pushMng" element={<></>} />
      <Route path="/popupMng" element={<></>} />

      <Route path="/vehicleMng" element={<></>} />
      <Route path="/batteryMng" element={<></>} />
      <Route path="/stationMng" element={<></>} />
      <Route path="/NFCMng" element={<></>} />

      <Route path="/notiMng" element={<></>} />
      <Route path="/multilingual" element={<Multilingual />} />
      <Route path="/commonCode" element={<></>} />
      <Route path="/historyMng" element={<></>} />
      <Route path="/linkBatchMng" element={<></>} />
      <Route path="/authFramework" element={<></>} />

      {/* 탄소감축량 관리 라우트 */}
      {/* 방법론 관리 라우트 */}
      <Route path="/methodologyMng" element={<MethodologyMngListPage />} />
      <Route
        path="/methodologyMng/createMethodology"
        element={<CreateMethodologyPage />}
      />
      <Route
        path="/methodologyMng/updateMethodology/:id"
        element={<CreateMethodologyPage />}
      />

      {/* 프로젝트 관리 라우트 */}
      <Route path="/projectMng" element={<ProjectMngListPage />} />
      <Route
        path="/projectMng/projectDetails"
        element={<CreateProjectPage />}
      />
      <Route
        path="/projectMng/projectDetails/:id"
        element={<CreateProjectPage />}
      />

      {/* 고정 데이터/인자 관리 라우트 */}
      <Route path="/paramDataMng" element={<ParamDataMngListPage />} />
      {/* <Route path="/paramDataMng/param-data" element={<ParamDetailsPage />} /> */}

      {/* 모니터링 관리 라우트 */}
      <Route path="/monitoringMng" element={<MonitoringMngListPage />} />
      <Route
        path="/monitoringMng/monitoringDetails"
        element={<CreateMonitoringPage />}
      />
      <Route
        path="/monitoringMng/monitoringDetails/:id"
        element={<CreateMonitoringPage />}
      />
    </Routes>
  );
};

export default MainRoutes;
