import AddIcon from "@mui/icons-material/Add";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import {
  Button,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography
} from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CommonList from "../../../component/CommonList";
import CommonSearch from "../../../component/CommonSearch";
import { Monitoring } from "../../../store/monitoringType";
import { Project } from "../../../store/projectType";
import MonitoringTable from "../section/MonitoringTable";

const MonitoringMngListPage = () => {
  const navigate = useNavigate();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [menuItems, setMenuItems] = useState<Project[]>([]);
  const [selectedProjectId, setSelectedProjectId] = useState<number | string>(
    "none"
  );
  const [page, setPage] = useState<number>(0);
  const [data, setData] = useState<Monitoring[]>([]);
  const [totalRows, setTotalRows] = useState<number>(0); // Total rows for pagination
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const API_URL = "https://api-kisa.greenery.im/monitoring";
  const API_URL_Project = "https://api-kisa.greenery.im/project";

  const fetchData = async (projectId?: number | string) => {
    setLoading(true);
    try {
      const url = projectId
        ? `${API_URL}?projectId=${projectId}&page=${page}&size=${rowsPerPage}`
        : `${API_URL}?page=${page}&size=${rowsPerPage}`;
      const response = await axios.get(url);
      setData(response.data.content);
      setTotalRows(response.data.totalElements);
    } catch (error) {
      console.error("Error fetching monitoring data:", error);
      setError("Error fetching monitoring data.");
    } finally {
      setLoading(false);
    }
  };

  const fetchMenuItems = async () => {
    try {
      const response = await axios.get(API_URL_Project);

      // Extract the array from the 'content' field
      const data: Project[] = Array.isArray(response.data.content)
        ? response.data.content
        : [];

      setMenuItems(data);
      console.log("Processed Data: ", data);
    } catch (error) {
      console.error("Error fetching project data:", error);
      setError("Error fetching project data.");
    }
  };

  useEffect(() => {
    fetchData(selectedProjectId !== "none" ? selectedProjectId : undefined);
    fetchMenuItems();
  }, [page, rowsPerPage, selectedProjectId]);

  const handleProjectChange = (event: SelectChangeEvent<number | string>) => {
    const selectedId = event.target.value as number | string;
    setSelectedProjectId(selectedId);
    setPage(0); // Reset to first page on project change
  };

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    newPage: number
  ) => {
    setPage(newPage - 1);
  };

  const handleRowsPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const headerButton = () => (
    <Button
      sx={{
        width: "113px",
        height: "45px",
        borderRadius: "40px",
        padding: "20px, 0px, 20px, 0",
        background: "#5D38E5",
        color: "#FFFFFF",
        textTransform: "none",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textWeight: "600",
        textSize: "14px",
        lineHeight: "20px",
        "&:hover": {
          background: " #5a3acb"
        }
      }}
      onClick={() => navigate("/monitoringMng/monitoringDetails")}
    >
      <AddIcon fontSize="small" />
      Create
    </Button>
  );

  if (loading) return <Typography>Loading...</Typography>;
  if (error) return <Typography color="error">{error}</Typography>;

  return (
    <CommonList title="모니터링 보고서" headerButton={headerButton()}>
      <CommonSearch
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        isSearch={false}
      >
        <Select
          value={selectedProjectId}
          onChange={handleProjectChange}
          size="small"
          IconComponent={(props) => (
            <KeyboardArrowDown {...props} sx={{ fontSize: "16px" }} />
          )}
          sx={{
            width: "82.5%",
            minHeight: "42px",
            opacity: 1,
            borderRadius: "10px",
            backgroundColor: "#ffff",
            "&:hover": {
              borderColor: "#F9F6FD"
            }
          }}
          disabled={menuItems.length === 0}
        >
          <MenuItem value="none">프로젝트 선택</MenuItem>
          {menuItems.length > 0
            ? menuItems.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.title}
                </MenuItem>
              ))
            : null}
        </Select>
      </CommonSearch>
      <MonitoringTable
        rowsPerPage={rowsPerPage}
        rows={data}
        page={page}
        totalRows={totalRows}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleRowsPerPageChange}
      />
    </CommonList>
  );
};

export default MonitoringMngListPage;
