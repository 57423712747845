import React, { useState } from "react";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../themes/theme";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import {
  Box,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Button,
  Typography,
  FormHelperText
} from "@mui/material";
import { MethodologyType } from "./enum";
import { SelectChangeEvent } from "@mui/material/Select";

interface FormData {
  version: string;
  standard: MethodologyType;
  title: string;
  methodologyIdName: string;
  startDate: string;
  createdAt: number;
  area: MethodologyType;
  subArea: MethodologyType;
  expiredYear: string;
  id: number;
}

interface CreateMethodologyGridProps {
  formData: FormData;
  setFormData: React.Dispatch<React.SetStateAction<FormData>>;
  errors: { [key: string]: string };
  handleSelect: (
    field: "standard" | "area" | "subArea",
    value: MethodologyType
  ) => void;
}

const CreateMethodologyGrid: React.FC<CreateMethodologyGridProps> = ({
  formData,
  setFormData,
  errors
}) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const handleTextFieldChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }));
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setSelectedFile(file);
    }
  };

  const handleUploadClick = () => {
    const fileInput = document.getElementById("fileInput") as HTMLInputElement;
    if (fileInput) {
      fileInput.click();
    }
  };

  const handleSelectChange = (
    event: SelectChangeEvent<MethodologyType>,
    name: string
  ) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: event.target.value as MethodologyType
    }));
  };
  const textFieldStyles = {
    // width: "480px",
    width: "480px",
    height: "48px",
    minHeight: "48px",
    gap: "10px",
    borderRadius: "10px 0px 0px 0px",
    opacity: 1,
    marginLeft: "6px"
  };
  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: { xs: "1fr", md: "1fr 1fr" },
        gap: 2,
        width: "100%",
        boxSizing: "border-box"
      }}
    >
      <Box
        sx={{
          bgcolor: "background.paper",
          borderRadius: 3,
          p: 4,
          display: "flex",
          flexDirection: "column",
          gap: 1
        }}
      >
        <Typography
          variant="h6"
          gutterBottom
          sx={{
            fontWeight: "bold",
            gap: 5
            // p: 1.5
          }}
        >
          기본 정보
        </Typography>

        <InputLabel
          htmlFor="version"
          sx={{
            fontWeight: "bold",
            fontSize: "12px",
            p: 1
          }}
        >
          버전
        </InputLabel>
        <TextField
          sx={textFieldStyles}
          fullWidth
          name="version"
          value={formData.version}
          onChange={handleTextFieldChange}
          InputProps={{
            sx: {
              borderRadius: 2,
              height: "45px"
            }
          }}
          error={!!errors.version}
          helperText={errors.version}
        />
        <InputLabel
          htmlFor="title"
          sx={{
            fontWeight: "bold",
            fontSize: "12px",
            p: 1
          }}
        >
          방법론명
        </InputLabel>
        <TextField
          fullWidth
          sx={textFieldStyles}
          name="title"
          value={formData.title}
          onChange={handleTextFieldChange}
          InputProps={{
            sx: {
              borderRadius: 2,
              height: "45px"
            }
          }}
          error={!!errors.title}
          helperText={errors.title}
        />
        <InputLabel
          htmlFor="methodologyIdName"
          sx={{
            fontWeight: "bold",
            fontSize: "12px",
            p: 1
          }}
        >
          방법론 ID
        </InputLabel>
        <TextField
          sx={textFieldStyles}
          fullWidth
          name="methodologyIdName"
          value={formData.methodologyIdName}
          onChange={handleTextFieldChange}
          InputProps={{
            sx: {
              borderRadius: 2,
              height: "45px",
              width: "480px"
            }
          }}
          error={!!errors.methodologyIdName}
          helperText={errors.methodologyIdName}
        />

        <InputLabel
          htmlFor="standard"
          sx={{
            fontWeight: "bold",
            fontSize: "12px",
            p: 1
          }}
        >
          방법론 기준
        </InputLabel>
        <FormControl fullWidth error={!!errors.standard}>
          <Select
            fullWidth
            name="standard"
            value={formData.standard}
            onChange={(event) => handleSelectChange(event, "standard")}
            IconComponent={(props) => (
              <KeyboardArrowDown {...props} sx={{ fontSize: "16px" }} />
            )}
            sx={{
              borderRadius: 2,
              marginLeft: 1,
              height: "45px",
              width: "480px",
              fontSize: "12px",
              p: 1
            }}
          >
            <MenuItem
              sx={{
                fontSize: "12px",
                fontWeight: "400",
                lineHeight: "18px"
              }}
              value={MethodologyType.DOMESTIC}
            >
              국내 방법론
            </MenuItem>
            <MenuItem
              sx={{
                fontSize: "12px",
                fontWeight: "400",
                lineHeight: "18px"
              }}
              value={MethodologyType.INTERNATIONAL}
            >
              국외 방법론
            </MenuItem>
            <MenuItem
              sx={{
                fontSize: "12px",
                fontWeight: "400",
                lineHeight: "18px"
              }}
              value={MethodologyType.CDM}
            >
              CDM
            </MenuItem>
            <MenuItem
              sx={{
                fontSize: "12px",
                fontWeight: "400",
                lineHeight: "18px"
              }}
              value={MethodologyType.ALL}
            >
              전체
            </MenuItem>
          </Select>
          <FormHelperText>{errors.standard}</FormHelperText>
        </FormControl>

        <InputLabel
          htmlFor="area"
          sx={{
            fontWeight: "bold",
            fontSize: "12px",
            p: 1
          }}
        >
          분야
        </InputLabel>
        <FormControl fullWidth error={!!errors.area}>
          <Select
            fullWidth
            name="area"
            value={formData.area}
            IconComponent={(props) => (
              <KeyboardArrowDown {...props} sx={{ fontSize: "16px" }} />
            )}
            onChange={(event) => handleSelectChange(event, "area")}
            sx={{
              borderRadius: 2,
              height: "45px",
              width: "480px",
              fontSize: "12px",
              fontWeight: "400",
              lineHeight: "18px",
              marginLeft: "7px"
            }}
          >
            <MenuItem
              sx={{
                fontSize: "12px",
                fontWeight: "400",
                lineHeight: "18px"
              }}
              value={MethodologyType.REMOVAL}
            >
              감축
            </MenuItem>

            <MenuItem
              sx={{
                fontSize: "12px",
                fontWeight: "400",
                lineHeight: "18px"
              }}
              value={MethodologyType.REDUCTION}
            >
              제거
            </MenuItem>
          </Select>
          <FormHelperText>{errors.area}</FormHelperText>
        </FormControl>

        <InputLabel
          htmlFor="subArea"
          sx={{
            fontWeight: "bold",
            fontSize: "12px",
            p: 1
          }}
        >
          세부 분야
        </InputLabel>
        <FormControl fullWidth error={!!errors.subArea}>
          <Select
            name="subArea"
            value={formData.subArea}
            onChange={(event) => handleSelectChange(event, "subArea")}
            IconComponent={(props) => (
              <KeyboardArrowDown {...props} sx={{ fontSize: "16px" }} />
            )}
            sx={{
              borderRadius: 2,
              height: "45px",
              marginLeft: "7px",
              width: "480px"
            }}
          >
            <MenuItem
              sx={{
                fontSize: "12px",
                fontWeight: "400",
                lineHeight: "18px"
              }}
              value={MethodologyType.FORESTRY}
            >
              산림
            </MenuItem>
            <MenuItem
              sx={{
                fontSize: "12px",
                fontWeight: "400",
                lineHeight: "18px"
              }}
              value={MethodologyType.SOIL_CARBON}
            >
              토양탄소
            </MenuItem>
            <MenuItem
              sx={{
                fontSize: "12px",
                fontWeight: "400",
                lineHeight: "18px"
              }}
              value={MethodologyType.DAC}
            >
              DAC
            </MenuItem>
            <MenuItem
              sx={{
                fontSize: "12px",
                fontWeight: "400",
                lineHeight: "18px"
              }}
              value={MethodologyType.RENEWABLE_ENERGY}
            >
              재생에너지
            </MenuItem>
            <MenuItem
              sx={{
                fontSize: "12px",
                fontWeight: "400",
                lineHeight: "18px"
              }}
              value={MethodologyType.E_MOBILITY}
            >
              e-mobility
            </MenuItem>
            <MenuItem
              sx={{
                fontSize: "12px",
                fontWeight: "400",
                lineHeight: "18px"
              }}
              value={MethodologyType.RECYCLE}
            >
              재활용
            </MenuItem>
            <MenuItem
              sx={{
                fontSize: "12px",
                fontWeight: "400",
                lineHeight: "18px"
              }}
              value={MethodologyType.REPLACE_ELEMENT}
            >
              대체물질
            </MenuItem>
            <MenuItem
              sx={{
                fontSize: "12px",
                fontWeight: "400",
                lineHeight: "18px"
              }}
              value={MethodologyType.ENERGY_CONVERSION}
            >
              에너지전환
            </MenuItem>

            <MenuItem
              sx={{
                fontSize: "12px",
                fontWeight: "400",
                lineHeight: "18px"
              }}
              value={MethodologyType.CHEMICAL}
            >
              화학물질
            </MenuItem>
            <MenuItem
              sx={{
                fontSize: "12px",
                fontWeight: "400",
                lineHeight: "18px"
              }}
              value={MethodologyType.AGRICULTURE}
            >
              농업
            </MenuItem>
            <MenuItem
              sx={{
                fontSize: "12px",
                fontWeight: "400",
                lineHeight: "18px"
              }}
              value={MethodologyType.CCS}
            >
              CCS
            </MenuItem>
            <MenuItem
              sx={{
                fontSize: "12px",
                fontWeight: "400",
                lineHeight: "18px"
              }}
              value={MethodologyType.SECONDHAND}
            >
              중고거리
            </MenuItem>
            <MenuItem
              sx={{
                fontSize: "12px",
                fontWeight: "400",
                lineHeight: "18px"
              }}
              value={MethodologyType.REPLACE_VEHICLE}
            >
              이동수단 대체
            </MenuItem>
            <MenuItem
              sx={{
                fontSize: "12px",
                fontWeight: "400",
                lineHeight: "18px"
              }}
              value={MethodologyType.SERVICE}
            >
              서비스
            </MenuItem>
            <MenuItem
              sx={{
                fontSize: "12px",
                fontWeight: "400",
                lineHeight: "18px"
              }}
              value={MethodologyType.ETC}
            >
              기타
            </MenuItem>
          </Select>
          <FormHelperText>{errors.subArea}</FormHelperText>
        </FormControl>

        <InputLabel
          htmlFor="startDate"
          sx={{
            fontWeight: "bold",
            fontSize: "12px",
            p: 1
          }}
        >
          유효기간 시작일
        </InputLabel>
        <TextField
          fullWidth
          type="date"
          name="startDate"
          value={formData.startDate}
          onChange={handleTextFieldChange}
          sx={{
            "& .MuiInputBase-root": {
              borderRadius: 2,
              height: "45px"
            },
            marginLeft: "7px",
            "& .MuiInputBase-input": {
              padding: "10px"
            },
            width: "480px"
          }}
          // sx={{
          //   borderRadius: 2,
          //   height: "45px",
          //   marginLeft: "7px",
          //   width: "480px"
          // }}
          error={!!errors.startDate}
          helperText={errors.startDate}
        />
        <InputLabel
          htmlFor="expiredYear"
          sx={{
            fontWeight: "bold",
            fontSize: "12px",
            p: 1
          }}
        >
          유효 기간(년수)
        </InputLabel>
        <TextField
          fullWidth
          name="expiredYear"
          value={formData.expiredYear}
          onChange={handleTextFieldChange}
          sx={{
            "& .MuiInputBase-root": {
              borderRadius: 2,
              height: "45px"
            },
            marginLeft: "7px",
            width: "480px",
            "& .MuiInputBase-input": {
              padding: "10px"
            }
          }}
          error={!!errors.expiredYear}
          helperText={errors.expiredYear}
        />
      </Box>
      <Box
        sx={{
          bgcolor: "background.paper",
          boxShadow: 3,
          borderRadius: 3,
          p: 4,
          display: "flex",
          flexDirection: "column",
          gap: 2
        }}
      >
        <Typography
          variant="h6"
          gutterBottom
          sx={{
            fontWeight: "bold"
          }}
        >
          첨부파일
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between"
          }}
        >
          <InputLabel
            sx={{
              fontWeight: "bold",
              fontSize: "12px",
              margin: 0
            }}
          >
            방법론 문서 파일
          </InputLabel>
          <Typography
            component="span"
            onClick={handleUploadClick}
            sx={{
              fontWeight: "bold",
              fontSize: "12px",
              textDecoration: "underline",
              cursor: "pointer",
              "&:hover": {
                color: "primary.dark"
              }
            }}
          >
            Upload File
          </Typography>
        </Box>

        <input
          type="file"
          id="fileInput"
          name="uploadedFile"
          onChange={handleFileChange}
          style={{ display: "none" }}
        />
        <TextField
          fullWidth
          variant="outlined"
          name="uploadedFileName"
          value={selectedFile ? selectedFile.name : ""}
          sx={{
            "& .MuiInputBase-root": {
              borderRadius: 2,
              height: "45px"
            }
          }}
        />
        {errors.uploadedFile && (
          <FormHelperText error>{errors.uploadedFile}</FormHelperText>
        )}
      </Box>
    </Box>
  );
};

export default CreateMethodologyGrid;
