import React, { useEffect, useState, useRef } from "react";
import { ChangeEvent } from "react";
import { ReactNode } from "react";
import { styled } from "styled-components";
import { KeyboardArrowDown } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Radio,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
  IconButton
} from "@mui/material";
import RemoveIcon from "@mui/icons-material/Remove";
import TextEditor from "../../../component/TextEditor";
import { ProjectFormData } from "../../../store/projectType";
import GoogleMapWrapper from "./GoogleMapWrapper";
import { MethodologyProvider } from "../../../context/MethodologyContext";
import { useMethodologies } from "../../../context/MethodologyContext";
import { MethodologyType } from "../methodology/enum";
import { areaOptions, subAreaOptions } from "../../../store/methodologyType";

interface ProjectInfoPageProps {
  formData: ProjectFormData;
  setFormData: React.Dispatch<React.SetStateAction<ProjectFormData>>;
  error: string;
}
interface WorkspaceImage {
  name: string; // Adjust other properties as needed
  // Add other properties related to the image here if needed
}
interface CustomTypographyProps {
  children: ReactNode;
  sx?: object;
}

const CustomTextField = styled(TextField)(({ theme }) => ({
  width: "100%",
  minHeight: "48px",
  padding: "12px 16px",
  gap: "2px",
  borderRadius: "10px 0px 0px 0px",
  border: "1px solid transparent",
  opacity: 1,
  marginLeft: "0",
  "& .MuiInputBase-root": {
    height: "48px" // Ensures input area matches the outer height
  }
}));
interface Methodology {
  id: number;
  title: string;
  methodologyIdName: string;
  area: string;
  subArea: string;
  reductionAmount: number;
}
const ProjectInfoPage: React.FC<ProjectInfoPageProps> = ({
  formData,
  setFormData,
  error
}) => {
  const [methodologyData, setMethodologyData] = useState<number | string>("");
  const [menuItems, setMenuItems] = useState<Methodology[]>([]);
  const [selectedArea, setSelectedArea] = useState<string>("");
  const [selectedSubArea, setSelectedSubArea] = useState<string>("");
  const [selectedValue, setSelectedValue] = useState("a");
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [images, setImages] = useState<File[]>([]);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [projectContent, setProjectContentData] = useState("");
  const [businessOwnership, setBusinessOwnership] = useState("");
  const [businessAssignment, setBusinessAssignment] = useState("");
  const [prePostProcess, setPrePostProcess] = useState("");
  const [businessPerformanceStatus, setBusinessPerformanceStatus] =
    useState("");
  const [changeAfterBusinessApproval, setChangeAfterBusinessApproval] =
    useState("");
  const apiKey =
    process.env.REACT_APP_GOOGLE_MAPS_API_KEY ||
    "AIzaSyC5UuKjoIZ8W24wn-ASHVvkeIcy1Gt4VrI";
  const latitude = 37.7749;
  const longitude = -122.4194;
  const [selectedMethodologyId, setSelectedMethodologyId] = useState<
    number | null
  >(formData?.methodology?.id || null);

  const [methodologies, setMethodologies] = useState<Methodology[]>([]);
  const handleRadioChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setSelectedValue(event.target.value);
  };

  const handleSelectParamTypeChange = async (
    methodologyId: string | number
  ) => {
    const endpoint = "https://api-kisa.greenery.im/methodology";
    try {
      const response = await fetch(`${endpoint}/${methodologyId}`);
      const data = await response.json();
      console.log("API Data:", data);

      if (response.ok) {
        setSelectedArea(data.area);
        setSelectedSubArea(data.subArea);
      } else {
        console.error("Failed to fetch methodology data.");
      }
    } catch (error) {
      console.error("Error fetching methodology:", error);
    }
  };
  const handleTextChange = (fieldName: string, value: string) => {
    setFormData((prev) => ({
      ...prev,
      [fieldName]: value
    }));
  };
  const handleSelectMethodologyChange = (event: SelectChangeEvent<string>) => {
    const selectedId = Number(event.target.value);

    setSelectedMethodologyId(selectedId);
    setFormData((prevData) => ({
      ...prevData,
      methodologyId: selectedId
    }));

    handleSelectParamTypeChange(selectedId);
  };

  useEffect(() => {
    if (selectedMethodologyId) {
      handleSelectParamTypeChange(selectedMethodologyId);
    }
  }, [selectedMethodologyId]);

  useEffect(() => {
    const fetchMethodologyData = async () => {
      try {
        const response = await fetch(
          `https://api-kisa.greenery.im/methodology/list`
        );
        const data: Methodology[] = await response.json();
        setMethodologies(data);

        const selectedMethodology = data.find(
          (item) => item.id === formData.methodology?.id
        );
        if (selectedMethodology) {
          setMethodologyData(selectedMethodology.id);
          setSelectedArea(selectedMethodology.area || "");
          setSelectedSubArea(selectedMethodology.subArea || "");
        }
      } catch (error) {
        console.error("Error fetching methodology data: ", error);
      }
    };

    fetchMethodologyData();
  }, [formData.methodology?.id]);

  useEffect(() => {
    if (selectedMethodologyId)
      handleSelectParamTypeChange(selectedMethodologyId);
  }, [selectedMethodologyId]);
  useEffect(() => {
    console.log("Current formData:", formData);
  }, [formData]);

  const handleSubAreaChange = (event: SelectChangeEvent<string>) => {
    setSelectedSubArea(event.target.value || "");
  };
  const handleImageFileChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target.files;
    console.log(files); // Debugging line
    if (files) {
      const filesArray = Array.from(files);
      console.log(filesArray); // Debugging line
      setImages((prevImages) => [...prevImages, ...filesArray]);
    } else {
      console.error("No files selected");
    }
  };

  const handleTitleChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFormData((prev) => ({ ...prev, title: event.target.value }));
  };
  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    const { name, value } = event.target;
    const keys = name.split(".");

    setFormData((prevFormData) => {
      if (keys.length === 1) {
        return { ...prevFormData, [name]: value };
      } else {
        const [parentKey, childKey] = keys;
        return {
          ...prevFormData,
          [parentKey]: {
            ...(prevFormData as any)[parentKey],
            [childKey]: value
          }
        };
      }
    });
  };

  const handleReductionAreaChange = (event: SelectChangeEvent<string>) => {
    setSelectedArea(event.target.value || "");
  };

  const handleRemoveImage = (index: number) => {
    setImages((prevImages) => prevImages.filter((_, i) => i !== index));
  };

  const handleAddressSelection = (address: string) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      address
    }));
  };

  const handleReductionChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    // Ensure numeric values are handled properly and allow empty string
    const numericValue =
      value === "" ? "" : !isNaN(Number(value)) ? Number(value) : value;

    setFormData((prevData) => ({
      ...prevData,
      [name]: numericValue
    }));
  };

  const [fileName, setFileName] = useState("");
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // Use optional chaining to safely access files
    const fileInput = event.target.files;
    if (fileInput && fileInput.length > 0) {
      const selectedFile = fileInput[0];
      setFileName(selectedFile.name);
    }
  };

  const CustomTypography: React.FC<CustomTypographyProps> = ({
    children,
    ...props
  }) => {
    return (
      <Typography
        {...props}
        sx={{
          color: "#99989D",
          ...props.sx,
          marginLeft: "17px",
          lineHeight: "18px",
          fontWeight: 700
        }}
      >
        {children}
      </Typography>
    );
  };

  return (
    <Grid container rowSpacing={4.5} sx={{ mt: 0 }}>
      {/* <p>{JSON.stringify(formData)}</p> */}
      <Grid item lg={12}>
        <Card
          sx={{
            borderRadius: 3,
            minHeight: 240,
            width: "100%",
            display: "flex",
            flexDirection: "column"
          }}
        >
          <CardContent sx={{ width: "100%", height: "100%" }}>
            <CardHeader sx={{ mb: "-18px" }} />
            <Box sx={{ m: 1, display: "flex", flexDirection: "column" }}>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Box sx={{ width: "100%" }}>
                  <CustomTypography sx={{ fontWeight: "bold" }}>
                    타 기관 등록(신청) 여부
                  </CustomTypography>
                  <Box sx={{ display: "flex", mt: 1 }}>
                    <Box>
                      <Radio
                        checked={selectedValue === "a"}
                        onChange={handleRadioChange}
                        value={selectedValue}
                        name="radio-buttons"
                        inputProps={{ "aria-label": "a" }}
                        sx={{
                          color: "#5D38E5",
                          "&.Mui-checked": {
                            color: "#5D38E5"
                          }
                        }}
                      />
                      없음
                    </Box>
                    <Box sx={{ ml: 5 }}>
                      <Radio
                        checked={selectedValue === "b"}
                        onChange={handleRadioChange}
                        value="b"
                        name="radio-buttons"
                        inputProps={{ "aria-label": "B" }}
                      />
                      있음 (사업신청 단계에서 등록 인증서 필수 첨부)
                    </Box>
                  </Box>
                </Box>
              </Box>

              <Box
                sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}
              >
                <Box sx={{ width: "100%" }}>
                  <CustomTypography sx={{ fontWeight: "bold" }}>
                    프로젝트명
                  </CustomTypography>
                  <CustomTextField
                    sx={{ width: "100%", mt: 1 }}
                    size="small"
                    value={formData.title}
                    name="title"
                    onChange={handleTitleChange}
                    InputProps={{ style: { borderRadius: "10px" } }}
                  />
                </Box>
              </Box>

              <Box
                sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}
              >
                <Box sx={{ width: "48.5%" }}>
                  <CustomTypography sx={{ fontWeight: "bold" }}>
                    유효기간
                  </CustomTypography>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      mt: 1
                    }}
                  >
                    <CustomTextField
                      type="date"
                      sx={{ width: "48.5%" }}
                      size="small"
                      value={formData.startDate}
                      name="startDate"
                      onChange={handleChange}
                      InputProps={{ style: { borderRadius: "10px" } }}
                    />
                    <CustomTypography> - </CustomTypography>
                    <CustomTextField
                      sx={{ width: "48.5%" }}
                      size="small"
                      type="date"
                      value={formData.endDate}
                      name="endDate"
                      onChange={handleChange}
                      InputProps={{ style: { borderRadius: "10px" } }}
                    />
                  </Box>
                </Box>

                <Box sx={{ width: "48.5%" }}>
                  <CustomTypography sx={{ fontWeight: "bold", mt: 1 }}>
                    사업 계획서 상의 온실가스 감축량
                  </CustomTypography>
                  <CustomTextField
                    value={formData.reductionAmount}
                    size="small"
                    type="number"
                    name="reductionAmount"
                    sx={{ width: "100%" }}
                    onChange={handleReductionChange}
                    InputProps={{
                      style: { borderRadius: "10px" },
                      endAdornment: (
                        <span style={{ color: "#99989D", width: "80px" }}>
                          tCO2-eq
                        </span>
                      )
                    }}
                  />
                </Box>
              </Box>
              <Box
                sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}
              >
                <Box sx={{ width: "48.5%" }}>
                  <CustomTypography sx={{ fontWeight: "bold" }}>
                    방법론
                  </CustomTypography>
                  <Select
                    value={
                      selectedMethodologyId !== null
                        ? selectedMethodologyId.toString()
                        : ""
                    }
                    onChange={handleSelectMethodologyChange}
                    displayEmpty
                    size="small"
                    IconComponent={(props) => (
                      <KeyboardArrowDown {...props} sx={{ fontSize: "16px" }} />
                    )}
                    sx={{
                      marginLeft: "15px",
                      width: "95%",
                      minHeight: "48px",
                      opacity: 1,
                      borderRadius: "10px",
                      mt: 2,
                      gap: "2px",
                      backgroundColor: "#ffff",
                      "&:hover": {
                        borderColor: "#F9F6FD"
                      }
                    }}
                  >
                    <MenuItem value="" disabled>
                      방법론 선택
                    </MenuItem>
                    {methodologies.map((methodology) => (
                      <MenuItem
                        key={methodology.id}
                        value={methodology.id.toString()}
                      >
                        {methodology.title}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
                <Box sx={{ width: "48.5%" }}>
                  <Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%"
                      }}
                    >
                      {/* 감축 분야 */}
                      <Box sx={{ width: "48.5%" }}>
                        <CustomTypography sx={{ fontWeight: "bold" }}>
                          감축 분야
                        </CustomTypography>
                        <Select
                          sx={{
                            marginLeft: "15px",
                            width: "95%",
                            minHeight: "48px",
                            opacity: 1,
                            borderRadius: "10px",
                            mt: 2,
                            gap: "2px",
                            backgroundColor: "#ffff",
                            "&:hover": {
                              borderColor: "#F9F6FD"
                            }
                          }}
                          value={selectedArea}
                          onChange={handleReductionAreaChange}
                          size="small"
                          inputProps={{
                            style: {
                              borderRadius: "10px"
                            }
                          }}
                        >
                          {areaOptions.map((el) => (
                            <MenuItem key={el.value} value={el.value}>
                              {el.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </Box>

                      {/* 세부 분야 */}
                      <Box sx={{ width: "48.5%" }}>
                        <CustomTypography sx={{ fontWeight: "bold" }}>
                          세부 분야
                        </CustomTypography>
                        <Select
                          value={selectedSubArea}
                          onChange={handleSubAreaChange}
                          size="small"
                          defaultValue=""
                          inputProps={{
                            style: {
                              borderRadius: "10px"
                            }
                          }}
                          sx={{
                            marginLeft: "15px",
                            width: "95%",
                            minHeight: "48px",
                            opacity: 1,
                            borderRadius: "10px",
                            mt: 2,
                            gap: "2px",
                            backgroundColor: "#ffff",
                            "&:hover": {
                              borderColor: "#F9F6FD"
                            }
                          }}
                        >
                          {subAreaOptions.map((el) => (
                            <MenuItem key={el.value} value={el.value}>
                              {el.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}
              >
                <Box sx={{ width: "48.5%" }}>
                  <CustomTypography sx={{ fontWeight: "bold" }}>
                    주소
                  </CustomTypography>
                  <CustomTextField
                    sx={{ width: "100%", mt: 1 }}
                    size="small"
                    value={formData.address || ""}
                    name="address"
                    onChange={handleChange}
                    InputProps={{
                      style: {
                        borderRadius: "10px"
                      },
                      readOnly: true
                    }}
                  />
                </Box>
                <Box sx={{ width: "48.5%" }}>
                  <CustomTypography sx={{ fontWeight: "bold" }}>
                    상세 주소 입력
                  </CustomTypography>
                  <CustomTextField
                    sx={{ width: "100%", mt: 1 }}
                    size="small"
                    value={formData.detailAddress || ""}
                    name="detailAddress"
                    onChange={handleChange}
                    InputProps={{
                      style: {
                        borderRadius: "10px"
                      }
                    }}
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  mt: 2
                }}
              >
                <Box sx={{ width: "48.5%" }}>
                  <CustomTypography sx={{ fontWeight: "bold" }}>
                    위도
                  </CustomTypography>
                  <CustomTextField
                    sx={{ width: "100%", mt: 1, borderRadius: "10px" }}
                    size="small"
                    value={formData.latitude}
                    name="latitude"
                    onChange={handleChange}
                    InputProps={{
                      readOnly: true,
                      style: {
                        backgroundColor: "#f0f0f0",
                        color: "#a0a0a0",
                        borderRadius: "10px"
                      }
                    }}
                  />
                </Box>
                <Box sx={{ width: "48.5%" }}>
                  <CustomTypography sx={{ fontWeight: "bold" }}>
                    경도
                  </CustomTypography>
                  <CustomTextField
                    sx={{ width: "100%", mt: 1 }}
                    size="small"
                    value={formData.longitude}
                    name="longitude"
                    onChange={handleChange}
                    InputProps={{
                      readOnly: true,
                      style: {
                        backgroundColor: "#f0f0f0",
                        color: "#a0a0a0",
                        borderRadius: "10px"
                      }
                    }}
                  />
                </Box>
              </Box>

              <Box
                sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}
              >
                <Box
                  sx={{
                    width: "100%",
                    minHeight: "48px",
                    padding: "12px 16px",
                    gap: "2px",
                    borderRadius: "10px 0px 0px 0px",
                    border: "1px solid transparent",
                    opacity: 1,
                    marginLeft: "0",
                    "& .MuiInputBase-root": {
                      height: "48px" // Ensures input area matches the outer height
                    }
                  }}
                >
                  {/* Google Map inserted here */}
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      border: 1,
                      borderColor: "#B0AFB3",
                      mt: 1,
                      borderRadius: 3,
                      height: "400px",
                      width: "100%",
                      overflow: "hidden"
                    }}
                  >
                    <GoogleMapWrapper
                      latitude={latitude}
                      longitude={longitude}
                      apiKey={apiKey}
                      onAddressSelected={handleAddressSelection}
                    />
                  </Box>
                </Box>
              </Box>

              <Box
                sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}
              >
                <Box sx={{ width: "100%" }}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "left",
                      mb: 1
                    }}
                  >
                    <CustomTypography sx={{ mr: 2, fontWeight: "bold" }}>
                      사업장 사진
                    </CustomTypography>
                    <Button
                      variant="outlined"
                      sx={{
                        color: "#5D38E5",
                        borderRadius: "20px",
                        textTransform: "none",
                        borderColor: "#5D38E5",
                        "&:hover": {
                          backgroundColor: "#5D38E5",
                          color: "white"
                        }
                      }}
                      onClick={() => fileInputRef.current?.click()}
                    >
                      Add Images
                    </Button>
                    <input
                      ref={fileInputRef}
                      type="file"
                      hidden
                      multiple
                      accept="image/*"
                      onChange={handleImageFileChange}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      height: 280,
                      marginLeft: "15px",
                      marginRight: "15px",
                      p: 1,
                      overflowY: "auto",
                      gap: 1,
                      borderColor: "#B0AFB3",
                      padding: "12px 16px",
                      borderRadius: "10px",
                      border: "1px solid ",
                      opacity: 1,
                      width: "96.8%"
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        gap: 3,
                        flexDirection: "column",
                        alignItems: "flex-start",
                        justifyContent: "flex-start"
                      }}
                    >
                      {images.map((image, index) => (
                        <Box
                          key={index}
                          sx={{
                            position: "relative",
                            width: 130,
                            height: 130,
                            borderRadius: "15%",
                            overflow: "hidden",
                            border: 1,
                            borderColor: "#B0AFB3",
                            backgroundColor: "#f0f0f0"
                          }}
                        >
                          <img
                            src={URL.createObjectURL(image)}
                            alt={`uploaded ${index}`}
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "cover"
                            }}
                          />
                          <IconButton
                            onClick={() => handleRemoveImage(index)}
                            sx={{
                              position: "absolute",
                              top: 1.5,
                              right: 1.5,
                              backgroundColor: "#53515A",
                              "&:hover": {
                                backgroundColor: "#53515A"
                              },
                              p: 0
                            }}
                          >
                            <RemoveIcon sx={{ fontSize: 28, color: "white" }} />
                          </IconButton>
                        </Box>
                      ))}
                    </Box>
                  </Box>
                </Box>
              </Box>

              <Box sx={{ width: "100%" }}>
                {/* Container for CustomTypography and Button */}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center"
                  }}
                >
                  <CustomTypography sx={{ fontWeight: "bold" }}>
                    사업 검증 보고서 등록
                  </CustomTypography>
                  <input
                    type="file"
                    name=""
                    value={formData.businessValidationReportFile}
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                    id="file-upload"
                  />
                  <label htmlFor="file-upload">
                    <Button
                      component="span"
                      variant="text"
                      color="inherit"
                      sx={{
                        ml: 2,
                        marginRight: "10px",
                        textTransform: "none",
                        textDecoration: "underline"
                      }}
                    >
                      Upload file
                    </Button>
                  </label>
                </Box>

                <CustomTextField
                  sx={{ width: "100%" }}
                  size="small"
                  value={fileName}
                  name="fileName"
                  InputProps={{
                    style: { borderRadius: "10px" },
                    inputProps: { readOnly: true }
                  }}
                />
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Grid>

      <Grid item lg={12}>
        <Card
          sx={{
            borderRadius: 3,
            minHeight: "100%",
            width: "100%",
            display: "flex"
          }}
        >
          <CardContent sx={{ width: "100%", height: "100%" }}>
            <CardHeader
              title={"Details"}
              titleTypographyProps={{ fontWeight: "bold", fontSize: 19 }}
            />
            <Box
              sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}
            >
              <Box>
                <CustomTypography
                  sx={{ fontWeight: "bold", mb: 1, marginLeft: 0 }}
                >
                  프로젝트 내용 및 목적
                </CustomTypography>
                <Box
                  sx={{
                    maxWidth: "100%", // Set the box to take full width
                    width: "1018px",
                    marginLeft: "25px" // Subtract 30px (15px on each side) for left-right gap
                  }}
                >
                  <TextEditor
                    setData={(val: string) =>
                      handleTextChange("projectContent", val)
                    }
                    minHeight={200}
                    data={formData.projectContent}
                  />
                </Box>
              </Box>
            </Box>

            <Box
              sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}
            >
              <Box>
                <CustomTypography sx={{ fontWeight: "bold", mb: 2 }}>
                  사업자 및 온실가스 감축량 소유권
                </CustomTypography>
                <Box
                  sx={{
                    width: "1018px",
                    marginLeft: "25px" // Subtract 30px (15px on each side) for left-right gap
                  }}
                >
                  <TextEditor
                    setData={(val: string) =>
                      handleTextChange("businessOwnership", val)
                    }
                    minHeight={200}
                    data={formData.businessOwnership}
                  />
                </Box>
              </Box>
            </Box>
            <Box
              sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}
            >
              <Box>
                <CustomTypography sx={{ fontWeight: "bold", mb: 2 }}>
                  프로젝트 인증실적의 중복성 평가
                </CustomTypography>
                <Box
                  sx={{
                    width: "1018px",
                    marginLeft: "25px" // Subtract 30px (15px on each side) for left-right gap
                  }}
                >
                  <TextEditor
                    setData={(val: string) =>
                      handleTextChange("businessAssignment", val)
                    }
                    minHeight={200}
                    data={formData.businessAssignment}
                  />
                </Box>
              </Box>
            </Box>
            <Box
              sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}
            >
              <Box sx={{ width: "100%" }}>
                <CustomTypography sx={{ fontWeight: "bold", mb: 2 }}>
                  사업 전,후 공정
                </CustomTypography>
                <Box
                  sx={{
                    width: "1018px",
                    marginLeft: "25px",
                    mt: 1 // Subtract 30px (15px on each side) for left-right gap
                  }}
                >
                  <TextEditor
                    setData={(val: string) =>
                      handleTextChange("prePostProcess", val)
                    }
                    minHeight={200}
                    data={formData.prePostProcess}
                  />
                </Box>
              </Box>
            </Box>
            <Box
              sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}
            >
              <Box sx={{ width: "100%" }}>
                <CustomTypography
                  sx={{ fontWeight: "bold", mb: 2, marginLeft: "50px" }}
                >
                  사업 이행 상태
                </CustomTypography>
                <Box
                  sx={{
                    width: "1018px",
                    marginLeft: "25px",
                    mt: 1 // Subtract 30px (15px on each side) for left-right gap
                  }}
                >
                  <TextEditor
                    setData={(val: string) =>
                      handleTextChange("businessPerformanceStatus", val)
                    }
                    minHeight={200}
                    data={formData.businessPerformanceStatus}
                  />
                </Box>
              </Box>
            </Box>
            <Box
              sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}
            >
              <Box sx={{ width: "100%" }}>
                <CustomTypography
                  sx={{ fontWeight: "bold", mb: 1, marginLeft: "52px" }}
                >
                  사업 승인 후 변경사항
                </CustomTypography>
                <Box
                  sx={{
                    width: "1018px",
                    marginLeft: "25px",
                    mt: 1
                  }}
                >
                  <TextEditor
                    setData={(val: string) =>
                      handleTextChange("changeAfterBusinessApproval", val)
                    }
                    minHeight={200}
                    data={formData.changeAfterBusinessApproval}
                  />
                </Box>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default ProjectInfoPage;
