import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
  Divider,
  Radio,
  Button,
} from "@mui/material";
import TextEditor from "../../../component/TextEditor";
import { useEffect, useState } from "react";

const InquiryDetailsGrid = () => {
  const [data, setData] = useState();
  useEffect(() => {
    console.log("data is : ", data);
  }, [data]);

  return (
    <Grid container rowSpacing={4.5} columnSpacing={2.75} sx={{ mt: -5 }}>
      {/* 첫번째 카드 */}
      <Grid item lg={3}>
        <Card
          sx={{
            borderRadius: 3,

            width: "100%",
            display: "flex",
          }}
        >
          <CardContent sx={{ width: "100%" }}>
            <CardHeader
              title="Information"
              titleTypographyProps={{ fontWeight: "bold", fontSize: 19 }}
            />
            <Box
              sx={{
                m: 2,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box>
                <Typography fontWeight={"bold"} color={"#99989D"}>
                  Status
                </Typography>
                <Typography sx={{ mt: 1, fontSize: 13 }}>대기중</Typography>
              </Box>
              <Box sx={{ mt: 3 }}>
                <Typography fontWeight={"bold"} color={"#99989D"}>
                  Category
                </Typography>
                <Typography sx={{ mt: 1, fontSize: 13 }}>
                  스테이션(차징/카페/CS)
                </Typography>
              </Box>
              <Box sx={{ mt: 3 }}>
                <Typography fontWeight={"bold"} color={"#99989D"}>
                  Writer
                </Typography>
                <Typography sx={{ mt: 1, fontSize: 13 }}>Minji Lee</Typography>
              </Box>
              <Box sx={{ mt: 3 }}>
                <Typography fontWeight={"bold"} color={"#99989D"}>
                  E-mail
                </Typography>
                <Typography sx={{ mt: 1, fontSize: 13 }}>
                  Minji123@gmail.com
                </Typography>
              </Box>
              <Box sx={{ mt: 3 }}>
                <Typography fontWeight={"bold"} color={"#99989D"}>
                  Phone Number
                </Typography>
                <Typography sx={{ mt: 1, fontSize: 13 }}>
                  +855 010-1111-1111
                </Typography>
              </Box>
              <Divider sx={{ mt: 3 }} />
              <Box sx={{ mt: 3 }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    color: "#99989D",
                  }}
                >
                  <Typography fontSize={14}>등록 일시</Typography>
                  <Typography fontSize={14}>2022.01.01 14:24</Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    color: "#99989D",
                    mt: 1,
                  }}
                >
                  <Typography fontSize={14}>답변 일시</Typography>
                  <Typography fontSize={14}>2022.01.04 14:24</Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    color: "#99989D",
                    mt: 1,
                  }}
                >
                  <Typography fontSize={14}>삭제 일시</Typography>
                  <Typography fontSize={14}>2022.01.05 14:24</Typography>
                </Box>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      {/* 두번째 카드 */}
      <Grid item lg={9}>
        <Card
          sx={{
            borderRadius: 3,

            width: "100%",
            display: "flex",
          }}
        >
          <CardContent sx={{ width: "100%" }}>
            <Box
              sx={{
                m: 2,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box sx={{ mt: 0 }}>
                <Typography fontWeight={"bold"} fontSize={21}>
                  팝플 스테이션 운영 시간 문의
                </Typography>
                <Box
                  sx={{
                    border: 2,
                    height: 410,
                    mt: 3,
                    borderRadius: 3,
                    borderColor: "#F2F2F2",
                  }}
                >
                  <Box sx={{ m: 2 }}>
                    안녕하세요. <br />
                    최근 업로드 해주신 운영시간 변경 공지에 대해서 궁금한 사항이
                    있습니다. 아래 사진에 운영시간이 19시라고 적혀있는데 다른
                    지점들도 마찬가지일까요? 한국에 있는 지점들만 해당하는
                    것인가요? 빠른 답변 부탁드립니다.
                  </Box>
                </Box>
              </Box>

              <Box sx={{ mt: 3 }}>
                <TextEditor setData={setData} minHeight={130} />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  mt: 3,
                  mb: -3,
                }}
              >
                <Button
                  sx={{
                    textTransform: "none",
                    height: 40,
                    backgroundColor: "#5D38E5",
                    color: "white",
                    borderRadius: 5,
                    width: 125,
                    ml: 2,
                    ":hover": {
                      backgroundColor: "#A38BFC",
                    },
                  }}
                >
                  Comment
                </Button>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default InquiryDetailsGrid;
