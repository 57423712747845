import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
  Divider,
  Radio,
} from "@mui/material";
import TextEditor from "../../../component/TextEditor";
import { useEffect, useState } from "react";

const FAQCreateGrid = () => {
  const [data, setData] = useState();
  useEffect(() => {
    console.log("data is : ", data);
  }, [data]);

  const [selectedValue, setSelectedValue] = useState("a");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(event.target.value);
  };
  return (
    <Grid container rowSpacing={4.5} columnSpacing={2.75} sx={{ mt: -5 }}>
      {/* 첫번째 카드 */}
      <Grid item lg={8}>
        <Card
          sx={{
            borderRadius: 3,

            width: "100%",
            display: "flex",
          }}
        >
          <CardContent sx={{ width: "100%" }}>
            <Box
              sx={{
                m: 2,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box sx={{ mt: 0 }}>
                <Typography fontWeight={"bold"}>Title</Typography>
                <TextField
                  // name="title"
                  sx={{ width: "100%", mt: 1 }}
                  // size="small"
                  // value={""}
                  // onChange={}
                  placeholder="제목 입력"
                  InputProps={{
                    style: {
                      borderRadius: "12px",
                    },
                  }}
                />
              </Box>
              <Divider sx={{ mt: 3 }} />
              <Box sx={{ mt: 3 }}>
                <TextEditor setData={setData} minHeight={450} />
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      {/* 두번째 카드 */}
      <Grid item lg={4}>
        <Card
          sx={{
            borderRadius: 3,

            width: "100%",
            display: "flex",
          }}
        >
          <CardContent sx={{ width: "100%" }}>
            <CardHeader
              title="Information"
              titleTypographyProps={{ fontWeight: "bold", fontSize: 19 }}
            />
            <Box
              sx={{
                m: 2,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box>
                <Typography fontWeight={"bold"} color={"#99989D"}>
                  Category
                </Typography>
                <Select
                  //   size="small"
                  value={"none"}
                  sx={{
                    width: "100%",
                    mt: 1,
                    borderRadius: "12px",
                    // backgroundColor: "#E9E9EA",
                  }}
                  //   disabled
                >
                  <MenuItem value="none">Select</MenuItem>
                </Select>
              </Box>
              <Box sx={{ mt: 3 }}>
                <Typography fontWeight={"bold"} color={"#99989D"}>
                  Show/Hide
                </Typography>
                <Box sx={{ display: "flex" }}>
                  <Box>
                    <Radio
                      checked={selectedValue === "a"}
                      onChange={handleChange}
                      value="a"
                      name="radio-buttons"
                      inputProps={{ "aria-label": "A" }}
                    />
                    Show
                  </Box>
                  <Box sx={{ ml: 5 }}>
                    <Radio
                      checked={selectedValue === "b"}
                      onChange={handleChange}
                      value="b"
                      name="radio-buttons"
                      inputProps={{ "aria-label": "B" }}
                    />
                    Hide
                  </Box>
                </Box>
              </Box>
              <Box sx={{ mt: 3 }}>
                <Typography fontWeight={"bold"} color={"#99989D"}>
                  Writer
                </Typography>
                <TextField
                  name="description"
                  sx={{ width: "100%", mt: 1 }}
                  value={"Very Kim"}
                  // onChange={handleChange}
                  InputProps={{
                    style: {
                      borderRadius: "7px",
                      backgroundColor: "#E9E9EA",
                    },
                  }}
                  disabled
                />
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default FAQCreateGrid;
