import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@mui/material";
import { MonitoringReduction } from "../../../store/monitoringType";

const CustomCell = ({
  title,
  align,
  width,
}: {
  title: string;
  align: "left" | "center" | "right";
  width?: string | number;
}) => {
  return (
    <TableCell
      sx={{
        backgroundColor: "white",
        color: "black",
        fontWeight: 600,
        fontFamily: "var(--bs-font-sans-serif)",
        width: width,
        fontSize: 12,
      }}
      align={align}
    >
      {title}
    </TableCell>
  );
};

interface MonitoringReductionTableProps {
  rows: MonitoringReduction[];
}

const testData: MonitoringReduction[] = [
  {
    userNo: '6EV6I30000',
    number: 'TC01234001',
    distance: '17.7km',
    charge: '30%',
    baselineEmission: '0.0023 kgCO2-eq',
    blockchainUrl: 'OK',
    reductionAmount: '10.0 tCO2-eq',
    collectionDate: '2024/01/01 13:40',
  },
  {
    userNo: '6EV6I30000',
    number: 'TC01234002',
    distance: '13.7km',
    charge: '40%',
    baselineEmission: '0.0023 kgCO2-eq',
    blockchainUrl: 'OK',
    reductionAmount: '4.2 tCO2-eq',
    collectionDate: '2024/01/02 13:40',
  },
  {
    userNo: '6EV6I30000',
    number: 'TC01234003',
    distance: '15.7km',
    charge: '30%',
    baselineEmission: '0.0023 kgCO2-eq',
    blockchainUrl: 'OK',
    reductionAmount: '2.3 tCO2-eq',
    collectionDate: '2024/01/03 13:40',
  },
];

const MonitoringReductionTable: React.FC<MonitoringReductionTableProps> = ({
  rows,
}) => {
  return (
    <Paper
      sx={{
        width: "100%",
        border: 1,
        borderColor: "#F3F3F4",
        borderRadius: 3,
        mt: 3,
      }}
      elevation={0}
    >
      <TableContainer sx={{ borderRadius: 3 }}>
        <Table
          sx={{
            width: "100%",
          }}
          stickyHeader
          aria-label="sticky table"
        >
          <TableHead>
            <TableRow sx={{fontSize: 50}}>
              <CustomCell title="No" align="center" width="10px"/>
              <CustomCell title="사용자 ID" align="center"/>
              <CustomCell title="차대번호" align="center"/>
              <CustomCell title="주행거리" align="center"/>
              <CustomCell title="배터리 충전잔량" align="center" width="75px"/>
              <CustomCell title="베이스라인 배출량" align="center" />
              <CustomCell title="블록체인 검증" align="center" width="85px"/>
              <CustomCell title="감축량" align="center"/>
              <CustomCell title="수집일시" align="center" />
            </TableRow>
          </TableHead>
          <TableBody>
            {testData
              .map((row, index) => (
                <TableRow
                  key={index}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    ":hover": { backgroundColor: "#F6F9FC", cursor: "pointer" },
                  }}
                >
                  <TableCell component="th" scope="row" align="center">
                    {index + 1}
                  </TableCell>
                  <TableCell align="center">{row.userNo}</TableCell>
                  <TableCell align="center">{row.number}</TableCell>
                  <TableCell align="center">{row.distance}</TableCell>
                  <TableCell align="center">{row.charge}</TableCell>
                  <TableCell align="center">{row.baselineEmission}</TableCell>
                  <TableCell align="center">{row.blockchainUrl}</TableCell>
                  <TableCell align="center">{row.reductionAmount}</TableCell>
                  <TableCell align="center">{row.collectionDate}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default MonitoringReductionTable;
