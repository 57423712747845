import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import { ReactNode } from "react";
import styled from "styled-components";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";

import {
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormGroup,
  FormControl,
  FormLabel,
  Table,
  TableRow,
  TableCell,
  Checkbox,
  TextField,
  Select,
  MenuItem,
  Typography,
  Box,
  Grid
} from "@mui/material";
import { SelectChangeEvent } from "@mui/material";
import React, { SyntheticEvent, ChangeEvent, useEffect, useState } from "react";
import { ArgumentDetailFormData, ValueListItem } from "../ParamDetailsPage";
import { ParamType } from "../../../../store/paramType";
import { Methodology } from "../../../../context/MethodologyContext";
import { PurposeEnum } from "../../../../store/paramType";
interface ParamResultProps {
  formData: ArgumentDetailFormData;
  setFormData: React.Dispatch<React.SetStateAction<ArgumentDetailFormData>>;
  methodologies: Methodology[];
  onHandleMethodologyChange: (event: SelectChangeEvent<number>) => void;
}
interface CustomTypographyProps {
  children: ReactNode;
  sx?: object;
}
const CustomTextField = styled(TextField)(({ theme }) => ({
  width: "100%",
  minHeight: "40px",
  padding: "12px 16px",
  borderRadius: "10px",
  border: "1px solid transparent",
  opacity: 1,
  marginLeft: "10px"
}));
const ParamResult: React.FC<ParamResultProps> = ({
  formData,
  setFormData,
  methodologies,
  onHandleMethodologyChange
}) => {
  const [valueList, setValueList] = useState<ValueListItem[] | []>([]);
  const [load, setLoad] = useState(false);

  const handleInputChange: React.ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (event) => {
    const { name, value } = event.target;
    setFormData((prev: ArgumentDetailFormData) => ({ ...prev, [name]: value }));
  };

  const handlePurposeChange = (value: PurposeEnum) => {
    setFormData((prev) => {
      const prevPurpose = prev.purpose || [];
      const newPurpose = prevPurpose.includes(value)
        ? prevPurpose.filter((v) => v !== value) // Remove selected value
        : [...prevPurpose, value]; // Add new value
      return { ...prev, purpose: newPurpose };
    });
  };

  useEffect(() => {
    setFormData((prev) => ({
      ...prev,
      valueList
    }));
  }, [valueList, setFormData]);
  const CustomTypography: React.FC<CustomTypographyProps> = ({
    children,
    ...props
  }) => {
    return (
      <Typography
        {...props}
        sx={{
          color: "#99989D",
          ...props.sx,
          lineHeight: "18px",
          fontSize: "14px",
          fontWeight: 700,
          width: "80px"
        }}
      >
        {children}
      </Typography>
    );
  };

  return (
    // <Grid container spacing={1}>
    <Grid item xs={12} sm={2}>
      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mt: 5
          }}
        >
          <CustomTypography sx={{ fontWeight: "bold", mt: 2.5 }}>
            데이터/인자
          </CustomTypography>
          <CustomTextField
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            InputProps={{
              style: {
                borderRadius: "10px",
                height: "40px"
              }
            }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mt: 2
          }}
        >
          <CustomTypography sx={{ fontWeight: "bold", mt: 2.5 }}>
            단위
          </CustomTypography>
          <CustomTextField
            name="unit"
            value={formData.unit}
            onChange={handleInputChange}
            InputProps={{
              style: {
                borderRadius: "10px",
                height: "40px"
              }
            }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mt: 2
          }}
        >
          <CustomTypography sx={{ fontWeight: "bold", mt: 2.5 }}>
            정의
          </CustomTypography>
          <CustomTextField
            name="definition"
            value={formData.definition}
            onChange={handleInputChange}
            InputProps={{
              style: {
                borderRadius: "10px",
                height: "40px"
              }
            }}
          />
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          mt: 2
        }}
      >
        <CustomTypography sx={{ fontWeight: "bold", mt: 5 }}>
          방법론명
        </CustomTypography>
        <Select
          name="methodologyId"
          value={formData.methodologyId || ""}
          onChange={onHandleMethodologyChange}
          IconComponent={(props) => (
            <KeyboardArrowDown {...props} sx={{ fontSize: "16px" }} />
          )}
          sx={{
            marginLeft: "22px",
            marginRight: "15px",
            width: "90%",
            minHeight: "40px",
            opacity: 1,
            borderRadius: "10px",
            mt: 4.2,
            gap: "2px",
            backgroundColor: "#ffff",
            "&:hover": {
              borderColor: "#F9F6FD"
            }
          }}
          style={{ borderRadius: "10px", height: "40px" }}
          renderValue={(val) => {
            const selected =
              methodologies.find((e: any) => e.id === val) || null;
            return selected ? `${selected?.title}` : `방법론 선택`;
          }}
        >
          {methodologies.length > 0 ? (
            methodologies.map((methodology) => (
              <MenuItem key={methodology.id} value={methodology.id}>
                {methodology.title}
              </MenuItem>
            ))
          ) : (
            <MenuItem value="" disabled>
              조회 데이터가 없습니다.
            </MenuItem>
          )}
        </Select>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          mt: 2
        }}
      >
        <CustomTypography sx={{ fontWeight: "bold", mt: 2.5 }}>
          버전
        </CustomTypography>
        <CustomTextField
          name="version"
          value={formData.version}
          onChange={handleInputChange}
          InputProps={{
            style: {
              borderRadius: "10px",
              height: "40px"
            }
          }}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          mt: 2
        }}
      >
        <CustomTypography sx={{ fontWeight: "bold", mt: 2.5 }}>
          데이터 목적
        </CustomTypography>

        <FormControl sx={{ width: "80%", mt: 2 }}>
          <RadioGroup
            row
            value={formData.purpose?.[0]}
            onChange={(e) => handlePurposeChange(e.target.value as PurposeEnum)}
          >
            <FormControlLabel
              value={PurposeEnum.BASELINE}
              control={<Radio />}
              label={
                <Typography sx={{ fontSize: "13px" }}>베이스라인</Typography>
              }
            />
            <FormControlLabel
              value={PurposeEnum.PROJECT}
              control={<Radio />}
              label={
                <Typography sx={{ fontSize: "13px" }}>프로젝트</Typography>
              }
            />
            <FormControlLabel
              value={PurposeEnum.FINAL_REDUCTION}
              control={<Radio />}
              label={
                <Typography sx={{ fontSize: "13px" }}>최종 감축량</Typography>
              }
            />
          </RadioGroup>
        </FormControl>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          mt: 2
        }}
      >
        <CustomTypography sx={{ fontWeight: "bold", mt: 2 }}>
          베이스라인 배출량 산정 수식
        </CustomTypography>
        <CustomTextField
          name="fomula"
          value={formData.fomula}
          onChange={(e) => setFormData({ ...formData, fomula: e.target.value })}
          minRows={4}
          InputProps={{
            style: {
              borderRadius: "10px",
              height: "95px"
            }
          }}
        />
      </Box>
    </Grid>
    // </Grid>
  );
};

export default ParamResult;
