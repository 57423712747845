import Editor from "@ckeditor/ckeditor5-build-classic/build/ckeditor";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { Box } from "@mui/material";
import { useCallback, useEffect, useRef } from "react";
import "./style.css";

interface TextEditorProps {
  setData?: any;
  minHeight?: any;
  data?: any;
}

const customUploadAdapter = (loader: any) => {
  return {
    upload() {
      return new Promise((resolve, reject) => {
        const formData = new FormData();
        loader.file.then((file: any) => {
          formData.append("file", file);
          console.log("file is : ", formData.get("file"));
          // axios
          //   .post('http://localhost:8080/api/v0/file/upload', formData)
          //   .then((res) => {
          //     resolve({
          //       default: res.data.data.uri
          //     });
          //   })
          //   .catch((err) => reject(err));
        });
      });
    },
  };
};

function uploadPlugin(editor: any) {
  editor.plugins.get("FileRepository").createUploadAdapter = (loader: any) => {
    return customUploadAdapter(loader);
  };
}

const TextEditor = ({ setData, minHeight, data }: TextEditorProps) => {
  const editorRef = useRef<any>(null);
  const observerRef = useRef<any>(null);

  useEffect(() => {
    return () => {
      // Cleanup observer when the component is unmounted
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }, []);

  const handleEditorReady = useCallback((editor: any) => {
    const editableElement = editor.editing.view.getDomRoot();

    const observer = new ResizeObserver((entries) => {
      for (let entry of entries) {
        console.log("The editable element has been resized in DOM.");
        console.log(entry.target); // -> editableElement
        console.log(entry.contentRect.width); // -> e.g. '423px'
      }
    });

    observer.observe(editableElement);
    observerRef.current = observer; // Store observer reference for cleanup
  }, []);

  const editorConfiguration = {
    extraPlugins: [uploadPlugin],
    toolbar: {
      items: [
        "heading",
        "|",
        "bold",
        "italic",
        "superscript",
        "subscript",
        "link",
        "bulletedList",
        "numberedList",
        "|",
        "outdent",
        "indent",
        "alignment",
        "|",
        "insertTable",
        "|",
        "imageUpload",
        "blockQuote",
        // "mediaEmbed",        
        "undo",
        "redo",
      ],
    },
    language: "en",
    image: {
      toolbar: [
        "imageTextAlternative",
        "toggleImageCaption",
        "imageStyle:inline",
        "imageStyle:block",
        "imageStyle:side",
      ],
    },
    table: {
      contentToolbar: [
        "tableColumn",
        "tableRow",
        "mergeTableCells",
        "tableCellProperties",
      ],
    },
  };

  return (
    <Box
      sx={{
        "& .ck.ck-editor__editable:not(.ck-editor__nested-editable)": {
          minHeight: minHeight || "300px", // 최소 높이 설정
          maxHeight: "600px", // 최대 높이 설정
          overflowY: "auto", // 높이를 넘으면 스크롤바 표시
        },
      }}
      ref={editorRef}
    >
      <CKEditor
        editor={Editor}
        config={editorConfiguration}
        data={data}
        onChange={(event: any, editor: any) => {
          setData(editor.getData()); // 에디터 작성 내용 저장
        }}
        onReady={handleEditorReady}
      />
    </Box>
  );
};

export default TextEditor;
