import AddIcon from "@mui/icons-material/Add";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import TrashIcon from "../assets/icon/TrashIcon.svg";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "../../../App.css";
import CommonList from "../../../component/CommonList";
import CreateMethodologyGrid from "./CreateMethodologyGridPage";
import { MethodologyType } from "./enum";
import { TextTransformation } from "@ckeditor/ckeditor5-typing";

interface FormData {
  version: string;
  standard: MethodologyType;
  title: string;
  methodologyIdName: string;
  startDate: string;
  area: MethodologyType;
  subArea: MethodologyType;
  expiredYear: string;
  id: number;
  createdAt: number;
}

const CreateMethodologyPage: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogType, setDialogType] = useState<"delete" | "navigate" | null>(
    null
  );
  const [error, setError] = useState("");
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [formData, setFormData] = useState<FormData>({
    version: "",
    standard: MethodologyType.standard,
    title: "",
    methodologyIdName: "",
    startDate: "",
    area: MethodologyType.area,
    subArea: MethodologyType.subArea,
    expiredYear: "",
    id: 0,
    createdAt: 0
  });
  const [initialFormData, setInitialFormData] = useState<FormData>({
    version: "",
    standard: MethodologyType.standard,
    title: "",
    methodologyIdName: "",
    startDate: "",
    area: MethodologyType.area,
    subArea: MethodologyType.subArea,
    expiredYear: "",
    id: 0,
    createdAt: 0
  });
  const [isSaved, setIsSaved] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [errorModalMessage, setErrorModalMessage] = useState("");
  const [isFormDirty, setIsFormDirty] = useState(false); // Track unsaved changes

  useEffect(() => {
    if (id) {
      const fetchMethodology = async () => {
        try {
          const response = await fetch(
            `https://api-kisa.greenery.im/methodology/${id}`
          );
          if (response.ok) {
            const data = await response.json();
            setFormData(data);
            setInitialFormData(data);
            setIsSaved(true);
            console.log("Fetched data:", data);
          } else {
            console.error("Failed to fetch data");
          }
        } catch (error) {
          console.error("Error fetching methodology", error);
        }
      };
      fetchMethodology();
    }
  }, [id]);

  useEffect(() => {
    // Determine if the form is dirty (has unsaved changes)
    const isDirty = Object.keys(formData).some((key) => {
      return (
        formData[key as keyof FormData] !==
        initialFormData[key as keyof FormData]
      );
    });
    setIsFormDirty(isDirty);
  }, [formData, initialFormData]);
  const [clicked, setClicked] = useState(false);

  const handleNavigate = (route: string) => {
    if (isFormDirty) {
      setDialogType("navigate");
      setDialogOpen(true);
    } else {
      navigate(route);
    }
  };

  const handleClickOpenDialog = (type: "delete" | "navigate") => {
    console.log(`Opening ${type} dialog`);
    setDialogType(type);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setDialogType(null);
  };

  const handleConfirmDelete = async () => {
    if (!id) return;
    setLoading(true);
    try {
      const response = await fetch(
        `https://api-kisa.greenery.im/methodology/${id}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json"
          }
        }
      );
      if (response.ok) {
        setError("");
        navigate("/methodologyMng");
      } else {
        const errorData = await response.json();
        setError(
          `Failed to delete data: ${errorData.message || response.statusText}`
        );
        console.error("Delete failed:", errorData);
      }
    } catch (error) {
      setError("An error occurred while deleting data.");
      console.error("An error occurred:", error);
    } finally {
      setLoading(false);
      handleCloseDialog();
    }
  };

  const handleConfirmNavigate = () => {
    navigate("/methodologyMng");
    handleCloseDialog();
  };

  const handleSelect = (
    field: "standard" | "area" | "subArea",
    value: MethodologyType
  ) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value
    }));
  };
  const handleSave = async () => {
    const newErrors: { [key: string]: string } = {};

    // Validate fields
    const validations = [
      {
        condition: !formData.version,
        message: "버전을 입력 해주세요.",
        key: "version"
      },
      {
        condition: !formData.title,
        message: "방법론명을 입력 해주세요.",
        key: "title"
      },
      {
        condition: !formData.methodologyIdName,
        message: "방법론 ID 를 입력 해주세요.",
        key: "methodologyIdName"
      },
      {
        condition: formData.standard === MethodologyType.standard,
        message: "기준을 선택해 주세요.",
        key: "standard"
      },
      {
        condition: formData.area === MethodologyType.area,
        message: "분야를 선택해 주세요.",
        key: "area"
      },
      {
        condition: formData.subArea === MethodologyType.subArea,
        message: "세부 분야를 선택해 주세요.",
        key: "subArea"
      },
      {
        condition: !formData.startDate,
        message: "시작일을 입력해 주세요.",
        key: "startDate"
      },
      {
        condition: !formData.expiredYear,
        message: "유효기간을 입력해주세요.",
        key: "expiredYear"
      }
    ];

    for (const validation of validations) {
      if (validation.condition) {
        newErrors[validation.key] = validation.message;
      }
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    setLoading(true);
    console.log("Form Data being sent:", formData);

    try {
      // Check if the title already exists
      const existingResponse = await fetch(
        `https://api-kisa.greenery.im/methodology?methodologyIdName=${encodeURIComponent(
          formData.methodologyIdName
        )}`
      );

      if (existingResponse.ok) {
        const existingData = await existingResponse.json();
        if (existingData.length > 0 && existingData[0].id !== formData.id) {
          setErrorModalMessage("이미 존재하는 방법론명입니다.");
          setErrorModalOpen(true);
          return;
        }
      }

      const method = formData.id ? "PUT" : "POST";
      const url =
        method === "PUT"
          ? `https://api-kisa.greenery.im/methodology/${formData.id}`
          : "https://api-kisa.greenery.im/methodology";

      const { id, ...dataToSend } = formData;

      const response = await fetch(url, {
        method,
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(dataToSend)
      });

      if (response.ok) {
        const savedData = await response.json();
        setFormData(savedData);
        setInitialFormData(savedData);
        setIsSaved(true);
        setErrors({});
        setError("");
        navigate("/methodologyMng");
      } else {
        const errorData = await response.json();
        setError(
          `Failed to save data: ${errorData.message || response.statusText}`
        );
        console.error("Save failed:", errorData);
      }
    } catch (error) {
      setError("An error occurred while saving data.");
      console.error("An error occurred:", error);
    } finally {
      setLoading(false);
    }
  };

  const HeaderButton = () => (
    <form action="" style={{ display: "flex", alignItems: "center" }}>
      <Button
        sx={{
          width: "113px",
          height: "45px",
          borderRadius: "40px",
          padding: "20px, 0px, 20px, 0",
          background: "#5D38E5",
          color: "#FFFFFF",
          textTransform: "none",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          textWeight: "600",
          textSize: "14px",
          lineHeight: "20px",
          "&:hover": {
            background: " #5a3acb"
          },
          marginRight: "2px"
        }}
        onClick={handleSave}
        disabled={loading}
      >
        {!isSaved && <AddIcon fontSize="small" />}
        {isSaved ? "Update" : "Create"}
      </Button>
      {isSaved && (
        <>
          <span
            style={{
              borderLeft: "0.40px solid #99989D",
              height: "30px",
              margin: "0 5px" // Reduced margin
            }}
          ></span>

          <Button
            sx={{
              height: "8.50px",
              width: "8.50px",
              color: "red",
              ":hover": {
                backgroundColor: "inherit"
              },
              display: "flex",
              justifyContent: "left"
            }}
            onClick={() => handleClickOpenDialog("delete")}
            disabled={loading}
          >
            <div>
              <img
                src={TrashIcon}
                alt="Trash Icon"
                style={{
                  width: "35px",
                  height: "35px",
                  textTransform: "none"
                }}
              />
            </div>
          </Button>
        </>
      )}
    </form>
  );

  const HeaderBackButton = () => (
    <IconButton
      sx={{
        ":hover": { backgroundColor: "#F2F2F2" },
        mb: 0.5,
        mr: 1
      }}
      onClick={() => handleNavigate("/methodologyMng")}
    >
      <ArrowBackIosNewIcon fontSize="medium" />
    </IconButton>
  );

  return (
    <CommonList
      title={id ? "방법론 상세" : "방법 등록"}
      setModal={null}
      headerButton={<HeaderButton />}
      headerBackButton={<HeaderBackButton />}
    >
      <Box>
        <CreateMethodologyGrid
          formData={formData}
          setFormData={setFormData}
          errors={errors}
          handleSelect={handleSelect}
        />
      </Box>
      <Dialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        PaperProps={{
          sx: {
            maxWidth: "400px",
            width: "90%",
            borderRadius: "25px",
            backgroundColor: "#ffffff",
            overflow: "hidden"
          }
        }}
      >
        <DialogContent
          sx={{
            padding: { xs: "1rem", sm: "1.5rem" },
            color: "black",
            textAlign: "center",
            fontSize: { xs: "0.875rem", sm: "1rem" }
          }}
        >
          {dialogType === "delete"
            ? "삭제한 방법론은 복구할 수 없습니다. 방법론을 삭제 하시겠습니까?"
            : "작성하던 것을 중단하고 이동하시겠습니까? 해당 사항은 저장되지 않습니다."}
        </DialogContent>
        <DialogActions
          sx={{
            padding: "1rem",
            backgroundColor: "#ffffff",
            justifyContent: "center"
          }}
        >
          <Button
            onClick={handleCloseDialog}
            sx={{
              width: "120px",
              height: "40px",
              borderRadius: 5,
              border: "1px solid #E1E1E1",
              color: "text.primary",
              ":hover": {
                backgroundColor: "#F2F2F2"
              }
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={
              dialogType === "delete"
                ? handleConfirmDelete
                : handleConfirmNavigate
            }
            sx={{
              width: "120px",
              height: "40px",
              borderRadius: 5,
              backgroundColor: "#5D38E5",
              color: "white",
              ":hover": {
                backgroundColor: "#5f359f"
              }
            }}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={errorModalOpen}
        onClose={() => setErrorModalOpen(false)}
        PaperProps={{
          sx: {
            maxWidth: "400px",
            width: "90%",
            borderRadius: "25px",
            backgroundColor: "#ffffff",
            overflow: "hidden"
          }
        }}
      >
        <DialogContent
          sx={{
            padding: { xs: "1rem", sm: "1.5rem" },
            color: "red",
            textAlign: "center",
            fontSize: { xs: "0.875rem", sm: "1rem" }
          }}
        >
          {errorModalMessage}
        </DialogContent>
        <DialogActions
          sx={{
            padding: "1rem",
            backgroundColor: "#ffffff",
            justifyContent: "center"
          }}
        >
          <Button
            onClick={() => setErrorModalOpen(false)}
            sx={{
              width: "120px",
              height: "40px",
              borderRadius: 5,
              backgroundColor: "#5D38E5",
              color: "white",
              ":hover": {
                backgroundColor: "#5f359f"
              }
            }}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </CommonList>
  );
};

export default CreateMethodologyPage;
