import { Button, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { useEffect, useState } from "react";
import CommonList from "../../component/CommonList";
import CommonSearch from "../../component/CommonSearch";
import AddIcon from "@mui/icons-material/Add";
import { GridRowsProp } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import CsMngGrid from "./section/CsMngGrid";

const CsMngPage = () => {
  const navigate = useNavigate();
  const [inquiryStatus, setInquiryStatus] = useState("전체");
  const [usrStatus, setUsrStatus] = useState("전체");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [csRows, setCsRows] = useState<GridRowsProp>([
    {
      id: "test",
      no: "1",
      status: "Reservation",
      category: "POPLE Station 1st Store",
      title: "접촉사고로 인한 차대 손상",
      reporter: "",
      writer: "Minji Lee",
      completeDate: "2022/12/26 23:00",
      registrationDate: "2022/12/26 23:00",
    },
  ]);

  const handleInquiryStatusChange = (event: SelectChangeEvent) => {
    setInquiryStatus(event.target.value as string);
  };

  const handleUsrStatus = (event: SelectChangeEvent) => {
    setUsrStatus(event.target.value as string);
  };

  return (
    <CommonList title="CS 관리">
      <CommonSearch setRowsPerPage={setRowsPerPage}>
        <Select
          id="paging"
          value={inquiryStatus}
          onChange={handleInquiryStatusChange}
          size="small"
          sx={{ width: "15%" }}
          style={{ borderRadius: "10px" }}
        >
          <MenuItem value={"전체"}>전체</MenuItem>
          <MenuItem value={"답변완료"}>답변완료</MenuItem>
          <MenuItem value={"대기중"}>대기중</MenuItem>
          <MenuItem value={"삭제"}>삭제</MenuItem>
        </Select>
        <Select
          id="paging"
          value={usrStatus}
          onChange={handleUsrStatus}
          size="small"
          sx={{ width: "15%" }}
          style={{ borderRadius: "10px" }}
        >
          <MenuItem value={"전체"}>전체</MenuItem>
        </Select>
      </CommonSearch>
      <CsMngGrid
        setRowsPerPage={setRowsPerPage}
        rows={csRows}
        rowsPerPage={rowsPerPage}
      />
    </CommonList>
  );
};

export default CsMngPage;
