export interface MethodologyProject {
  id: number;
  methodologyIdName: string;
  title: string;
  area: string;
  subArea: string;
}

export interface Project {
  No: number;
  id: number;
  projectIdName: string;
  type: string;
  title: string;
  methodology: MethodologyProject;
  companyName: string;
  businessValidationReportFile: string;
  startDate: string;
  endDate: string;
  createdAt: string;
}

export interface ProjectList {
  id: number;
  methodologyId: number;
  projectIdName: string;
  title: string;
}
interface WorkspaceImage {
  name: string; // Adjust other properties as needed
  // Add other properties related to the image here if needed
}

export interface CompanyProject {
  name: string;
  ownerName: string;
  businessNumber: string;
  certificate: string;
  address: string;
  detailAddress: string;
  url: string;
  faxNumber: string;
}

export interface ProjectFormData {
  id: number;
  company?: CompanyProject;
  contactName: string;
  address?: string;
  detailAddress?: string;
  businessOwnership?: string;
  projectContent?: string;
  businessAssignment?: string;
  prePostProcess?: string;
  businessPerformanceStatus?: string;
  changeAfterBusinessApproval?: string;
  businessValidationReportFile?: string;
  team: string;
  phoneCode: string;
  phone: string;
  email: string;
  isExported: boolean;
  title: string;
  startDate: string;
  endDate: string;
  reductionAmount: number;
  methodology?: MethodologyProject;
  purpose?: string;
  content: string;
  branchName?: string;
  country?: string;
  region?: string;
  city?: string;
  latitude: number;
  longitude: number;
  methodologyId?: number;
  type?: string;
  // workspaceImages?: { name: string }[];
  workspaceImages?: WorkspaceImage[];
}

export function getTypeString(type: any): string {
  switch (type) {
    case "NEW":
      return "신규";
    case "MOVE":
      return "변경";
    default:
      return "-";
  }
}
