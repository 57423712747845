import {
  Box,
  Button,
  ButtonProps,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { rootState } from "../../store";
import { login } from "../../store/auth";

const user = [
  {
    email: "qkrwodbs43@gmail.com",
    userName: "Very Kim",
    pw: "1234",
    role: "EVMS",
    roleName: "EVMS 관리자",
  },
  {
    email: "test@gmail.com",
    userName: "Very Park",
    pw: "1234",
    role: "USER",
    roleName: "일반 사용자",
  },
  {
    email: "test2@gmail.com",
    userName: "Very Lee",
    pw: "1234",
    role: "AS",
    roleName: "AS 관리자",
  },
];

const LoginPage = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const adminType = useSelector(
    (state: rootState) => state.adminType.adminType
  );

  useEffect(() => {
    setEmail('qkrwodbs43@gmail.com');
    setPassword('1234');
  }, [email, password]);

  useEffect(() => {
    console.log("adminType is : ", adminType);
  }, [adminType]);

  const ColorButton = styled(Button)<ButtonProps>(({ theme }) => ({
    color: theme.palette.getContrastText("#5D38E5"),
    backgroundColor: "#5D38E5",
    "&:hover": {
      backgroundColor: "#A38BFC",
    },
  }));

  const handleLogin = () => {
    const foundUser = user.find(
      (user) => user.email === email && user.pw === password
    );

    if (foundUser) {
      if (foundUser.role === adminType) {
        sessionStorage.setItem("isLoggedIn", "true");
        sessionStorage.setItem("adminType", adminType);
        sessionStorage.setItem("userName", foundUser.userName);
        sessionStorage.setItem("userRoleName", foundUser.roleName);

        dispatch(login());
      } else {
        alert("로그인 권한이 없습니다.");
      }
    } else {
      alert("아이디나 패스워드가 일치하지 않습니다.");
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        // backgroundColor: "#E3EAF3",
        width: "100vw",
        height: "100vh",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Card
        sx={{
          borderRadius: 1,
          width: 460,
          height: 440,
        }}
      >
        <Box sx={{ p: 2 }}>
          <CardHeader
            title="로그인"
            titleTypographyProps={{ variant: "h4", fontWeight: "bold" }}
            sx={{ height: "auto" }}
          />
          <CardContent>
            <Box>
              {adminType == "EVMS" ? (
                <Typography sx={{ pt: 2 }} variant="subtitle2" gutterBottom>
                  EVMS ID
                </Typography>
              ) : (
                <Typography sx={{ pt: 2 }} variant="subtitle2" gutterBottom>
                  AS ID
                </Typography>
              )}

              <TextField
                size="small"
                sx={{ width: "100%" }}
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value); // 이 부분 수정
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleLogin();
                  }
                }}
                InputProps={{
                  style: {
                    borderRadius: "8px",
                  },
                }}
              />
            </Box>
            <Box>
              <Typography sx={{ pt: 2 }} variant="subtitle2" gutterBottom>
                Password
              </Typography>
              <TextField
                size="small"
                type="password"
                sx={{ width: "100%" }}
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value); // 이 부분 수정
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleLogin();
                  }
                }}
                InputProps={{
                  style: {
                    borderRadius: "8px",
                  },
                }}
              />
            </Box>
            <Box
              sx={{ display: "flex", alignItems: "center", pt: 2, ml: -1.5 }}
            >
              <Checkbox defaultChecked />
              <Typography variant="subtitle2">Unregistered Terms</Typography>
            </Box>
            <Box sx={{ pt: 2 }}>
              <ColorButton
                variant="contained"
                disableElevation
                sx={{
                  width: "100%",
                  height: 46,
                  fontSize: 16,
                  borderRadius: "8px",
                }}
                onClick={() => handleLogin()}
              >
                로그인
              </ColorButton>
            </Box>
          </CardContent>
        </Box>
      </Card>
    </Box>
  );
};

export default LoginPage;
