import { Box, Button, IconButton } from "@mui/material";
import CommonList from "../../../component/CommonList";
import CreateAdminGrid from "./CreateAdminGrid";
import { useNavigate } from "react-router-dom";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import AddIcon from "@mui/icons-material/Add";

const CreateAdminPage = () => {
  const navigate = useNavigate();
  const HeaderButton = () => {
    return (
      <Button
        sx={{
          height: 40,
          backgroundColor: "#5D38E5",
          color: "white",
          borderRadius: 5,
          width: 85,
          ":hover": {
            backgroundColor: "#A38BFC"
          }
        }}
        onClick={() => {
          navigate("/adminMng/createAdmin");
          //error가 있는 경우: 모달(alert타입)출력(❷-1 참고)
          // 이상 없는 경우: 입력된 내용 저장 후 지역 상세 화면(#AW015-1-3)으로 이동
        }}
      >
        <AddIcon fontSize="small" />
        등록
      </Button>
    );
  };
  const HeaderBackButton = () => {
    const handleNavigate = (route: string) => {
      navigate(route);
      // 페이지를 새로고침
      window.location.reload();
    };
    return (
      <IconButton
        sx={{
          ":hover": { backgroundColor: "#F2F2F2" },
          mb: 0.5,
          mr: 1
        }}
        onClick={() => {
          handleNavigate("/adminMng");
        }}
      >
        <ArrowBackIosNewIcon fontSize="medium" />
      </IconButton>
    );
  };

  return (
    <CommonList
      title="관리자 등록"
      setModal={null}
      headerButton={HeaderButton()}
      headerBackButton={HeaderBackButton()}
    >
      <Box>
        <CreateAdminGrid />
      </Box>
    </CommonList>
  );
};

export default CreateAdminPage;
