import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
  Divider,
  Radio,
  Button,
} from "@mui/material";
import TextEditor from "../../../component/TextEditor";
import { useEffect, useState } from "react";
import SpanningTable from "./FeeTable";

const CsMngDetailsGrid = () => {
  const [data, setData] = useState();
  useEffect(() => {
    console.log("data is : ", data);
  }, [data]);
  const [reportOpen, setReportOpen] = useState(false);

  return (
    <Grid container rowSpacing={4.5} columnSpacing={2.75} sx={{ mt: -5 }}>
      {/* 첫번째 카드 */}
      <Grid item lg={3}>
        <Card
          sx={{
            borderRadius: 3,

            width: "100%",
            display: "flex",
          }}
        >
          <CardContent sx={{ width: "100%" }}>
            <CardHeader
              title="CS 신청 정보"
              titleTypographyProps={{ fontWeight: "bold", fontSize: 19 }}
            />
            <Box
              sx={{
                m: 2,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box>
                <Typography fontWeight={"bold"} color={"#99989D"}>
                  Status
                </Typography>
                <Typography sx={{ mt: 1, fontSize: 13 }}>
                  Reservation
                </Typography>
              </Box>
              <Box sx={{ mt: 3 }}>
                <Typography fontWeight={"bold"} color={"#99989D"}>
                  Writer
                </Typography>
                <Typography sx={{ mt: 1, fontSize: 13 }}>Minji Lee</Typography>
              </Box>
              <Box sx={{ mt: 3 }}>
                <Typography fontWeight={"bold"} color={"#99989D"}>
                  E-mail
                </Typography>
                <Typography sx={{ mt: 1, fontSize: 13 }}>
                  Minji123@gmail.com
                </Typography>
              </Box>
              <Box sx={{ mt: 3 }}>
                <Typography fontWeight={"bold"} color={"#99989D"}>
                  Phone Number
                </Typography>
                <Typography sx={{ mt: 1, fontSize: 13 }}>
                  +855 010-1111-1111
                </Typography>
              </Box>
              <Box sx={{ mt: 3 }}>
                <Typography fontWeight={"bold"} color={"#99989D"}>
                  VIN
                </Typography>
                <Typography sx={{ mt: 1, fontSize: 13 }}>
                  KNABX511BCT012345
                </Typography>
              </Box>
              <Box sx={{ mt: 3 }}>
                <Typography fontWeight={"bold"} color={"#99989D"}>
                  Preferred CS Station
                </Typography>
                <Typography sx={{ mt: 1, fontSize: 13 }}>
                  POPLE Station 1st Store
                </Typography>
              </Box>
              <Box sx={{ mt: 3 }}>
                <Typography fontWeight={"bold"} color={"#99989D"}>
                  Preferred CS date
                </Typography>
                <Typography sx={{ mt: 1, fontSize: 13 }}>
                  2022.12.26 18:00
                </Typography>
              </Box>
              <Divider sx={{ mt: 5 }} />
              <Box sx={{ mt: 5 }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    color: "#99989D",
                  }}
                >
                  <Typography fontSize={13}>등록 일시</Typography>
                  <Typography fontSize={13}>2022.01.01 14:24</Typography>
                </Box>
                {reportOpen == true && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      color: "#99989D",
                      mt: 1,
                    }}
                  >
                    <Typography fontSize={13}>접수 일시</Typography>
                    <Typography fontSize={13}>2022.01.01 14:24</Typography>
                  </Box>
                )}
              </Box>
              {reportOpen == false && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    mt: 3,
                    mb: -3,
                  }}
                >
                  <Button
                    sx={{
                      textTransform: "none",
                      height: 40,
                      backgroundColor: "#5D38E5",
                      color: "white",
                      borderRadius: 5,
                      width: 125,

                      ":hover": {
                        backgroundColor: "#A38BFC",
                      },
                    }}
                    onClick={() => {
                      setReportOpen(true);
                    }}
                  >
                    Receive
                  </Button>
                </Box>
              )}
            </Box>
          </CardContent>
        </Card>
      </Grid>
      {/* 두번째 카드 */}
      <Grid item lg={9}>
        <Card
          sx={{
            borderRadius: 3,

            width: "100%",
            display: "flex",
          }}
        >
          <CardContent sx={{ width: "100%" }}>
            <Box
              sx={{
                m: 2,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box sx={{ mt: 0 }}>
                <Typography fontWeight={"bold"} fontSize={21}>
                  battery is not working
                </Typography>
                <Box
                  sx={{
                    border: 2,
                    height: 570,
                    mt: 3,
                    borderRadius: 3,
                    borderColor: "#F2F2F2",
                  }}
                >
                  <Box sx={{ m: 2 }}>
                    안녕하세요.
                    <br />
                    <br />
                    제가 전날 주행을 위해 배터리를 반납 후 대여 하였습니다.
                    배터리를 제 스쿠터에 장착 후 전원을 켰으나 스쿠터가 평소와
                    다르게 힘 없이 천천히 느리게 이동하는 것이 아니겠습니까?
                    <br />
                    <br />
                    스쿠터가 갑자기 배터리를 가려 받기 시작한 것은 아닐테니
                    배터리가 문제가 있는 것이라 직감하여 이렇게 배터리 이상으로
                    고장 신고를 접수 하게 되었습니다. 빠르고 신속하게 해당 증상
                    개선을 부탁 드리며 기운이 나올 수 있도록 제 스쿠터 사진을
                    첨부드립니다.
                  </Box>
                </Box>
              </Box>
            </Box>
          </CardContent>
        </Card>
        {reportOpen == true && (
          <Card
            sx={{
              borderRadius: 3,
              mt: 4,
              width: "100%",
              display: "flex",
            }}
          >
            <CardContent sx={{ width: "100%" }}>
              <Box
                sx={{
                  m: 2,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box sx={{ mt: 0 }}>
                  <Typography fontWeight={"bold"} fontSize={21}>
                    Report
                  </Typography>
                  <Box
                    sx={{
                      mt: 4,
                    }}
                  >
                    <Typography
                      fontSize={14}
                      sx={{ color: "#99989D" }}
                      fontWeight={"bold"}
                    >
                      Identified Problem Description
                    </Typography>
                    <TextField
                      id="outlined-multiline-static"
                      multiline
                      rows={3}
                      sx={{ mt: 1, width: "100%" }}
                      InputProps={{
                        style: {
                          borderRadius: "12px",
                        },
                      }}
                    />
                  </Box>
                  <Box
                    sx={{
                      mt: 4,
                    }}
                  >
                    <Typography
                      fontSize={14}
                      sx={{ color: "#99989D" }}
                      fontWeight={"bold"}
                    >
                      Action to Prevent Recurrence
                    </Typography>
                    <TextField
                      id="outlined-multiline-static"
                      multiline
                      rows={3}
                      sx={{ mt: 1, width: "100%" }}
                      InputProps={{
                        style: {
                          borderRadius: "12px",
                        },
                      }}
                    />
                  </Box>
                  <Divider sx={{ mt: 5, mb: 5 }} />
                  <Typography
                    fontSize={14}
                    sx={{ color: "#99989D" }}
                    fontWeight={"bold"}
                  >
                    Repair Fee
                  </Typography>
                  <SpanningTable />
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      mt: 3,
                      mb: -3,
                    }}
                  >
                    <Button
                      sx={{
                        textTransform: "none",
                        height: 40,
                        backgroundColor: "#8E8E8E",
                        color: "white",
                        borderRadius: 5,
                        width: 125,

                        ":hover": {
                          backgroundColor: "#BABABA",
                        },
                      }}
                      autoFocus
                      onClick={() => {}}
                    >
                      Complete
                    </Button>
                  </Box>
                </Box>
              </Box>
            </CardContent>
          </Card>
        )}
      </Grid>
    </Grid>
  );
};

export default CsMngDetailsGrid;
